@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');

@font-face {
  font-family: 'FSLucasProRegular';
  src: url('../fonts/FSLucasProRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FSLucasProSemiBd';
  src: url('../fonts/FSLucasProSemiBd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Coupe';
  src: url('../fonts/Coupe-Bold Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: FSLucasProRegular;
  background-color: #fff;
}

body.modal-open {
  overflow-y: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'DM Serif Display';
}
img {
  border-radius: 0;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #120308;
}

::-webkit-scrollbar-thumb {
  background: #f26526;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.v-cursor {
  z-index: 9999;
  width: 50px;
  height: 50px;
  border: 2px solid #000;
  pointer-events: none;
  border-radius: 25px;
}

.v-cursor__drag {
  height: 100%;
  position: absolute;
  bottom: auto;
  left: 0;
  right: auto;
  top: 0;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 50%;
  background-color: #c24040;
  transition: opacity 0.25s, transform 0.25s;
  transition-delay: 0s;
  transition-timing-function: ease;
  opacity: 0;
  transform: scale(0.75);
}

.v-cursor__link-315 {
  height: 100%;
  position: absolute;
  bottom: auto;
  left: 0;
  right: auto;
  top: 0;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 50%;
  background-color: #c24040;
  transition: opacity 0.25s, transform 0.25s;
  transition-delay: 0s;
  transition-timing-function: ease;
  opacity: 0;
  transform: scale(0.75) rotate(45deg);
}

/* Menu & Navigation*/
.logo_img.normal_logo {
  width: 100%;
  max-width: 150px;
}
.logo_img.sticky_logo {
  width: 100%;
  max-width: 40px;
}
.wrapper .navbar {
  z-index: 90;
}
.navbar .navbar-translate {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between !important;
}
.navbar.navbar-transparent {
  padding-top: 20px;
}
.navbar .navbar-toggler-bar {
  display: block;
  position: relative;
  width: 25px;
  height: 2px;
  border-radius: 1px;
  background: #ffffff;
}
.navbar .navbar-toggler-bar.bar2 {
  width: 25px;
  transition: width 0.2s linear;
}
.navbar .navbar-toggler-bar + .navbar-toggler-bar {
  margin-top: 7px;
}
.fixed-top.navbar-transparent .normal_logo {
  display: block;
}
.fixed-top.navbar-transparent .sticky_logo {
  display: none;
}
.navbar.bg-info .normal_logo {
  display: none;
}
.navbar.bg-info .sticky_logo {
  display: block;
}
.navbar.bg-info {
  padding-top: 5px;
  padding-bottom: 5px;
}
.navbar p {
  width: 100%;
  margin-bottom: 10px;
}

.navbar p.text-uppercase {
  color: #fff;
  font-size: 21px;
  opacity: 1;
  margin: 15px 0 5px;
  font-weight: bold;
}

.navbar p.white_label {
  color: #fff;
  margin-bottom: 0;
  font-weight: bold;
}
.navbar-collapse.show {
  z-index: 999;
  border-radius: 0;
  padding: 2.5rem 2.5rem 2.5rem 8rem;
}
.navbar .navbar-collapse .navbar-collapse-header .collapse-close .navbar-toggler {
  z-index: 99;
  position: relative;
}
.navbar .navbar-collapse .navbar-collapse-header .collapse-brand a:focus {
  color: #fff;
}

.menu_link {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.12);
  position: relative;
}
.menu_link:last-child {
  border-bottom: none;
}

.menu_link a {
  width: fit-content;
  color: #ffffff;
  font-family: 'DM Serif Display';
  font-size: 6.5vw;
  font-weight: normal !important;
}

.menu_link a:hover,
.small_menu:hover {
  color: grey !important;
}

.small_menu {
  color: #ffffff;
  font-family: 'DM Serif Display';
  font-size: 3em;
  display: block;
  font-weight: normal !important;
}

.mail_link {
  font-family: FSLucasProRegular;
  color: #fff;
  font-size: 15px;
  display: block;
  font-weight: normal !important;
}

.mail_link:hover,
.mail_link:focus {
  color: rgba(255, 255, 255, 0.5);
}

.mail_link img:hover,
.mail_link img,
.breaker_link img,
.breaker_link img:hover,
.back_btn img,
.back_btn:hover img {
  -webkit-transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
}
a.breaker_link img {
  margin: 0;
  margin-left: 10px;
}
.modal-body .back_btn img {
  margin: 0;
}
.back_btn img {
  margin-left: 0px;
  margin-right: 10px;
}
.back_btn:hover img {
  margin-left: -20px;
  margin-right: 30px;
}

a.breaker_link:hover img,
a.mail_link:hover img,
.modal-body .back_btn:hover img {
  margin: 0;
  margin-left: 20px;
  margin-right: -20px;
  display: inline-block;
}

.social_link {
  display: inline-block;
  color: #8e8e8e;
  font-size: 16px;
}

.dark_img {
  display: none;
}
.desktop_only,
.show_bg {
  display: block;
}
.mobile_only,
.mobile_view {
  display: none;
}
/* Homepage*/
.header_video {
  right: 0;
  position: fixed;
  top: 0;
  height: 100vh;
  padding: 0;
  object-fit: cover;
  width: 100%;
  background: url('/public/home/hero-video.png') no-repeat;
  background-size: cover;
}
.header_video_mobile {
  display: none;
}
.home_wrapper.service_wrapper .header_keywords {
  font-size: 70px;
  line-height: 1.2;
}
.home_header_container {
  height: auto !important;
}
.show_in_320 {
  display: none;
}
#lottie_block {
  display: block;
}
#lottie_block_ipad {
  display: none;
}
#lottie_block_mobile {
  display: none;
}
.lottie_container {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
}
.lottie_bg {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: left;
}
.header_overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
}
.home_wrapper .page-header {
  height: 100vh;
  min-height: unset;
  max-height: unset;
  position: relative;
  overflow: hidden;
  flex-wrap: wrap;
}
.home_wrapper .header_keywords {
  text-align: left;
  font-size: 100px;
  line-height: 1.1;
  font-weight: normal;
}
.home_wrapper.service_wrapper .header_title {
  font-size: 22px;
  margin: 30px auto 0;
  line-height: 1.3;
  font-family: 'FSLucasProRegular';
  display: block;
  max-width: 800px;
  text-align: center;
}
.home_wrapper .corner_btn {
  margin: 50px auto 0;
  position: relative;
  width: fit-content;
  left: unset;
  right: unset;
  top: unset;
  bottom: unset;
}
.home_wrapper .corner_btn i {
  padding: 20px;
  font-size: 20px;
}
.home_wrapper .halfway_section .sectional_title {
  max-width: 500px;
}
.home_wrapper .small_title {
  font-family: 'DM Serif Display';
}
.home_wrapper .halfway_section .subtitle {
  max-width: 500px;
  margin-bottom: 40px;
}

.home_wrapper .halfway_section.omni_bg .subtitle {
  max-width: 300px;
  margin-bottom: 40px;
}
.home_wrapper .mail_link {
  font-weight: bold !important;
}
.halfway_section .orange_hr.short_hr {
  max-width: 60px;
  margin-bottom: 30px;
  min-width: unset;
}
.home_wrapper .grey_section {
  margin-top: 0;
  padding-bottom: 100px;
  margin-bottom: -100px;
  padding-top: 0px;
}
.home_wrapper .grey_bg {
  width: 100%;
}
.home_wrapper .grey_section .container .col-md-6 {
  background-color: #fff;
  border: none !important;
}
.home_wrapper .grey_section.halfway_section .subtitle {
  max-width: 350px;
}
.home_wrapper .grey_section .halfway_img {
  margin-right: -5vw;
}
.left_content {
  padding: 50px 0 50px 50px;
}
.speaker_png {
  left: 27vw;
  margin-top: 0;
  position: absolute;
  max-width: 400px;
}
.speaker_png.full_length {
  display: none;
}
.grey_one {
  position: absolute;
  top: -20px;
  left: 17vw;
  font-size: 94px;
  font-family: 'DM Serif Display';
  color: #000;
  opacity: 0.1;
}
.home_wrapper h1.section_title {
  font-size: 60px;
}
.home_wrapper .career_content_wrapper .subtitle {
  font-size: 23px;
  max-width: 700px;
  margin: auto;
  line-height: 1.3;
}
.home_wrapper .service_content {
  padding-bottom: 0;
  margin-bottom: -70px;
}
.home_wrapper .second_halfway .sectional_title {
  font-size: 58px;
}
.home_wrapper .omni_link {
  color: #fff;
  letter-spacing: 4px;
  cursor: pointer;
}

.white_bg {
  position: relative;
  background-color: #fff;
  z-index: 2;
}
.what_matters_section {
  background: linear-gradient(90deg, black 0%, #05314e 100%);
}
.what_matters_section .container-fluid {
  padding: 0 50px;
}
.what_matters_section .container-fluid h1,
.what_matters_section .container-fluid p {
  color: #fff;
}
.what_matters_section .container-fluid h1 {
  font-size: 97px;
  line-height: 0.95;
}
.what_matters_section .container-fluid p {
  font-size: 18px;
  line-height: 1.8;
  margin: 40px 0;
}
.matters_container .matters_block.mobile_ver,
.matters_container .matters_img.mobile_ver {
  display: none;
}
.matters_container {
  display: flex;
}
.matters_container.position-absolute {
  right: 0;
  bottom: 0;
}
.matters_img {
  width: 25vw;
}
.matters_img img {
  width: 100%;
  border-radius: 0;
}
.matters_block {
  background-color: #f7f7f7;
  padding: 50px;
  position: relative;
  width: 25vw;
}
.block_bottom {
  display: none;
}
.block_right:after {
  top: 40%;
  content: '';
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 35px 40px 35px 0;
  border-color: transparent #f7f7f7 transparent transparent;
  left: -40px;
}
.block_left:after {
  transform: rotateY(180deg);
  top: 40%;
  content: '';
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 35px 40px 35px 0;
  border-color: transparent #f7f7f7 transparent transparent;
  right: -40px;
}
.matters_block h6 {
  font-family: 'DM Serif Display';
  font-size: 20px;
  color: #000;
}
.matters_block h5 {
  font-family: 'DM Serif Display';
  font-size: 23px;
  color: #000;
  font-weight: bold;
  margin-top: 50px;
  max-width: 300px;
}
.team_ds_container {
  /* background: linear-gradient(
    90deg,
    black 0%,
    #05314e 30%,
    rgba(0, 0, 0, 0) 30%
  ); */

  display: flex;
  align-items: center;
  position: relative;
}
.pre_team_ds.career_text {
  padding-bottom: 100px;
  padding-top: 80px;
}
.pre_team_ds.career_text h1 {
  color: #000;
}
.team_ds_container .subtitle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.team_ds_container img {
  border-radius: 0;
}
.side_img {
  width: 48%;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.side_img:hover {
  transform: scale(1.1);
  opacity: 0.8;
}
.side_img:nth-last-child(-n + 2) {
  margin-bottom: 0;
}
.side_img.active_img {
  width: 100%;
  margin-bottom: 0;
  cursor: pointer;
}
.team_ds_img {
  position: absolute;
  left: -80px;
  width: 80px;
  top: 20px;
}
.ReactModalPortal .ReactModal__Overlay {
  z-index: 9999 !important;
}
.blue_bg {
  display: none;
}

.industry_container {
  /* background: url('/public/home/bg.png') no-repeat; */
  background-color: #fff;
  padding-top: 120px;
  padding-bottom: 100px;
  background-size: cover;
}
.gradient_highlight {
  font-family: 'DM Serif Display';
  background: linear-gradient(90deg, rgba(59, 97, 104, 1) 0%, rgba(24, 38, 55, 1) 27%, rgba(255, 127, 50, 1) 100%);
  padding: 0 10px;
}
.gradient_wording {
  background: -webkit-linear-gradient(360deg, rgba(59, 97, 104, 1) 0%, rgba(24, 38, 55, 1) 27%, rgba(255, 127, 50, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient_wording2 {
  background: -webkit-linear-gradient(0deg, rgba(59, 97, 104, 1) 0%, rgba(24, 38, 55, 1) 40%, rgba(255, 127, 50, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.industry_container .reverse_title {
  writing-mode: vertical-lr;
  transform: scale(-1);
  right: 0;
  margin: 0;
}

.industry_container .reverse_title.gradient_wording {
  background: -webkit-linear-gradient(270deg, rgba(59, 97, 104, 1) 0%, rgba(24, 38, 55, 1) 44%, rgba(255, 127, 50, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 130px;
}
.industry_container .reverse_title.desktop_only {
  position: sticky;
  top: 100px;
}
.industry_container .reverse_title.desktop_only.gradient_wording {
  margin-left: 120px;
  margin-top: -150px;
  top: 400px;
}
.home_wrapper .industry_container h1.section_title {
  text-align: center;
  color: #000;
  font-size: 120px;
  font-family: 'DM Serif Display';
}
.reverted_b {
  padding-right: 250px;
}
.industry_container .subtitle {
  color: #fff;
}
.industry_box {
  margin-top: 20px;
  max-width: 620px;
}

.industry_box:nth-child(2) {
  margin-top: 40px;
  margin-bottom: 40px;
}

.industry_box a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.industry_box img {
  max-width: 220px;
}
.industry_cont {
  margin-left: 50px;
}
.home_wrapper.service_wrapper .industry_box h1.section_title {
  font-size: 20px;
  padding-top: 100px;
}
.industry_date {
  color: #000 !important;
  opacity: 0.5;
  font-size: 16px;
}
.industry_box.col-md-4 {
  flex: 0 0 31%;
}
.industry_container .industry_box .subtitle {
  font-size: 28px;
  line-height: 1.2;
  max-width: unset;
  margin: auto;
  margin-left: 0;
  font-family: 'DM Serif Display';
  color: #000;
}
.industry_container .industry_box .subcontent {
  font-size: 16px;
  font-family: 'FSLucasProRegular';
  line-height: 1.3;
  margin: 20px 0;
}
.industry_box .orange_hr {
  margin: 0 20px;
  display: inline-block;
  border-top: 2px solid #ff8c18;
  min-width: 60px;
}
.view_all {
  margin: 50px auto 0;
  text-align: center;
  letter-spacing: 2px;
}

/* About Us */
.about_wrapper .container {
  max-width: 85vw;
}
.about_wrapper .subtitle {
  font-size: 22px;
}
.about_wrapper .info .description p {
  font-size: 20px;
  max-width: 300px;
  line-height: 1.3;
}
.about_wrapper .my_team_section,
.about_wrapper .breaker_section,
.about_wrapper .about_car_section {
  padding: 100px 0;
}
.page-header.about_header .header_title {
  max-width: 800px;
  margin: auto;
}
.hide_about {
  display: flex !important;
}
.about_slider {
  display: none;
}
.page-header.about_header {
  background-position: center;
}
.top_element,
.left_element,
.right_element {
  position: absolute;
  opacity: 0.5;
}
.left_element {
  right: 95vw;
  opacity: 0.5;
  width: 350px;
}
.right_element {
  width: 350px;
  left: 70vw;
}
.top_element {
  left: 0;
  top: -15vh;
}
.info {
  max-width: unset;
}
.info-title {
  font-weight: bold;
  margin-top: 0 !important;
}
.icon_image {
  max-width: 120px;
}
.description p {
  font-size: 18px;
}
.breaker_section {
  background: url('assets/img/about/breaker.jpg') no-repeat;
  background-size: cover;
  color: #fff;
  overflow: hidden;
}
.breaker_section h1.section_title {
  color: #fff;
  font-family: 'DM Serif Display';
}
.breaker_section .subtitle {
  color: #fff;
}
.breaker_link {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 3.33px;
  color: #fff;
  margin-top: 50px;
  display: block;
  width: fit-content;
}
.breaker_link:hover,
.breaker_link:focus {
  color: #fff;
}
.team_box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px 50px;
}
.my_team_section {
  background: linear-gradient(90deg, black 0%, #05314e 100%);
  position: relative;
  z-index: 2;
  overflow-x: hidden;
}
.my_team_section h1.section_title {
  color: #fff;
  text-align: left !important;
}
.my_team_section h4.subtitle {
  font-family: 'DM Serif Display';
  color: #fff;
  text-align: left !important;
  font-size: 41px;
}
.my_team_section .carousel_container {
  margin-top: 100px;
}
.my_team_section .carousel_container .owl-carousel {
  max-width: unset;
}
.my_team_section .carousel_container .owl-carousel .owl-nav button.owl-next {
  top: -100px;
  right: 0;
  border-color: #fff;
}
.my_team_section .owl-next i {
  color: #fff;
}
.my_team_section .owl-theme .owl-dots {
  text-align: center;
}

.smiic_hero{
  margin-top: 50px;
}

.text-black{color: #000 !important;}
.text-white{color: #fff !important;}
.text-30{font-size: 30px !important;}
.horizontal-line{border-color: #424242;width:100%;margin: 0;}
.icon-computer{width: 40px;height: auto;margin-bottom: 20px;}

.card-smiic hr{
  width:100%;
}
.card-smiic h3{
  font-size:40px;
  line-height: 1;
}
.ds-color{
  color: #F9641D;
}
.font-semibold{
  font-family: 'FSLucasProSemiBd' !important;
}
.card-smiic{
  padding:30px 0;
  border-radius: 20px;
  height:100%;
}
.card-smiic .text-18{
  font-size: 18px;
  line-height: 1.3;
}
.card-smiic .text-20{
  font-size: 20px;
  line-height: 1.3;
}
.core-card{
  background: url('/public/smiic/shape-core.png') no-repeat;
  background-size: 100%;
  height: 295px;
}

.core-card p{
  font-size: 20px;
  color: #fff !important;
  line-height: 1.3;
}

.benefit-card h3{
  font-weight: 900;
  font-family: 'FSLucasProSemiBd';
  color: #fff !important;
  font-size: 25px;
  line-height: 1.3;
}
.benefit-card .text-20{
  font-size: 20px;
  line-height: 1.3;
  color: #fff !important;
}
.text-bold{
  font-family: 'FSLucasProSemiBd';
}
.package-card{
  width: 30%;
}

.pioneer.text-20{
  font-size: 20px;
  line-height: 1.3;
}

.section.package_section{
  padding-top: 0;
}

.breaker-text .text-40{
  font-size: 40px;
  line-height: 1.3;
}

.car_section {
  background: linear-gradient(90deg, black 0%, #05314e 100%);
  position: relative;
  z-index: 2;
  overflow-x: hidden;
}
.car_section h2.section_title {
  color: #fff;
  font-size: 50px;
}
.broker-img{
  background: url('/public/smiic/broker.png') no-repeat;
  background-size: cover;
  height: 435px;
  display: flex;
}
.text-23{
  font-size: 20px;
}


.office-lite-carousel .owl-stage,
.home-carousel .owl-stage {
  display: flex;
  align-items: center;
}
.office-lite-carousel.carousel_container .owl-carousel .owl-nav button.owl-next {
  right: 33vw;
}
.team_container {
  text-align: center;
  width: 100%;
}
.team_container .hover_active {
  position: absolute;
  top: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}
.team_container .hover_active:hover {
  opacity: 1;
  transition: opacity 0.3s ease-in;
  cursor: pointer;
}
.profile_name {
  font-size: 21px;
  color: #fff;
  margin: 20px auto 10px;
}
.profile_position {
  font-size: 18px;
  color: #f87613 !important;
  margin-bottom: 30px;
}
u {
  font-weight: bold;
}
.about_wrapper .breaker_section {
  background: url('/public/about/bg.jpg') no-repeat;
  background-size: cover;
}
.about_wrapper .breaker_section h1.section_title {
  font-size: 58px;
}

.about_wrapper .no_max_width {
  padding: 20px 0 0px;
}
.about_wrapper .no_max_width .info {
  padding: 100px 0 20px;
}
.girl_img {
  max-width: 350px;
  position: absolute;
  right: auto;
  top: -20px;
  display: block;
  width: 100%;
}
.my_team_section .carousel_container .owl-carousel .owl-nav {
  margin-top: 50px;
}
.my_team_section .carousel_container .owl-carousel .owl-nav button.owl-prev {
  order: 1px solid #000;
  border-radius: 50% !important;
  padding: 23px 25px !important;
  outline: none;
  right: 90px;
  top: unset;
  bottom: 0%;
  border: 1px solid #fff;
}
.my_team_section .carousel_container .owl-carousel .owl-nav button.owl-next {
  bottom: 0%;
  top: unset;
  padding: 23px 25px !important;
}
.about_wrapper .my_team_section .carousel_container .owl-carousel .owl-nav button.owl-prev {
  display: block !important;
}
.my_team_section .carousel_container .owl-carousel .owl-nav button.owl-prev i {
  color: #fff;
}

/* Services */
.service_wrapper.career_wrapper .header_keywords {
  text-align: left;
  font-size: 121px;
  width: fit-content;
  margin: auto;
  line-height: 1;
}
.service_wrapper.career_wrapper .header_keywords span {
  font-size: 65px;
  display: block;
  line-height: 1.2;
  padding-left: 150px;
}
.service_wrapper .halfway_section {
  overflow: hidden;
}
.halfway_section {
  display: flex;
  align-items: center;
  z-index: 2;
  position: relative;
}
.halfway_section h6 {
  opacity: 1;
  color: #fff;
  font-size: 20px;
  text-transform: unset;
  margin-bottom: 50px;
}
.halfway_section .orange_hr {
  width: 100px;
  display: inline-block;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 5px;
}
.halfway_section .sectional_title {
  color: #fff;
  font-size: 66px;
  font-family: 'DM Serif Display';
  max-width: 300px;
}
.halfway_section .subtitle {
  color: #fff;
  font-size: 18px;
  margin-bottom: 50px;
  max-width: 400px;
}
.halfway_section .perks_container {
  width: 30%;
  padding-right: 10px;
  min-width: 250px;
}
.halfway_section .perks_container .subtitle {
  margin-bottom: 0px;
  line-height: 1;
  font-weight: bold;
}
.halfway_img {
  border-radius: 0;
}

.grey_section {
  padding-top: 160px;
  margin-top: -90px;
  margin-bottom: -80px;
  padding-bottom: 160px;
}
.grey_section h6,
.grey_section .sectional_title,
.grey_section .subtitle {
  color: #000;
}
.service_content {
  padding-top: 100px;
}
.service_content img {
  max-width: 300px;
  position: absolute;
  right: 30px;
}
.service_content h1.section_title {
  text-align: center !important;
  font-family: 'DM Serif Display';
}
.service_content .mail_link {
  display: flex;
  width: fit-content;
  margin: 20px auto;
  color: #000;
  font-weight: bold !important;
  letter-spacing: 2px;
  align-items: center;
}

/* Work */
.working_wrapper .work_header .header_keywords {
  text-align: left;
  font-size: 121px;
  width: fit-content;
  margin: auto;
  line-height: 1;
}
.working_wrapper .work_header .header_keywords .delay07 {
  font-size: 48px;
  display: block;
  line-height: 1;
  padding-left: 50px;
}
#gradient-canvas {
  width: 100%;
  height: 100%;
  --gradient-color-1: #182637;
  --gradient-color-2: #ff7f32;
  --gradient-color-3: #3b6168;
  /* --gradient-color-4: #4c57f6; */
  position: absolute;
}
.working_wrapper .working_section h1.work_section_title {
  padding-left: 0px;
  line-height: 1.2;
}
.working_wrapper .working_section .orange_hr {
  margin-left: 0px;
}
.work_image {
  margin-bottom: 20px;
}
.work-info:nth-child(6) .work_image {
  margin-top: -70px;
}
.work-info:last-child .work_image {
  margin-top: -50px;
}
.profile-page .info {
  padding: 50px 35px 0;
}
.working_section .work-info.info.info-horizontal {
  margin-left: 0;
  cursor: pointer;
}
.info.info-horizontal.work-info:nth-child(2) {
  margin-top: -70px;
}
.filter_group {
  margin: 0 0 0 auto;
}
.filter_label {
  font-size: 15px;
  color: #797979;
  margin-bottom: 30px;
}
.filter_title {
  cursor: pointer;
  font-size: 21px;
  color: #737373;
  display: block;
  margin-bottom: 10px;
}
.filter_title:hover,
.filter_title.active {
  color: #000;
  font-weight: bold;
}
.profile-page .info.work-info .description {
  padding-left: 0;
}
.info-title.work-title {
  font-size: 33px;
  line-height: 1.1;
  font-family: 'DM Serif Display';
  margin-top: 20px;
}
.section_title.work_section_title {
  font-size: 50px;
  line-height: 1.5;
}
.orange_hr {
  border-top: 3px solid #ff8c18;
  width: 7%;
  min-width: 85px;
}
.work-info p {
  color: rgba(0, 0, 0, 0.85);
  max-width: unset;
}
.project_highlight {
  color: #7b8b95;
  font-size: 18px;
}
.work-info hr {
  border-color: #000;
  margin-bottom: 5px;
  margin-left: 15px;
  display: inline-block;
  border-radius: 0;
}
.working_section .breaker_section {
  width: 100%;
  margin: 50px 0 120px;
}
.work_breaker_section {
  background: url('assets/img/work/ARS.png') no-repeat;
  background-size: cover;
  padding: 100px 0;
}
.work_breaker_section.breaker_section .subtitle {
  padding-right: 5vw;
}
.work_breaker.breaker_section {
  background: url('assets/img/about/breaker.jpg') no-repeat;
  background-size: 100% 70%;
  color: #fff;
  background-color: #fff;
  padding-top: 100px;
}
.brands_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '. . . .'
    '. . . .'
    '. . . .'
    '. . . .'
    '. . . .';
  margin: 100px auto 50px;
}
.brand_img_box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: #fff;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
}
.brand_img_box:nth-child(4n + 1),
.brand_img_box:first-child {
  border-left: 1px solid #979797;
}
.brand-img {
  max-width: 130px;
}
.brand_carousel {
  display: none;
}
.ars-mobile,
.ars-mobile-element {
  display: none;
}

/* Case Study */
.case-study .page-header {
  min-height: 100vh;
  background-position: bottom;
}
.case-study .header_keywords {
  text-align: left;
  line-height: 1;
  width: 45vw;
}
.case-study .header_title {
  font-size: 23px;
  text-align: left;
  font-family: FSLucasProRegular;
  max-width: 350px;
  line-height: 1.5;
}
.case-study hr.orange_hr {
  max-width: 35px;
  min-width: 35px;
}
.work_gif {
  margin-right: 10px;
  width: 30px;
  display: inline-block;
}
.short_title {
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 10px;
}
.case-study .section_title {
  margin-bottom: 50px;
  font-family: 'DM Serif Display';
}
.work_content {
  font-size: 20px;
  text-align: justify;
}
.info-head {
  font-size: 27px;
  width: 100%;
  color: #000;
  font-family: FSLucasProSemiBd;
}
.services_list .icon_image {
  width: 25px;
  margin-right: 20px;
  margin-top: 0;
}
.services_list .info-title {
  font-size: 18px;
  color: #000;
  margin-bottom: 0;
  font-family: FSLucasProRegular;
}
.list-group {
  display: inline-block;
}
.list-group-item {
  border: none;
  display: flex;
  align-items: center;
}
.list-group-item {
  margin-right: auto;
  margin-left: 0;
  padding: 0.8rem;
  background: none;
  color: #fff;
}
.list-group-item .info-head,
.list-group-item .info-title {
  color: #fff;
}
.list-group .icon_image {
  width: 30px;
}
.case-study.case_study_wrapper .list-group .icon_image {
  height: 40px;
}
.case-study .subtitle,
.case-study .subtitle span,
.case-study .work_content span {
  font-family: FSLucasProRegular !important;
}
.case-study .breaker_section .subtitle {
  font-size: 20px;
  opacity: 0.85;
}
.case-study .breaker_section .subtitle p,
.case-study .breaker_section .subtitle p span {
  color: #fff !important;
}
.case-study .section_subtitle {
  font-size: 31px;
  margin-bottom: 30px;
}
.profile-page .other_stories_carousel .info {
  padding: 0;
}
.case-study .other_stories_carousel .owl-dots .owl-dot.active span:after {
  color: #000;
}
.sample_work_section {
  padding-bottom: 20px;
  padding-top: 0;
}
.case-study .sample_work_section hr.orange_hr {
  max-width: 60px;
  min-width: 50px;
}
.sample_work_section .section_title {
  text-align: center;
  font-size: 50px;
}
.sample_work_img {
  width: 100%;
  object-fit: cover;
}
.sample_work_img_mobile {
  display: none;
  width: 100%;
}
.sample_work_carousel .owl-item {
  opacity: 0.5;
}
.sample_work_carousel .owl-item.active.center {
  opacity: 1;
}

.carousel_container.other_stories_carousel {
  margin-top: 0;
}
.other_stories_container {
  padding-right: 0;
}
/*.other_stories_carousel .owl-carousel .owl-stage-outer{min-width: 100vw;}*/
.other_stories_carousel .owl-stage {
  padding-left: 0px !important;
  padding-top: 60px;
}
.other_stories_carousel .work-info {
  display: flex;
  justify-content: space-between;
  margin-left: 0;
}
.other_stories_carousel .owl-item img.work_image {
  width: 55%;
  margin-right: 20px;
  margin-top: 0;
  margin-bottom: 0;
}
.other_stories_carousel .info-title.work-title {
  margin: 20px 0 !important;
}
.other_stories_carousel .description hr.orange_hr {
  margin-left: 0;
  border-color: #ff8c18;
  min-width: 85px;
  display: block;
  margin-top: 50px;
}
.other_stories_carousel.carousel_container .owl-carousel .owl-nav button.owl-next {
  top: -60px;
  right: -20px;
  bottom: unset;
}
.other_stories_carousel.carousel_container .owl-carousel .owl-nav button.owl-prev {
  top: -60px;
  right: 80px;
  bottom: unset;
  display: block !important;
  border: 1px solid #000;
  border-radius: 50% !important;
  padding: 28px 30px !important;
  outline: none;
}
#work_image_6 {
  margin-top: -40px;
}
.strategyImg {
  position: relative;
  padding-left: 0;
  width: 100%;
  margin-top: 10px;
  padding-right: 20px;
  max-width: 100px;
}
.case-study .breaker_section {
  background: url('assets/img/about/breaker.jpg') no-repeat;
  background-size: cover;
  overflow: auto;
  padding: 100px 0;
}
.case-study .breaker_section .subtitle {
  margin-bottom: 80px;
}
.kpi_container {
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.mid_container {
  background: url('assets/img/case-study/shape.png') no-repeat;
  background-size: contain;
  background-position: center;
}
.kpi_container h1 {
  font-size: 72px;
  font-family: 'DM Serif Display';
  font-weight: bold;
  margin-bottom: 0;
}
.kpi_container p {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.85);
}
.mid_container.kpi_container h1,
.mid_container.kpi_container p {
  text-align: center;
  color: #fff;
}
.vertical_hr {
  border-left: 1px solid rgba(0, 0, 0, 0.25);
  height: 50%;
  position: absolute;
  top: 25%;
  width: 1px;
  right: 0;
}

/* Journal */
.journal_wrapper .page-header {
  min-height: 55vh;
}
.journal.header_keywords {
  font-size: 66px;
}
.journal.header_keywords b {
  font-size: 121px;
}
.journal_info .work-info.info.info-horizontal,
.other_stories_carousel .owl-carousel .owl-item {
  transform: translateY(0);
  transition: 0.5s all;
}
.journal_info .work-info.info.info-horizontal:hover,
.other_stories_carousel .owl-carousel .owl-item:hover {
  transform: translateY(-30px);
}
.journal_info {
  padding: 70px 0 100px;
  position: relative;
}
.journal_info .work-info {
  flex: 0 0 30.33333%;
}
.journal_section .journal_info:after {
  content: '';
  flex: 0 0 30.333%;
}
.journal_img {
  margin-bottom: 20px;
  position: relative;
}
.journal_img .work_image {
  margin-bottom: 0;
}
.journal_info .info-title.work-title b {
  font-size: 23px !important;
}
.journal_info .work-info,
.journal_info .work-info img {
  margin-top: 0 !important;
  border-radius: 0;
}
.journal_info .info-title.work-title {
  font-size: 23px;
}
.journal_info .project_highlight {
  font-size: 15px;
  color: #626262;
  text-transform: uppercase;
}
.journal-footer{
  color: #D86728 !important;
  font-family: Georgia, serif;
  font-size: 19px;
  font-weight: 700;
}
.journal-footer-small{
  color: black;
  font-family: 'Trebuchet MS', Helvetica, sans-serif;
  font-size: 17px;
}
.journal-footer-small a{
  color: #000;
}
.load_more {
  display: block;
  color: #000;
  letter-spacing: 3.33px;
  font-size: 15px;
  padding-bottom: 5px;
  border: none;
  border-bottom: 1px solid #000;
  width: fit-content;
  background-color: transparent;
  padding: 0 0 10px;
  outline: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0px;
}
.load_more:hover,
.load_more:focus {
  font-weight: bold;
  color: #000;
  outline: 0;
}
.loading_img {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 150px;
  bottom: 0;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background: linear-gradient(90deg, black 0%, #05314e 100%);
  display: block;
}
.journal_info .work-info {
  padding: 0;
  margin: 70px 0 50px;
}
.work-info:hover .overlay {
  opacity: 1;
}
.overlay_content {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.overlay_content img {
  max-width: 40px;
}
.overlay_content p {
  color: #fff !important;
  font-size: 21px;
  margin: 10px auto;
}
.modal-open .modal {
  background: rgba(0, 0, 0, 0.6);
}
.right_modal {
  max-width: 40vw;
  margin-right: -20vw;
  height: 100vh;
  max-height: 100%;
  top: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.right_modal .modal-content {
  height: 100vh;
  border-radius: unset;
  overflow: auto;
}
.modal.show .right_modal {
  transform: translate(-20vw, 0) !important;
}
.modal.fade .modal-dialog.right_modal {
  transform: unset;
}
.modal .modal-header .close {
  color: #000;
  opacity: 1;
}
.right_modal h4 {
  font-size: 39px;
  color: #000;
  font-family: 'DM Serif Display';
  font-weight: 300;
  margin-top: 30px !important;
}
#float-label {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 350px;
}
#float-label input {
  width: 100%;
  padding: 14px 16px 0 10px;
  outline: 0;
  border: none;
  border-bottom: 1px solid #979797;
  background: #fff;
  font-size: 16px;
  margin-top: 20px;
  font-family: FSLucasProRegular;
  color: #000;
  border-radius: 0;
}
#float-label label {
  font-size: 18px;
  padding: 0 12px;
  color: #000;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 26px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  text-transform: uppercase;
}

#float-label .form-group:focus-within label {
  transform: translate(0, 12px) scale(0.75);
}
#float-label .Active {
  transform: translate(0, 12px) scale(0.75);
}
.right_modal .back_btn {
  margin: auto;
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #000;
  position: relative;
  bottom: unset;
  margin-top: 50px;
  justify-content: center;
  width: fit-content;
}
.right_modal .back_btn:hover,
.right_modal .back_btn:focus {
  opacity: 1;
  color: #000;
  cursor: pointer;
  outline: 0;
}

/* Journal Details */
.back_btn {
  font-size: 15px;
  letter-spacing: 3.33px;
  color: #fff;
  line-height: 1.3;
  position: absolute;
  bottom: -10vh;
}
.back_btn:hover,
.back_btn:active,
.back_btn:focus {
  color: #fff;
  font-weight: bold;
}
.article_title {
  color: #ffffff;
  font-family: 'DM Serif Display';
  font-size: 52px;
}
.article_highlight {
  font-size: 73px;
  font-weight: bolder;
}
.journal_wrapper .work_content h1,
.journal_wrapper .work_content h2,
.journal_wrapper .work_content h3,
.journal_wrapper .work_content h4,
.journal_wrapper .work_content h5 {
  margin-top: 30px;
  margin-bottom: 10px;
  color: #000;
  font-family: 'DM Serif Display';
  font-weight: bold;
}
.journal_wrapper .work_content p {
  text-align: justify;
  font-size: 16px;
  font-family: FSLucasProRegular;
  opacity: 0.85;
  margin-bottom: 20px;
}
.journal_wrapper .work_content p strong {
  font-weight: bold;
}
.journal_wrapper .info-head {
  font-family: 'DM Serif Display';
  font-size: 31px;
}
.thumb-home{
  object-fit: cover;
    object-position: center;
    width: 220px;
    height: 264px;
}
.other_journal {
  padding: 25px 0;
  display: block;
  border-bottom: 1px solid #979797;
}
.other_journal:last-child {
  border: none;
}
.other_journal .info-title,
.other_journal .info-title b {
  font-size: 23px;
  font-family: 'DM Serif Display';
}
.article_download {
  border: 1px solid #979797;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 10px 20px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  display: block;
  margin: 50px auto 50px;
  max-width: 300px;
}
.article_download.ds-btn{
  border-color: #F26527;
  background-color: #F26527;
  color: #fff;
}
.article_download img {
  max-width: 30px;
}
.article_download:hover,
.article_download:focus {
  font-weight: bold;
  color: #000;
  opacity: 1;
}
.article_download.ds-btn:hover,
.article_download.ds-btn:focus {
  font-weight: bold;
  color: #fff;
  opacity: 1;
}
.journal_cta.my_team_section {
  overflow: hidden;
}
.journal_cta.my_team_section h2.section_title {
  color: #fff;
  font-size: 55px;
}
.journal_cta.my_team_section .apply_btn {
  margin: auto;
  display: block;
  outline: 0;
}

/* Career */
.only_career.career_wrapper .header_keywords {
  text-align: left;
  font-size: 121px;
  width: fit-content;
  margin: auto;
  line-height: 1;
}
.only_career.career_wrapper .header_keywords span {
  font-size: 65px;
  display: block;
  line-height: 1.2;
  padding-left: 150px;
}

.only_career .career_content {
  overflow: hidden;
}

.hide_perks {
  display: flex !important;
}
.perks_container_mobile {
  display: none;
}
.career_wide_container {
  overflow: hidden;
}
.career_wrapper .section_title.work_section_title {
  font-size: 66px;
  line-height: 1.2;
  max-width: 550px;
}
.career_wrapper .top_element {
  right: 0;
  left: unset;
  top: -27vh;
}
.career_wrapper .my_team_section .left_element {
  left: -15vw;
  bottom: -270px;
}
.career_wrapper .my_team_section h4.subtitle {
  margin-bottom: 60px;
  line-height: 1;
}
.career_container {
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
  padding: 10px 0;
}
.career_title {
  color: #fff !important;
  font-size: 23px;
  margin-bottom: 0px;
  font-family: FSLucasProSemiBd;
}
.career_container .career_position {
  font-size: 18px;
  color: #fff !important;
  text-transform: capitalize;
}
.apply_btn {
  border: 1px solid #ffffff;
  border-radius: 45.5px;
  color: #fff;
  background-color: transparent;
  letter-spacing: 2.89px;
  font-size: 13px;
  padding: 15px 20px;
  min-width: 150px;
  text-align: center;
}
.apply_btn:hover,
.apply_btn:focus {
  color: #000;
  background-color: #fff;
}
.career_content {
  background-color: #fff;
  position: relative;
  margin: 40px 0;
}
.career_content .container {
  max-width: calc(((100% - 1140px) / 2) + 1140px);
  margin-right: 0;
}
.career_content.last .container {
  margin-left: 0;
  margin-right: auto;
}
.career_content .career_content_wrapper {
  max-width: unset;
  margin: 0 0 0 auto;
  position: static;
}
.only_career .career_content_wrapper .career_text h1 {
  max-width: 350px;
}
.career_content_wrapper {
  position: relative;
  max-width: 1140px;
  margin: auto;
}
.career_img {
  border-radius: 0;
  width: 100%;
}
.career_content .career_text {
  max-width: 450px;
}
.career_content .position-static {
  padding: 0;
}
.career_content .section_title {
  font-size: 47px;
  font-family: 'DM Serif Display';
  margin-bottom: 50px;
}
.career_content .subtitle {
  font-size: 18px;
  margin-bottom: 0;
}
.perks_container {
  width: 50%;
  display: inline-block;
  margin-top: 40px;
}
.perks_img {
  width: 30px;
  margin-right: 20px;
}
.career_img.left_img {
  width: 100%;
  display: block;
}
.perks_header_img {
  display: none;
}
.desktop_career_img {
  position: relative;
  width: 100%;
}
.mobile_career_img {
  display: none !important;
}
.career_wrapper .my_team_section .left_element.careers_left_element {
  bottom: -250px;
  width: 500px;
  opacity: 1;
  left: -20vw;
}
.career_wrapper .my_team_section.positive-relative {
  padding-bottom: 120px;
  overflow: hidden;
}
.career_wrapper .my_team_section.positive-relative .section_title {
  font-size: 47px;
  font-weight: bold;
  max-width: 700px;
  line-height: 1.2;
}
.career_wrapper .my_team_section.positive-relative .left_element {
  left: 0;
  bottom: unset;
  opacity: 1;
  height: 50vh;
  top: 20vh;
  width: auto;
}
.career_wrapper .my_team_section .carousel_container .owl-carousel {
  width: 100%;
  max-width: 100%;
}
.carousel_caption {
  width: 30vw;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 9;
  padding: 50px;
  height: 100%;
  max-height: 50vh;
  bottom: 0;
  margin: auto;
  min-height: 400px;
}
.carousel_caption .dummy-title {
  font-size: 35px;
  line-height: 1.5;
  font-family: 'DM Serif Display';
}
.carousel_caption .dummy-paragraph {
  font-size: 18px;
  line-height: 1.7;
  font-family: 'FSLucasProRegular';
  font-weight: normal;
  display: block;
}
.career_wrapper .my_team_section .carousel_container .owl-carousel .owl-nav button.owl-next {
  top: 40%;
  right: 30vw;
}
.career_wrapper .my_team_section .carousel_container.career_carousel .owl-carousel .owl-nav button.owl-next {
  right: 25vw;
  bottom: unset;
}
.carousel_container.career_carousel {
  width: 63vw;
  margin: 50px 0 0 auto;
}
.carousel_container.career_carousel .owl-stage {
  display: flex;
  align-items: center;
  left: -25%;
}
.carousel_container.career_carousel .owl-item {
  margin: auto !important;
}
.show_mobile_right_element {
  display: none;
}

/* Career Details */
.career_details .page-header {
  background: linear-gradient(90deg, black 0%, #05314e 100%);
}
.career_details .left_element {
  left: unset;
  right: 10vw;
  top: -300px;
  opacity: 1;
}
.career_details .article_title {
  font-size: 75px;
  font-weight: bold;
  font-family: 'DM Serif Display';
  margin-bottom: 20px;
  line-height: 1.1;
}
.career_details .my_team_section {
  overflow: hidden;
}
.career_details .my_team_section h2.section_title,
.journal_cta.my_team_section h2.section_title {
  color: #fff;
}
.career_details h2.section_title {
  color: #000;
  font-size: 56px;
  margin-bottom: 50px;
}
.career_position {
  font-size: 23px;
  text-transform: capitalize;
}
.career_details_img {
  max-width: 450px;
  margin-top: -20vh;
  float: right;
  border-radius: 0;
  z-index: 9;
  position: relative;
  width: 100%;
}
.list_dot {
  width: 20px;
}
.career_details h1.section_title {
  text-align: center !important;
  margin-bottom: 60px;
}
.career_dots {
  padding-left: 0;
}
.career_dots li {
  max-width: 50%;
  color: #fff;
  font-size: 18px;
  width: 100%;
  display: inline-block;
  margin-bottom: 0px;
  vertical-align: top;
}
.career_dots li.subtitle {
  list-style: none;
  background: url('../img/career/Polygon.png') no-repeat left 13px;
  padding: 10px 30px;
  background-size: 20px;
}
.black_text.career_dots li {
  color: #000;
}
.career_details .apply_btn {
  display: block;
  max-width: 150px;
  margin: auto;
}
p.subtitle.white_text {
  color: #fff;
}
.bottom_element {
  position: absolute;
  bottom: -13vw;
  left: -400px;
}
.dropzone_container {
  margin-top: 50px;
  border: 1px dashed #000;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropzone {
  width: 100%;
}
.dropzone p {
  font-size: 14px;
  text-align: center;
  margin: 0;
  padding: 20px 12px;
}
.dropzone p.upload_file_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90px;
  padding: 0 0 10px;
}
.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 15px;
}
.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}
.dropzone .thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin: 0 8px 8px 0;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}
input[name='resume'] {
  display: block !important;
}
.upload_err li {
  color: #000;
}
.career_modal.right_modal .back_btn:hover:disabled,
.career_modal.right_modal .back_btn:disabled {
  opacity: 0.6;
  font-weight: normal;
  cursor: unset;
}

/* Contact */
.contact_wrapper {
  position: relative;
}
.first_contact_container {
  flex-flow: column;
}
.col-md-7::-webkit-scrollbar {
  display: none;
}

.col-md-7::-webkit-scrollbar-track {
  display: none;
}
.contact_left.header_keywords {
  font-size: 65px;
  line-height: 1;
  position: absolute;
  width: 600px;
  left: 10%;
  top: 20vh;
}
/* .position-relative.contact_one{width:100%;height:100vh;transition: all 1s ease;display: block;top:0;z-index: 1;padding-bottom: 0;} */
.position-relative.contact_one {
  width: 100%;
  height: 100vh;
  display: block;
  top: 0;
  z-index: 1;
  padding-bottom: 0;
}
.position-relative.contact_one .navbar {
  position: absolute !important;
  z-index: 9;
}
.contact_first.page-header {
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  background-position-y: center;
}
.contact_container {
  max-width: 90vw;
  justify-content: center;
}
.contact_form .contact_container {
  padding: 120px 0 100px;
}
.contact_container .row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.contact_first .header_keywords,
.contact_form .header_keywords {
  text-align: left;
  line-height: 1.1;
  font-size: 77px;
  max-width: 300px;
}
.right_content {
  padding: 30px 0;
  border-top: 0.5px solid rgba(255, 255, 255, 0.4);
  width: 100%;
}
.right_content .col-md-5 {
  padding: 0;
}
.right_content .mail_link {
  margin-bottom: 20px;
}
.right_content .apply_btn {
  display: block;
  max-width: 280px;
  margin-right: 0;
  margin-left: auto;
}
.corner_btn {
  position: absolute;
  right: 5vw;
  bottom: 5vh;
  background: url('../img/Polygon.png') no-repeat center;
  background-size: contain;
}
.corner_btn i {
  transform: rotate(90deg);
  display: block;
  padding: 15px;
  color: #fff;
}
.corner_btn i:hover,
.corner_btn i:focus {
  font-weight: bold;
  cursor: pointer;
}
.coffee_icon {
  width: 100%;
  max-width: 150px;
  display: block;
  margin: 0 auto 20px;
}

.section.fp-section {
  padding: 0;
}
.contact_form {
  overflow: hidden;
  width: 100%;
}
.show_in_mobile {
  display: none;
}
.contact_form nav {
  display: block;
}
.contact_form .navbar .navbar-toggler-bar {
  background: #000;
}
.contact_form .logo_img {
  display: none;
}
.contact_form .logo_img.dark_img {
  display: block;
}
.contact_form .header_keywords {
  color: #fff;
  width: 100%;
  max-width: 280px;
}
.contact_form .top_element {
  right: unset;
  left: 25vw;
  top: -300px;
}
.contact_form .section_title {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}
.header_keywords.black_text {
  color: #000;
}
.contact_form p.subtitle {
  font-size: 18px;
  max-width: 450px;
  margin-bottom: 50px;
}
.contact_form #float-label label,
.contact_form #float-label input {
  padding-left: 0;
}
.contact_form textarea {
  width: 100%;
  border: none;
  border-bottom: 1px solid #979797;
  margin-top: 30px;
}
.contact_form textarea:hover,
.contact_form textarea:focus {
  outline: none;
}
.error_msg {
  color: #ff4000;
}
.contact_form #float-label .submit_btn {
  margin-bottom: 0;
}
.contact_form .submit_btn {
  border: 1px solid #000000;
  border-radius: 45px;
  min-width: 120px;
  margin: auto;
  text-align: center;
  display: block;
  color: #000;
  padding: 10px 20px;
  margin-bottom: 80px;
  background-color: #fff;
  opacity: 1;
  transition: opacity 0.5 linear;
}
.submit_btn:hover,
.submit_btn:focus,
.submit_btn:active {
  background-color: #000;
  border-color: #000;
  color: #fff;
}
.contact_form .submit_btn i {
  display: none;
}
.huge_space {
  margin-bottom: 100px;
}
.exit_left {
  opacity: 1;
  transition: opacity 0.5s linear;
}
.exit_left.exit {
  opacity: 0;
  transition: all 0.5s linear;
  transform: translateX(-50px);
}
.contact_form .submit_btn:disabled {
  opacity: 0.6;
}
.contact_form .submit_btn:disabled i {
  opacity: 0;
  transition: opacity 0.5 linear;
}
.contact_form .submit_btn i {
  opacity: 1;
  transition: opacity 0.5 linear;
}
.submit_btn:disabled:hover,
.submit_btn:disabled:focus,
.submit_btn:disabled:active {
  background-color: transparent;
  color: #000;
}
.fp-scroller .contact_container {
  padding: 150px 0 100px;
  display: flex;
}

/* Page Header */
.page-header {
  background-repeat: no-repeat;
  min-height: 70vh;
  max-height: 70vh;
  background-size: cover;
  display: flex;
  align-items: center;
  z-index: 2;
}
.header_keywords {
  font-size: 7em;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-family: 'DM Serif Display';
}

.header_title {
  font-size: 64px;
  margin-bottom: 0;
  font-family: 'DM Serif Display';
  color: #fff;
}

.section h1,
.section p {
  color: #000;
}
h1.section_title {
  font-size: 66px;
}
.subtitle {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
}

/* Owl Carousel */
.carousel_container {
  margin: 50px 0 0px;
}
.carousel_container .owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  top: 40%;
  display: none !important;
}

.carousel_container .owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 45%;
  right: 12vw;
  display: block !important;
  border: 1px solid #000;
  border-radius: 50% !important;
  padding: 28px 30px !important;
  outline: none;
}
.owl-prev i,
.owl-next i {
  color: #000;
  font-size: 1.5em;
}
.owl-theme .owl-nav [class*='owl-']:hover {
  background-color: #fa641e !important;
  border-color: #fa641e !important;
}
.owl-theme .owl-nav [class*='owl-']:hover i {
  color: #fff;
}
.owl-dots {
  margin-top: 30px;
}

.owl-theme .owl-dots .owl-dot {
  outline: 0;
  position: relative;
}
.carousel_container .owl-theme .owl-dots .owl-dot span {
  width: 5px;
  height: 5px;
  margin: 10px 12px;
  color: #fff !important;
}
.owl-dots .owl-dot.active span:after {
  font-family: 'icomoon';
  content: '\e902';
  position: absolute;
  left: 2px;
  top: -7px;
  font-size: 25px;
  transform: rotate(90deg);
  color: #fff;
}
.owl-title,
.owl-paragraph {
  display: none;
}

/* Footer */
.footer {
  background-color: #000;
  padding-left: calc(25px + 1rem);
  padding-right: 25px;
}
.social_link span {
  margin-left: 10px;
  font-size: 16px;
}
.footer .title {
  letter-spacing: 1px;
}
.footer_label {
  color: #fff !important;
  font-size: 18px;
  font-family: 'FSLucasProSemiBd';
  margin-bottom: 20px;
  font-weight: bold;
  margin-top: 10px;
}
.facebook_proud {
  max-width: 110px;
}
.google_proud {
  max-width: 110px;
  margin-left: 20px;
}

@media (min-width: 1900px) {
  .navbar.navbar-transparent {
    padding-top: 30px;
  }
  .home_wrapper .service_content {
    padding-top: 100px;
  }
  .home_wrapper h1.section_title {
    font-size: 65px;
  }
  #case-study-id .container-fluid,
  #case-study-id .container-fluid .row {
    height: 80vh;
  }
  .driving_ani_col {
    margin-bottom: -10vw;
    padding-right: 20vw;
  }
  .omni_bg .omni_lottie {
    margin-bottom: 70px;
    max-width: 1000px;
    width: 100%;
  }
  .icon_image {
    max-width: 200px;
  }
  .about_wrapper .no_max_width {
    padding: 40px 0 0px;
  }
  .about_wrapper .my_team_section h1.section_title {
    font-size: 72px;
    margin-bottom: 50px;
  }
  .about_wrapper .info .info-title {
    font-size: 46px;
  }
  .about_wrapper .info .description p {
    font-size: 20px;
    max-width: 300px;
    line-height: 1.3;
  }
  .about_wrapper .container {
    max-width: 85vw;
  }
  .about_wrapper .no_max_width .subtitle {
    font-size: 24px;
  }
  .about_wrapper .my_team_section,
  .about_wrapper .breaker_section,
  .about_wrapper .about_car_section {
    padding: 120px 0;
  }
  .about_wrapper .breaker_section .subtitle,
  .about_wrapper .about_car_section .subtitle {
    font-size: 24px;
  }
  .my_team_section .carousel_container .owl-carousel {
    max-width: 90vw;
  }
  .carousel_container.office-lite-carousel {
    margin-top: 70px;
  }
  .about_wrapper .breaker_section h1.section_title {
    max-width: 700px;
  }
  .profile_name {
    font-size: 28px;
  }
  .profile_position {
    font-size: 24px;
  }
  .contact_left.header_keywords {
    top: 20vh;
    font-size: 90px;
  }
  .home_wrapper.service_wrapper .header_keywords {
    max-width: unset !important;
  }
}

@media screen and (min-width: 1600px) {
  .home_wrapper .page-header {
    height: 100vh;
  }
  .speaker_png {
    left: 28vw;
  }
  .career_wrapper .my_team_section .carousel_container.career_carousel .owl-carousel .owl-nav button.owl-next {
    right: 22vw;
    bottom: unset;
    padding: 33px 35px !important;
  }
  .career_wrapper .my_team_section .left_element.careers_left_element {
    width: 500px;
    bottom: -150px;
  }
}

@media screen and (max-width: 1500px) {
  .speaker_png {
    left: 27vw;
    right: 600px;
  }
  .grey_one {
    left: unset;
    right: 1120px;
  }
  #lottie_block div {
    top: 100px !important;
  }
  .menu_link a {
    font-size: 96px;
  }
  .small_menu {
    font-size: 52px;
  }
}

@media screen and (max-width: 1440px) {
  .navbar-collapse-header .collapse-close {
    position: absolute;
    top: 20px;
    right: 0px;
  }
  .home_wrapper .grey_section .halfway_img {
    right: 0;
  }
  #lottie_block div {
    top: 100px !important;
    width: 105% !important;
  }
  .what_matters_section .container-fluid h1 {
    font-size: 78px;
  }
  .matters_block {
    padding: 40px 50px 20px;
  }
  .block_left:after {
    right: -35px;
  }
  .about_wrapper .breaker_section h1.section_title {
    font-size: 50px;
  }
  .girl_img {
    top: -5px;
  }
  .career_content_wrapper {
    padding-top: 10vh;
  }
  .career_content .career_content_wrapper {
    padding-top: 0;
  }
  .career_content {
    padding: 4vw 0;
  }
  .only_career.career_wrapper .my_team_section .carousel_container.career_carousel .owl-carousel .owl-nav button.owl-next {
    right: 20vw;
  }
  .career_wrapper .my_team_section .left_element.careers_left_element {
    left: -23vw;
  }
  .team_ds_container .pr-0.container {
    width: 80%;
  }
  .other_stories_carousel.carousel_container .owl-carousel .owl-nav button.owl-next {
    padding: 18px 20px !important;
    bottom: unset;
    right: -20px;
  }
  .other_stories_carousel.carousel_container .owl-carousel .owl-nav button.owl-prev {
    padding: 18px 20px !important;
    right: 60px;
  }
  .footer .title {
    font-size: 34px;
  }
  .facebook_proud,
  .google_proud {
    max-width: 100px;
  }
}

@media screen and (max-width: 1200px) {
  .navbar-collapse.show {
    padding: 2.5rem 2.5rem 2.5rem 4rem;
  }
  .home_header_container {
    margin-left: 5vw;
  }
  #lottie_block div {
    top: 105px !important;
    width: 125% !important;
  }
  .matters_block {
    padding: 30px 30px 20px;
  }
  .matters_block h5 {
    margin-top: 18px;
  }
  .grey_section .left_content {
    padding: 50px 0 50px 50px;
  }
  .left_content {
    padding: 0 0 0 50px;
  }
  .omni_bg {
    width: 100%;
  }
  .grey_one {
    right: 20px;
    top: -120px;
  }
  .speaker_png {
    margin-top: 0;
    display: none;
  }
  .speaker_png.full_length {
    display: block;
    right: unset;
    position: relative;
    margin: 0 auto;
    max-width: 350px;
  }
  .home_wrapper .second_halfway .sectional_title {
    font-size: 52px;
  }
  .home_wrapper .grey_section .halfway_img {
    top: -60px;
  }
  .career_content_wrapper {
    padding-top: 0;
  }
  .what_matters_section .container-fluid {
    padding: 0 30px;
  }
  .what_matters_section .container-fluid h1 {
    font-size: 70px;
  }
  .team_ds_container .container {
    max-width: 860px;
  }
  .team_ds_img {
    left: -65px;
    width: 60px;
  }
  .team_ds_container .container .col-md-6 {
    padding-right: 0;
  }

  .menu_link a {
    font-size: 90px;
  }
  .small_menu {
    font-size: 45px;
  }
  .navbar p.text-uppercase {
    font-size: 18px;
  }
  .navbar p.white_label {
    font-size: 16px;
  }
  .navbar .collapse-brand .mail_link {
    font-size: 14px;
  }

  .footer .title {
    font-size: 28px;
  }
  .facebook_proud {
    max-width: 80px;
  }
  .google_proud {
    max-width: 80px;
  }

  /*About*/
  .about_wrapper .no_max_width .info {
    padding-top: 50px;
  }
  .about_wrapper .breaker_section h1.section_title {
    font-size: 46px;
  }
  .about_wrapper .info .description p {
    font-size: 16px;
  }
  .about_wrapper .breaker_section .breaker_link {
    margin-top: 30px;
  }
  .right_element {
    width: 300px;
    left: 75vw;
  }
  .about_wrapper .my_team_section h4.subtitle {
    font-size: 38px;
  }
  .office-lite-carousel.carousel_container .owl-carousel .owl-nav button.owl-next {
    right: 25vw;
  }
  .girl_img {
    max-width: 320px;
    right: 20px;
  }

  /*Work*/
  .breaker_section.work_breaker h1.section_title {
    font-size: 64px;
  }
  .working_wrapper .working_section h1.work_section_title {
    padding-left: 30px;
  }
  .working_wrapper .working_section .orange_hr {
    margin-left: 30px;
  }
  .working_section .orange_hr {
    margin-left: 40px;
  }
  .work_breaker_section {
    background-position: bottom;
  }
  .section.working_section .container {
    max-width: unset;
  }
  .working_section .work-info.info.info-horizontal {
    padding: 0 4vw;
  }
  .working_section .breaker_section {
    margin: 50px 0 150px;
    width: 100vw;
    overflow: hidden;
  }
  .working_section .breaker_section .subtitle {
    margin-right: 40px;
    width: 100%;
  }
  .other_stories_carousel.carousel_container .owl-carousel .owl-nav button.owl-next {
    bottom: unset;
  }
  .other_stories_carousel .info-title.work-title {
    font-size: 30px;
  }

  /*Journal*/
  .journal_info {
    padding: 60px;
  }
  .journal_info .work-info.info.info-horizontal {
    padding: 0;
  }
  .journal_section .section_title.work_section_title {
    padding-left: 40px;
  }
  .working_section .journal_info .info-title.work-title {
    font-size: 20px;
    line-height: 1.3;
  }
  .right_modal h4 {
    line-height: 1.2;
    font-size: 30px;
  }
  #float-label label {
    font-size: 16px;
  }
  .overlay_content {
    width: 100%;
    padding: 0 40px;
  }
  .overlay_content img {
    max-width: 30px;
  }
  .overlay_content p {
    font-size: 18px;
  }

  /*Journal Details*/
  .services_list.col-md-3 {
    padding-left: 0;
    padding-right: 0;
  }
  .article_download img {
    max-width: 25px;
  }
  .journal_wrapper .info-head {
    font-size: 28px;
  }
  .other_journal .info-title,
  .other_journal .info-title b {
    font-size: 18px;
  }
  .journal_cta.my_team_section h2.section_title {
    font-size: 50px;
  }
  .journal_cta.my_team_section .bottom_element {
    bottom: -250px;
  }

  /*Career*/
  .career_content .container {
    max-width: calc(((100% - 960px) / 2) + 960px);
  }
  .only_career .career_content_wrapper .career_text h1 {
    max-width: 320px;
  }
  .career_content .career_text {
    max-width: 380px;
  }
  .career_content {
    padding-top: 2vw;
  }
  .career_content .section_title {
    font-size: 40px;
  }
  .career_content.last {
    padding: 6vw 0;
  }
  .carousel_caption {
    padding: 40px;
  }
  .carousel_caption .dummy-title {
    font-size: 28px;
    line-height: 1.2;
  }
  .carousel_caption .dummy-paragraph {
    line-height: 1.5;
    font-size: 16px;
  }

  /*Career Details*/
  .career_details h2.section_title {
    font-size: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .home_wrapper.service_wrapper .header_keywords {
    font-size: 56px;
  }
  .home_wrapper .page-header {
    height: 100vh;
  }
  #lottie_block div {
    top: 120px !important;
    width: 150% !important;
  }
  .halfway_section .perks_container {
    min-width: 200px;
  }
  .career_wrapper.service_wrapper .perks_container {
    min-width: 220px;
  }
  .footer .title {
    font-size: 30px;
  }

  /*About*/
  .about_wrapper .breaker_section h1.section_title {
    font-size: 42px;
  }

  /*Work*/
  .section_title.work_section_title {
    font-size: 44px;
    line-height: 1.3;
  }
  .filter_title {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .working_section .info-title.work-title {
    font-size: 28px;
  }
  .working_section .description p {
    font-size: 16px;
  }
  .working_section .breaker_section.work_breaker_section h1.section_title {
    font-size: 56px;
  }
  .working_section .subtitle {
    font-size: 16px;
  }

  /*Journal*/
  .journal.header_keywords {
    font-size: 64px;
  }
  .journal.header_keywords b {
    font-size: 98px;
  }
  .right_modal .modal-body p {
    line-height: 1.4;
  }

  /*Career*/
  .career_content {
    padding-top: 3vw;
    padding-left: 30px;
    padding-bottom: 3vw;
  }
  .career_content .section_title {
    font-size: 32px;
    margin-bottom: 30px;
  }
  .career_content .subtitle {
    font-size: 16px;
  }
  .career_content.last {
    padding: 5vw 0;
  }
  .career_wrapper .my_team_section .left_element.careers_left_element {
    bottom: -280px;
    left: -25vw;
  }
  .perks_container {
    margin-top: 30px;
  }

  /*Contact*/
  .contact_form .section_title {
    font-size: 32px;
  }
  .contact_form p.subtitle {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .header_video {
    width: 100vw;
    height: 100vh;
    top: unset;
    bottom: 0;
    z-index: 0;
  }
  .page-header .gradient_highlight {
    background: transparent;
    padding: 0;
  }
  .home_header_container .col-md-7 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  #lottie_block {
    display: none;
  }
  #lottie_block_ipad {
    display: block;
  }
  .desktop_only,
  .matters_block.mobile_only {
    display: none;
  }
  .mobile_only {
    display: block;
  }
  .pl-5,
  .px-5 {
    padding-left: 0 !important;
  }
  .pr-5,
  .px-5 {
    padding-right: 0 !important;
  }
  .home_header_container {
    top: 20vh;
    margin-left: auto;
  }
  .home_wrapper .page-header {
    height: 100vh;
  }
  .home_wrapper.service_wrapper .header_keywords {
    font-size: 84px;
  }
  #lottie_block div {
    top: unset !important;
    bottom: 50px;
    width: 100% !important;
    right: 20vw !important;
  }
  .home_wrapper .corner_btn {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    top: unset;
    width: fit-content;
    margin: auto;
  }
  .corner_btn i {
    padding: 30px;
    font-size: 30px;
  }
  .grey_section .left_content {
    padding: 30px 0 30px 30px;
  }
  .halfway_section h6 {
    margin-bottom: 30px;
  }
  .halfway_section .sectional_title {
    font-size: 36px;
  }
  .home_wrapper .second_halfway .sectional_title {
    font-size: 38px;
  }
  .home_wrapper .grey_section .halfway_img {
    max-width: 500px;
    top: -10px;
    right: -70px;
    width: 100%;
  }
  .left_content {
    padding-left: 0px;
  }
  .speaker_png.full_length {
    max-width: 300px;
  }
  .home_wrapper .service_content {
    margin-bottom: -105px;
  }
  .career_content_wrapper video {
    width: 600px;
  }
  .what_matters_section .container-fluid {
    padding: 0 20px;
  }
  .what_matters_section .container-fluid h1 {
    font-size: 68px;
  }
  .what_matters_section .container-fluid p {
    font-size: 16px;
    margin: 30px 0;
    line-height: 1.3;
  }
  .matters_block {
    padding: 20px;
  }
  .matters_block .orange_hr {
    margin: 10px 0;
  }
  .matters_block h5 {
    font-size: 18px;
    margin-bottom: 0;
  }
  .team_ds_container .container {
    max-width: 760px;
  }
  .home_wrapper .team_ds_container .subtitle {
    font-size: 20px;
  }
  .team_ds_img {
    width: 55px;
  }
  .home_wrapper .career_content_wrapper .subtitle {
    font-size: 20px;
  }

  .home_wrapper .industry_container h1.section_title {
    font-size: 52px;
  }
  .home_wrapper .industry_container .col-sm-12.col-md-12 .subtitle {
    font-size: 18px;
  }
  .industry_box {
    margin-top: 60px;
  }
  .industry_box h1.section_title {
    font-size: 20px;
    margin-bottom: 10px;
    padding-top: 60px;
  }
  .industry_box.col-md-4 {
    flex: 0 0 100%;
    max-width: 32%;
    padding: 20px;
  }
  .industry_container .industry_box .subtitle {
    font-size: 24px;
    max-width: 100%;
  }
  .industry_date {
    font-size: 16px;
  }
  .footer .title {
    line-height: 1.3;
    font-size: 28px;
  }
  .footer .nav-link {
    font-size: 16px;
  }
  .footer .mail_link {
    font-size: 16px;
  }

  .facebook_proud {
    max-width: 120px;
  }
  .google_proud {
    max-width: 120px;
    margin-left: 0;
    margin-top: 20px;
  }
  .footer .copyright {
    max-width: 385px;
  }

  /*About*/
  .about_wrapper .my_team_section,
  .about_wrapper .breaker_section,
  .about_wrapper .about_car_section {
    padding: 80px 0;
  }
  .about_wrapper .breaker_section h1.section_title {
    font-size: 40px;
  }
  .about_wrapper .breaker_section .subtitle {
    font-size: 18px;
  }
  .about_wrapper .no_max_width .info {
    padding-bottom: 0;
  }
  .about_wrapper .breaker_section .breaker_link {
    margin-top: 25px;
  }
  .no_max_width.container {
    max-width: unset;
  }
  .about_wrapper .no_max_width {
    padding: 0px 15px 0px;
  }
  .right_element {
    width: 200px;
    left: 70vw;
  }
  .left_element {
    width: 300px;
    right: 90vw;
  }
  .icon_image {
    max-width: 70px;
  }
  .about_wrapper .info .description p {
    max-width: 230px;
  }
  .about_wrapper h1.section_title {
    font-size: 60px;
  }
  .about_wrapper .info {
    padding-left: 10px;
    padding-right: 10px;
  }
  .about_wrapper .info .info-title {
    font-size: 30px;
  }
  .about_wrapper .description p {
    font-size: 16px;
  }
  .top_element {
    top: -30vh;
  }
  .about_wrapper .my_team_section h4.subtitle {
    font-size: 34px;
  }
  .my_team_section .container {
    max-width: unset;
    padding: 0 50px;
  }
  .team_box {
    gap: 0px 40px;
  }
  .profile_name {
    font-size: 18px;
    margin: 10px auto 0px;
  }
  .profile_position {
    font-size: 14px;
  }
  .office-lite-carousel.carousel_container .owl-carousel .owl-nav button.owl-next {
    right: 15vw;
  }
  .career_wrapper.service_wrapper .service_content h1.section_title {
    font-size: 54px;
  }
  .girl_img {
    max-width: 300px;
    right: 20px;
    top: -40px;
  }

  /*Service*/
  .career_wrapper.service_wrapper .header_keywords {
    line-height: 1;
    font-size: 120px;
  }
  .career_wrapper.service_wrapper .header_keywords span {
    display: block;
    font-size: 60px;
  }
  .career_wrapper.service_wrapper .grey_section {
    margin-top: -115px;
    margin-bottom: -115px;
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .service_container_align_img {
    display: flex;
    align-items: center;
  }
  .career_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .career_wrapper.service_wrapper .halfway_section .subtitle {
    margin-bottom: 30px;
  }
  .career_wrapper.service_wrapper .halfway_section .perks_container .subtitle {
    margin-bottom: 0px;
  }

  /*Work*/
  .work_header .header_keywords {
    font-size: 6em;
  }
  .brands_container {
    display: none;
  }
  .brand_carousel {
    display: block;
  }
  .brand_carousel .team_box {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .brand_img_box {
    min-height: 211px;
  }
  .brand_carousel .owl-carousel .owl-item img {
    max-width: 120px;
  }
  .work_breaker.breaker_section {
    background: url('assets/img/about/breaker.jpg') no-repeat;
    background-size: 100% 100%;
  }
  .section_title.work_section_title {
    font-size: 40px;
  }
  .brand_carousel .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 30px;
  }
  .case-study .breaker_section .col-md-7 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .other_stories_carousel .info-horizontal .description {
    margin-bottom: 30px;
  }

  .other_stories_carousel .owl-item img.work_image {
    width: 35%;
    margin-right: 30px;
  }
  .other_stories_carousel .info-title.work-title {
    font-size: 40px;
  }

  .other_stories_carousel .description p {
    font-size: 22px;
  }

  /*Journal Details*/
  .right_modal {
    max-width: 50vw;
  }
  .right_modal h4 {
    font-size: 25px;
  }
  .overlay_content {
    padding: 0 30px;
  }
  .overlay_content img {
    max-width: 25px;
  }
  .overlay_content p {
    font-size: 16px;
    line-height: 1.4;
  }
  .journal_wrapper .page-header .article_title {
    font-size: 46px;
  }
  .journal_wrapper .info-head {
    font-size: 24px;
  }
  .other_journal .info-title,
  .other_journal .info-title b {
    font-size: 16px;
  }
  .article_download {
    padding: 10px;
    letter-spacing: 1px;
    font-size: 12px;
  }
  .article_download img {
    max-width: 23px;
    margin-left: 2px;
  }

  /*Career*/
  .career_content .container {
    max-width: 100%;
    padding-left: 65px;
  }
  .only_career .career_content.last .container {
    padding-right: 0;
  }
  .career_content.last .container {
    padding-left: 0;
    padding-right: 65px;
  }
  .career_content.last .container .row {
    justify-content: flex-start !important;
  }
  .career_wide_container .container {
    max-width: 100%;
    padding: 0 50px;
  }
  .career_content.last .hide_perks {
    padding-left: 5vw;
  }
  .career_wrapper .top_element {
    right: -20vw;
  }
  .career_content {
    padding-top: 3vw;
    padding-left: 0;
    padding-bottom: 3vw;
    margin: 100px 0;
  }
  .career_content .section_title {
    margin-bottom: 20px;
  }
  .career_content .career_text {
    padding-left: 0;
  }
  .career_content,
  .career_content.last {
    padding: 0;
  }
  .career_content.last .col-md-6 {
    padding-right: 0;
  }
  .only_career.career_wrapper .my_team_section .carousel_container.career_carousel .owl-carousel .owl-nav button.owl-next {
    right: 17vw;
  }

  /*Career Details*/
  .introduction_section {
    padding-top: 0;
  }
  .introduction_section .row {
    flex-flow: row wrap-reverse;
  }
  .introduction_section .career_details_img {
    margin: -50px auto 50px;
    display: block;
    text-align: center;
    float: none;
  }
  .career_details h2.section_title {
    margin-bottom: 40px;
  }
  .career_details .my_team_section .container {
    max-width: 720px;
    padding: 0 15px;
  }

  /*Contact*/
  .contact_wrapper .subtitle,
  .contact_wrapper .mail_link {
    font-size: 16px;
  }
  .right_content .apply_btn {
    letter-spacing: 1.5px;
    max-width: 220px;
  }
  .contact_left.header_keywords {
    font-size: 66px;
    top: 25vh;
  }
  .contact_wrapper .corner_btn i {
    padding: 20px;
    font-size: 16px;
  }
  .contact_form p.subtitle {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  #lottie_block_mobile div {
    top: 15px !important;
    bottom: 0px;
    width: 100% !important;
    left: 0px !important;
    right: unset !important;
    height: 100vh;
  }
  .logo_img.normal_logo {
    max-width: 150px;
  }
  .logo_img.sticky_logo {
    max-width: 40px;
  }
  #navbar-brand {
    margin-left: 10px;
  }
  .home_header_container {
    top: 18vh;
  }
  .home_wrapper .page-header .row {
    margin-top: 0;
  }
  .home_wrapper .grey_section .container .row {
    background-color: #fff;
  }
  .home_wrapper .grey_section .halfway_img {
    position: relative;
    right: unset;
    top: unset;
    margin: auto;
    margin-bottom: -100px;
    margin-top: -100px;
  }
  .section.halfway_section.d-none.d-sm-block.d-md-none {
    padding-bottom: 0;
  }
  .speaker_png {
    position: relative;
  }
  /* .home_wrapper .page-header{height: 550vh} */
  .home_wrapper.service_wrapper .header_keywords {
    font-size: 74px;
  }
  .home_wrapper.service_wrapper .header_title {
    font-size: 18px;
  }
  .home_wrapper .service_content {
    margin-bottom: -45px;
  }
  .career_content_wrapper video {
    width: 500px;
    height: 100%;
  }
  .matters_container {
    flex-wrap: wrap;
  }
  .career_text.col-sm-12.col-md-4 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .what_matters_section .container-fluid h1 {
    max-width: 600px;
  }
  .matters_container.position-absolute,
  .hide_in_768 {
    display: none;
  }
  .matters_container .matters_block.mobile_ver,
  .matters_container .matters_img.mobile_ver {
    display: block;
  }
  .matters_img,
  .matters_block {
    width: 50%;
  }
  .team_ds_container {
    background: none;
    padding-top: 0;
  }
  .team_ds_container .container .col-md-6 {
    padding-right: 15px;
    z-index: 2;
  }
  .team_ds_desktop {
    display: none;
  }
  .team_ds_container {
    padding-bottom: 120px;
  }
  .blue_bg {
    display: block;
    height: 200px;
    background: linear-gradient(90deg, black 0%, #072034 100%);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .blue_bg .team_ds_img {
    position: relative;
    left: unset;
    top: unset;
    transform: rotate(90deg);
    margin: auto;
    text-align: center;
    height: auto;
    display: none;
    margin-top: -50px;
  }
  .home_wrapper .halfway_section .sectional_title {
    max-width: 400px;
  }
  #case-study-id .justify-content-end .left_content {
    padding-left: 20px;
  }
  .driving_video {
    width: 500px;
    height: 500px;
    margin-left: -100px;
    margin-right: 0;
  }

  .navbar-collapse {
    height: 100% !important;
    min-height: 100vh;
  }
  .collapse-brand {
    margin-top: 60px;
  }
  .collapse-brand.col-sm-12 {
    margin-bottom: 50px;
    margin-top: 50px;
    padding: 0;
  }
  .navbar .navbar-collapse.collapse {
    overflow-y: auto;
    padding-right: 15px;
  }
  .navbar .navbar-collapse .navbar-collapse-header {
    height: 100%;
  }
  .menu_link:last-child {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.12);
  }
  .menu_link a {
    font-size: 55px;
  }
  .small_menu {
    font-size: 28px;
  }
  .navbar p.text-uppercase,
  .navbar p.white_label {
    font-size: 18px;
  }
  .navbar .collapse-brand .mail_link {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .navbar .navbar-collapse .navbar-collapse-header .collapse-close .navbar-toggler i {
    font-weight: bold;
  }

  /*About*/
  .about_wrapper .container {
    max-width: 90vw;
  }
  .page-header .row {
    margin-top: 50px;
  }
  .page-header,
  .profile-page .page-header {
    min-height: unset;
    max-height: 500px;
    height: 100vh;
  }
  .page-header .container {
    display: flex;
    justify-content: center;
  }
  .about_wrapper .page-header.about_header .header_title {
    font-size: 3em;
    margin-left: -50px;
  }
  .about_wrapper .subtitle {
    font-size: 20px;
  }
  .about_wrapper .header_keywords {
    font-size: 5em;
  }
  .about_wrapper h1.section_title {
    font-size: 52px;
  }
  .about_wrapper .my_team_section h4.subtitle {
    font-size: 28px;
  }
  .carousel_container .owl-carousel .owl-nav button.owl-next,
  .carousel_container .owl-carousel .owl-nav button.owl-prev,
  .my_team_section .carousel_container .owl-carousel .owl-nav button.owl-prev,
  .my_team_section .carousel_container .owl-carousel .owl-nav button.owl-next {
    padding: 20px 23px !important;
  }
  .my_team_section .carousel_container {
    margin-top: 50px;
  }
  .owl-prev i,
  .owl-next i {
    font-size: 1em;
  }
  .about_wrapper .breaker_section h1.section_title {
    font-size: 34px;
  }

  /*Work*/
  .working_wrapper .work_header .header_keywords {
    font-size: 7em;
  }
  .working_wrapper .work_header .header_keywords .delay07 {
    font-size: 42px;
  }
  .working_wrapper .working_section h1.work_section_title {
    padding-left: 0px;
  }
  .working_wrapper .working_section .orange_hr {
    margin-left: 0px;
  }
  .section_title.work_section_title {
    font-size: 36px;
  }
  .brand_carousel .team_box {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .brand_img_box {
    min-height: 200px;
    max-width: 300px;
    border-left: 1px solid #979797;
  }
  .journal_info {
    padding: 0px 40px 60px;
  }

  /*Journal*/
  .journal.header_keywords b {
    font-size: 70px;
  }
  .header_keywords.journal {
    font-size: 42px;
  }
  .header_keywords.journal p {
    padding-right: 0 !important;
    margin-right: -50px;
  }
  .journal_first_title.section_title.work_section_title {
    font-size: 37px;
  }
  .right_modal #float-label {
    min-width: unset;
  }

  /*Career*/
  .only_career.career_wrapper .header_keywords {
    font-size: 6em;
  }
  .only_career.career_wrapper .header_keywords span {
    padding-left: 50px;
  }
  .career_content {
    padding-right: 0;
    margin: 50px 0 0;
    padding-bottom: 0;
  }
  .only_career .career_content_wrapper .career_text h1 {
    max-width: 320px;
  }
  .career_content .career_text {
    padding-left: 50px;
    max-width: 100%;
    padding-right: 50px;
    margin-bottom: 70px;
    flex: 0 0 100% !important;
  }
  .career_content .career_content_wrapper .container {
    padding: 0;
  }
  .career_content .career_content_wrapper .container .row {
    margin: 0;
    padding: 0;
  }
  .position-static.col-sm-12 {
    position: relative !important;
    padding: 0;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .career_img {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
  .career_img.left_img {
    display: none;
  }
  .perks_header_img {
    display: block;
    width: 70vw;
    position: relative;
    margin-top: 30px;
  }
  .hide_perks {
    display: none !important;
  }
  .perks_container_mobile {
    display: block;
    padding: 0;
  }
  .perks_container_mobile .subtitle.text-left {
    padding: 0 45px;
  }
  .perks_container {
    width: fit-content;
    margin: auto;
    display: block;
  }
  .carousel_container .perks_container .perks_img {
    width: 32px;
    margin-right: 20px;
  }
  .perks_container .subtitle {
    font-size: 18px;
  }
  .career_content.last {
    padding-right: 0;
    padding-top: 0;
  }
  .career_content.last .career_content_wrapper .container {
    padding-right: 0;
  }
  .carousel_caption {
    position: relative;
    width: 80vw;
  }
  .career_content .section_title {
    line-height: 1.2;
  }
  .carousel_container.perks_carousel {
    margin-top: 30px;
  }
  .carousel_container.career_carousel {
    width: 100%;
    margin-top: 30px;
  }
  .carousel_container.career_carousel .carousel_caption {
    margin-bottom: 50px;
    min-height: 270px;
    padding: 40px 50px;
  }
  .carousel_container.career_carousel .owl-stage {
    left: 0;
  }
  .carousel_container.career_carousel .owl-item {
    margin-left: auto !important;
    margin-right: 0 !important;
  }
  .carousel_container.career_carousel .owl-item img {
    opacity: 0;
    transition: opacity 0.5s;
  }
  .carousel_container.career_carousel .owl-item.active.center img {
    opacity: 1;
  }

  .perks_carousel .owl-dots .owl-dot.active span:after,
  .perks_carousel .owl-theme .owl-dots .owl-dot.active span,
  .perks_carousel .owl-theme .owl-dots .owl-dot:hover span {
    color: #000;
  }
  .perks_carousel .owl-theme .owl-dots .owl-dot.active span,
  .perks_carousel .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #000;
  }
  .only_career.career_wrapper .my_team_section .carousel_container.career_carousel .owl-carousel .owl-nav button.owl-next {
    right: unset;
    left: 28vh;
    padding: 25px 28px !important;
  }
  .career_wrapper .my_team_section.positive-relative {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .career_wrapper.only_career .my_team_section.positive-relative .left_element {
    opacity: 0.5;
    height: 35vh;
    top: 30vh;
  }
  .show_mobile_right_element {
    position: absolute;
    height: 150px;
    right: 5vw;
    top: 30px;
  }

  /*Career Details*/
  .career_details .page-header .container {
    justify-content: flex-start;
  }
  .career_details .page-header .container .row {
    margin-top: 0;
  }
  .career_details h1.section_title {
    font-size: 56px;
  }
  .career_details .bottom_element {
    bottom: -250px;
  }

  /*Contact*/
  .contact_form .header_keywords {
    font-size: 58px;
  }
}
@media screen and (max-width: 767.5px) {
  .navbar.navbar-transparent {
    padding-top: 10px;
  }
  .collapse-brand.col-sm-12 {
    margin-top: 20px;
  }
  .navbar p.text-uppercase {
    margin-top: 10px;
  }
  .home_wrapper.service_wrapper .header_keywords {
    font-size: 70px;
  }
  .home_wrapper .halfway_section .col-md-6:last-child {
    margin-top: 0;
  }
  #case-study-id .justify-content-end .left_content {
    padding: 80px 0 0;
  }
  #case-study-id .justify-content-end.col-md-4 {
    justify-content: center !important;
  }
  .side_img.active_img {
    margin-bottom: 20px;
  }
  .team_ds_container .container {
    max-width: 500px;
  }
  .team_ds_container .px-0.col-sm-12.col-md-6 {
    padding: 0 15px !important;
  }
  .team_ds_container .pr-0.container {
    padding-right: 15px !important;
    width: 100%;
  }
  .industry_box h1.section_title {
    padding-top: 30px;
  }
  .industry_container .industry_box .subtitle {
    height: auto;
    margin-bottom: 20px;
  }
  .close_btn {
    font-size: 2em;
  }
  .collapse-brand {
    margin-top: 0px;
  }
  .navbar-collapse-header .collapse-close {
    right: 0;
  }
  .footer,
  .footer .title {
    text-align: left !important;
  }
  .footer .mail_link {
    justify-content: flex-start;
    font-weight: normal !important;
  }
  .footer .footer_label {
    text-align: left;
  }

  /*About*/
  .icon_image {
    max-width: 80px;
  }
  .about_wrapper .info .info-title {
    font-size: 28px;
    margin-bottom: 0;
    color: #000;
  }
  .about_wrapper .description p {
    font-size: 14px;
    color: #000;
  }
  .right_element {
    width: 300px;
    left: unset;
    right: 0;
    margin-top: 150px;
  }
  .left_element {
    width: 400px;
    bottom: 0;
  }
  .about_wrapper .breaker_section .subtitle {
    font-size: 16px;
  }
  .team_box {
    display: block;
  }
  .team_container {
    max-width: 260px;
    margin: auto;
  }
  .about_wrapper h1.section_title {
    font-size: 48px;
  }
  .about_wrapper .my_team_section h4.subtitle {
    font-size: 28px;
  }
  .profile_name {
    font-size: 21px;
    margin: 20px auto 10px;
  }
  .profile_position {
    font-size: 18px;
  }
  .my_team_section .carousel_container {
    margin-top: 20px;
  }
  .my_team_section .carousel_container .owl-carousel .owl-nav button.owl-prev {
    top: 40%;
    bottom: unset;
    left: 0;
    right: unset;
  }
  .my_team_section .carousel_container .owl-carousel .owl-nav button.owl-next {
    top: 40%;
    bottom: unset;
  }
  .my_team_section .owl-theme .owl-dots {
    text-align: center;
  }
  .girl_img {
    max-width: 250px;
    margin: auto;
    display: block;
    width: 100%;
    position: relative;
    top: unset;
    margin-bottom: -80px;
  }
  .office-lite-carousel .owl-stage,
  .home-carousel .owl-stage {
    align-items: flex-start;
  }
  .office-lite-carousel.carousel_container .owl-carousel .owl-nav button.owl-next {
    right: 2vw;
  }

  /*Service*/
  .career_wrapper .top_element {
    display: none;
  }
  .dark_blue_bg {
    width: 100%;
    left: 0;
  }

  /*Work*/
  .work_header .container {
    max-width: unset;
  }
  .work_header .header_keywords {
    font-size: 5em;
  }
  .section_title.work_section_title {
    line-height: 1.3;
  }
  .filter_title {
    display: inline-block;
    margin-right: 40px;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .mb-5.filter_group.col-md-6 {
    margin-bottom: 0 !important;
    padding: 0 0 0 40px;
  }
  .work_image {
    width: 100%;
  }
  .info.info-horizontal.work-info:nth-child(2),
  .work-info:nth-child(6) .work_image,
  .work-info:last-child .work_image {
    margin-top: 0;
  }

  .brand_img_box {
    margin: auto;
  }
  .breaker_section.work_breaker {
    padding-top: 50px;
  }
  .working_section .breaker_section {
    margin: 40px 0 120px;
  }
  .working_section .breaker_section .row {
    margin: auto;
    max-width: 500px;
    padding-left: 10px;
  }
  .breaker_section.work_breaker h1.section_title {
    font-size: 60px;
  }
  #brand_18 .owl-img {
    max-width: 200px;
  }
  .working_section {
    padding: 40px 0 0;
  }
  .working_section .info {
    padding: 0 20px 50px;
  }
  .working_section .info:last-child {
    padding-bottom: 0;
  }
  .working_section .info-title.work-title {
    font-size: 28px;
    font-family: 'DM Serif Display';
  }
  .working_section .description p {
    font-size: 16px;
  }
  .working_section .work-info.info.info-horizontal {
    max-width: 500px;
    margin: 0 auto 50px;
  }
  .hide_in_767 {
    display: none;
  }
  .section.work_breaker_section {
    background: url('assets/img/about/breaker.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    padding: 50px 0 0 10px;
  }
  .working_section .breaker_section.work_breaker_section h1.section_title {
    font-size: 43px;
    max-width: 50%;
  }
  .work_breaker_section.breaker_section .subtitle {
    max-width: 65%;
  }
  .section.work_breaker_section .breaker_link {
    margin-top: 30px;
  }
  .ars-mobile {
    display: block;
    max-width: 300px;
    margin: 50px auto 0;
    z-index: 3;
    position: relative;
  }
  .ars-mobile-element {
    display: block;
    max-width: 300px;
    position: absolute;
    left: -25vw;
    bottom: -15vw;
  }

  /*Journal*/
  .working_section.journal_section {
    padding-bottom: 60px;
  }
  .journal_first_title.section_title.work_section_title {
    padding-left: 25px;
    padding-right: 25px;
  }
  .journal_section .orange_hr {
    margin-left: 25px;
  }
  .working_section .journal_info .work-info.info.info-horizontal {
    padding: 0;
    flex: 0 0 47%;
    margin: 50px 0;
  }
  .right_modal {
    max-width: 70vw;
  }

  /*Journal Details*/
  .journal_wrapper .work_content {
    margin-bottom: 50px;
  }
  .journal_wrapper .work_content h3 {
    text-align: left;
  }
  .journal_wrapper .services_list.col-md-3 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .journal_wrapper .back_btn {
    bottom: -5vh;
  }
  .journal_wrapper .info-head {
    font-size: 31px;
  }
  .other_journal .info-title,
  .other_journal .info-title b {
    font-size: 23px;
  }

  /*Career Details*/
  .career_details .my_team_section .container {
    max-width: 540px;
  }
  .career_details h1.section_title {
    font-size: 50px;
  }
  .career_dots li {
    max-width: 100%;
  }

  /*Contact*/
  .position-relative.contact_one .navbar {
    position: fixed !important;
  }
  .navbar.bg-info {
    background-color: #000 !important;
  }
  .contact_wrapper {
    overflow: unset;
  }
  .contact_wrapper .position-relative.contact_one {
    position: relative !important;
  }
  .show_in_mobile {
    display: block;
  }
  .contact_container .contact_left.header_keywords {
    font-size: 42px;
    max-width: unset;
    position: relative;
    width: 100%;
    top: unset;
    left: 0;
    margin-bottom: 40px;
  }
  .contact_form {
    padding-top: 80px !important;
  }
  .contact_first .right_content {
    padding: 40px 0;
  }
  .contact_first .d-flex.right_content {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .contact_wrapper .hide_in_mobile,
  .contact_wrapper nav.navbar-transparent.navbar {
    display: none;
    position: relative;
  }
  .contact_form #float-label .submit_btn,
  .contact_container .apply_btn {
    border: none;
  }
  .contact_container .apply_btn {
    display: inline;
    padding-right: 0;
  }
  .contact_form #float-label .submit_btn i,
  .contact_container .apply_btn i {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
  .contact_wrapper .corner_btn {
    display: none;
  }
  .contact_form .top_element {
    display: none;
  }
  .fp-scroller .contact_container {
    padding: 0 0 50px;
  }
  .submit_btn:disabled:hover,
  .submit_btn:disabled:focus,
  .submit_btn:disabled:active {
    background-color: transparent;
    color: #000;
  }
}

@media screen and (max-width: 575px) {
  .header_video {
    display: none;
  }
  .page-header.hide_bg {
    visibility: hidden;
    background-color: #000;
  }
  .lottie_bg {
    object-position: right;
  }
  .header_video_mobile {
    z-index: 0;
    position: fixed;
    top: 0;
    height: 100vh;
    padding: 0;
    object-fit: cover;
    width: 100vw;
    display: block;
  }
  #lottie_block_ipad {
    display: none;
  }
  #lottie_block_mobile {
    display: block;
  }
  .d-sm-none.d-md-block {
    display: none !important;
  }
  .d-none.d-sm-block.d-md-none {
    display: block !important;
  }
  .mobile_view {
    display: block;
  }
  .section {
    padding: 40px 20px;
  }
  .corner_btn i {
    padding: 20px;
    font-size: 20px;
  }
  .home_wrapper .corner_btn {
    bottom: 90px;
    z-index: 9999;
  }
  #lottie_block_mobile svg rect {
    x: -140;
    width: 135%;
  }
  .home_header_container {
    justify-content: flex-start !important;
    margin-left: 10px;
  }
  .home_wrapper.service_wrapper .header_keywords {
    font-size: 55px;
    max-width: 300px;
  }
  .home_wrapper.service_wrapper .header_title {
    font-size: 15px;
    max-width: 400px;
    margin-top: 20px;
  }
  .home_wrapper .halfway_section .sectional_title {
    font-size: 45px;
  }
  .grey_section .left_content {
    padding: 40px 0px 30px 20px;
  }
  .halfway_section .orange_hr {
    width: 60px;
  }
  .home_wrapper .grey_section .halfway_img {
    margin-top: -60px;
  }
  .section.halfway_section.omni_bg {
    background: url('assets/img/home/desk-mobile.png') no-repeat;
    background-size: cover;
    padding: 50px 20px;
    background-position: bottom;
  }
  .halfway_section .sectional_title {
    font-size: 45px;
    line-height: 60px;
  }
  .home_wrapper .halfway_section .sectional_title {
    max-width: 350px;
  }
  .home_wrapper .service_content h1.section_title {
    font-size: 45px;
  }
  .home_wrapper .halfway_section .subtitle {
    font-size: 18px;
    max-width: 300px;
  }
  .home_wrapper .career_content_wrapper .subtitle {
    font-size: 18px;
    margin: 20px auto;
  }
  .home_wrapper .service_content {
    margin-bottom: -40px;
  }
  .career_content_wrapper video {
    width: 400px;
  }
  .what_matters_section .container-fluid h1 {
    font-size: 65px;
    max-width: 300px;
  }
  .what_matters_section .container-fluid p {
    font-size: 18px;
    line-height: 1.8;
    max-width: 300px;
  }
  .matters_img,
  .matters_block {
    width: 100vw;
  }
  .matters_container .matters_block.mobile_ver,
  .block_right,
  .block_left {
    display: none;
  }
  .matters_block {
    padding: 50px 40px;
  }
  .matters_block .orange_hr {
    display: inline-block;
    margin: 0;
    margin-left: 10px;
  }
  .matters_block h5 {
    font-size: 23px;
    margin-top: 30px;
  }
  .block_bottom {
    display: block;
  }
  .block_bottom:after {
    right: 47%;
    top: -55px;
    transform: rotate(90deg);
    content: '';
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 35px 40px 35px 0;
    border-color: transparent #f7f7f7 transparent transparent;
  }
  .section.team_ds_container {
    padding-bottom: 120px;
  }
  .speaker_png.full_length {
    max-width: 250px;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  footer.footer {
    overflow: hidden;
  }
  .footer {
    background: url('../img/ars/element.png') no-repeat;
    background-size: 300px;
    background-color: #000;
    background-position-y: 200px;
    background-position-x: calc(100% + 50px);
    background: none;
    background-color: #000;
  }
  .footer .footer_label {
    font-size: 18px;
  }
  .footer .social_col,
  .footer .col-sm-3.col-md-2 {
    max-width: 49.99%;
    flex: 0 0 49.99%;
    padding-right: 0;
    padding-left: 0;
  }
  .footer .social_col.col-sm-3.col-md-2 {
    padding-left: 15px;
  }
  .facebook_proud {
    max-width: 100px;
  }
  .google_proud {
    max-width: 100px;
    margin-left: 20px;
    margin-top: 0px;
  }

  .home_wrapper footer.footer .copyright {
    margin-bottom: 80px;
  }

  /*About*/
  .about_wrapper .my_team_section,
  .about_wrapper .breaker_section,
  .about_wrapper .about_car_section {
    padding: 60px 0;
  }
  .hide_about {
    display: none !important;
  }
  .about_slider {
    display: block;
  }
  .about_wrapper .no_max_width {
    padding: 20px 15px;
  }
  .about_wrapper .description {
    margin-bottom: 20px;
  }
  .about_wrapper .description .info-title {
    font-size: 35px;
    margin-bottom: 10px;
    color: #000;
    text-align: center;
  }
  .about_wrapper .description p {
    font-size: 18px;
    color: #000;
    text-align: center;
    line-height: 1.3;
  }
  .about_slider .owl-img {
    max-width: 150px;
    width: 100%;
    margin: 0px auto;
    display: block;
  }
  .about_slider .owl-theme .owl-dots .owl-dot.active span,
  .about_slider .owl-theme .owl-dots .owl-dot:hover span {
    background: #000;
  }
  .about_slider .owl-dots .owl-dot.active span:after {
    color: #000;
  }
  .page-header,
  .profile-page .page-header {
    min-height: unset;
    max-height: 375px;
    height: 100vh;
  }
  .about_wrapper .page-header.about_header .header_title {
    font-size: 34px;
    margin-left: -7vw;
  }
  .about_wrapper .header_keywords {
    font-size: 53px;
    margin-bottom: -20px;
  }
  .about_wrapper h1.section_title {
    font-size: 42px;
    text-align: center !important;
  }
  .my_team_section {
    padding: 60px 0;
  }
  .about_wrapper .my_team_section h4.subtitle {
    text-align: center !important;
    font-size: 22px;
  }
  .my_team_section h4.subtitle br {
    display: none;
  }
  .about_wrapper .breaker_section h1.section_title {
    text-align: left !important;
  }
  .office-lite-carousel.carousel_container .owl-carousel .owl-nav button.owl-next {
    right: -10px;
    top: 38%;
  }
  .about_wrapper .my_team_section .carousel_container .owl-carousel .owl-nav button.owl-next,
  .about_wrapper .my_team_section .carousel_container .owl-carousel .owl-nav button.owl-prev {
    top: 30%;
    margin: 0;
  }
  .about_wrapper .my_team_section .carousel_container .owl-carousel .owl-nav button.owl-prev {
    left: 0;
    right: unset;
    padding: 20px 23px !important;
  }
  .girl_img {
    margin-bottom: -60px;
    margin-top: 30px;
  }

  /*Work*/
  .working_wrapper .work_header .header_keywords {
    font-size: 5em;
  }
  .working_wrapper .work_header .header_keywords .delay07 {
    font-size: 32px;
  }
  .career_wrapper.service_wrapper .section.halfway_section {
    padding-left: 0;
    padding-right: 0;
  }
  .work_header .header_keywords {
    font-size: 53px;
    margin-bottom: 0;
  }
  .section_title.work_section_title {
    font-size: 37px;
  }
  .working_section {
    padding: 40px 0 0;
  }
  .working_section .mb-5.col-md-6 {
    margin-bottom: 20px !important;
  }
  .breaker_section.work_breaker h1.section_title {
    font-size: 50px;
  }
  .brand_img_box {
    padding: 20px;
    min-height: 150px;
  }
  #brand_3 .owl-img {
    max-width: 80px;
  }
  #brand_7 .owl-img,
  #brand_20 .owl-img {
    max-width: 100px;
  }
  #brand_13 .owl-img,
  #brand_14 .owl-img,
  #brand_19 .owl-img {
    max-width: 80px;
  }
  .working_section .work-info.info.info-horizontal {
    padding: 0 40px;
  }
  .filter_group {
    padding-left: 40px;
  }
  .working_section .breaker_section .row {
    padding-left: 20px;
  }
  .working_section .breaker_section {
    margin: 0px 0 70px;
  }
  .working_section .breaker_section.work_breaker_section h1.section_title {
    max-width: 70%;
  }
  .ars-mobile {
    margin: 50px 20px 0;
  }
  .ars-mobile-element {
    bottom: -100px;
    max-width: 250px;
  }
  .sample_work_img {
    display: none;
  }
  .sample_work_img_mobile {
    display: block;
  }

  /*Journal*/
  .journal_wrapper .page-header {
    max-height: 500px;
  }
  .journal.header_keywords b {
    font-size: 53px;
  }
  .header_keywords.journal {
    font-size: 34px;
  }
  .journal_first_title.section_title.work_section_title {
    font-size: 30px;
  }
  .journal_info {
    padding-bottom: 0;
  }
  .working_section .journal_info .work-info.info.info-horizontal {
    padding: 0;
    flex: 0 0 100%;
  }
  .journal_info .project_highlight {
    font-size: 12px;
  }
  .journal_section.working_section .load_more {
    margin-bottom: 0px;
    font-weight: bold;
  }
  .journal_wrapper .page-header .article_title {
    font-size: 34px;
  }
  .journal_wrapper .page-header .back_btn,
  .journal_wrapper .page-header .article_title {
    padding-left: 20px;
    padding-right: 20px;
  }
  .journal_wrapper .back_btn {
    bottom: -120px;
  }
  .journal_wrapper .article_highlight {
    font-size: 65px;
  }
  .modal.fade .right_modal {
    margin-left: auto;
    right: -20%;
    margin-right: 0;
    transform: translate(-20vw, 0) !important;
  }
  .overlay_content {
    width: 70%;
  }
  .overlay_content img {
    max-width: 35px;
  }
  .overlay_content p {
    font-size: 20px;
    line-height: 1.4;
  }
  .journal_cta.my_team_section h2.section_title {
    font-size: 46px;
  }

  /*Career*/
  .career_wrapper.only_career .page-header {
    background-position: center;
  }
  .only_career.career_wrapper .header_keywords {
    font-size: 53px;
  }
  .only_career.career_wrapper .header_keywords .header_title {
    font-size: 34px;
  }
  .career_container {
    display: block !important;
  }
  .only_career.career_wrapper .my_team_section h4.subtitle {
    margin-bottom: 30px;
    font-size: 34px;
  }
  .career_title {
    margin-top: 12px;
    font-size: 18px;
  }
  .career_container .career_position {
    font-size: 16px;
    margin-top: 10px;
  }
  .career_container .apply_btn {
    border: none;
    text-align: left;
    padding: 15px 0;
    font-size: 15px;
    display: flex;
    align-items: center;
    width: fit-content;
  }
  .career_content {
    padding: 40px 0 0;
    padding-top: 20px;
  }
  .career_content .career_content_wrapper h1.section_title {
    font-size: 37px;
  }
  .career_wide_container,
  .only_career .career_content {
    position: relative;
    overflow: hidden;
  }
  .career_wide_container .container {
    padding: 0 25px;
  }
  .perks_header_img {
    width: 80vw;
  }
  .career_content.last {
    margin-bottom: 30px;
    padding-top: 0;
    overflow: hidden;
    position: relative;
  }
  .career_content .career_text {
    padding-left: 45px;
  }
  .carousel_caption {
    padding: 40px;
  }
  .career_carousel .dummy-title {
    font-size: 31px;
    line-height: 1.4;
  }
  .carousel_caption .dummy-paragraph {
    font-size: 16px;
  }
  .only_career .my_team_section {
    padding-bottom: 65px;
  }
  .only_career .my_team_section.positive-relative {
    padding-bottom: 40px;
  }
  .only_career .my_team_section.positive-relative .section_title {
    font-size: 30px;
    line-height: 1.4;
  }
  .only_career .career_container .apply_btn:hover,
  .only_career .career_container .apply_btn:focus {
    background-color: transparent;
    color: #fff;
  }
  .only_career.career_wrapper .my_team_section .carousel_container.career_carousel .owl-carousel .owl-nav button.owl-next {
    left: 5vh;
  }
  .career_wrapper .my_team_section .left_element.careers_left_element {
    display: none;
  }
  .show_mobile_right_element {
    height: 200px;
    right: 5vw;
    top: 20px;
  }

  /*Career Details*/
  .journal_wrapper.career_details .page-header {
    min-height: unset;
  }
  .career_details .career_position {
    font-size: 17px;
  }
  .career_details .career_position {
    padding-left: 20px;
  }
  .career_details .back_btn {
    bottom: -50px;
  }
  .introduction_section {
    padding-top: 0;
  }
  .career_details .my_team_section {
    padding-left: 15px;
    padding-right: 15px;
  }
  .career_details h2.section_title,
  .career_details h1.section_title {
    font-size: 46px;
    text-align: left !important;
    margin-bottom: 40px;
  }
  .max_title {
    max-width: 300px;
    line-height: 1.25;
  }
  .career_dots {
    margin-bottom: 0;
  }
  .career_details h2.section_title.white_text {
    text-align: center !important;
  }
  .career_dots li.subtitle {
    padding-right: 0;
  }

  /*Contact*/
  .contact_first.page-header,
  .contact_wrapper {
    max-height: unset;
    height: 100%;
  }
  .contact_one .contact_left.header_keywords {
    margin-top: 50px;
    width: 220px;
    font-size: 58px;
  }
  .contact_form {
    padding-left: 0;
    padding-right: 0;
    padding-top: 50px !important;
  }
  .contact_form .contact_container .row {
    margin-left: 0;
    margin-right: 0;
  }
  .contact_form .section_title {
    font-size: 36px;
    font-family: 'DM Serif Display';
  }
  .contact_one .right_content {
    flex-wrap: wrap;
  }
  .contact_first .right_content:first-child {
    padding: 40px 0 10px;
  }
  .contact_first .d-flex .pl-0.pr-0.col-12,
  .contact_first .d-flex .pl-0.pr-0.col-12 .mail_link {
    justify-content: flex-start !important;
    margin-bottom: 15px;
    text-align: left;
  }
  .contact_first .d-flex .pl-0.pr-0.col-12.text-right {
    text-align: left !important;
  }
  .contact_first .d-flex .pl-0.pr-0.col-12 .apply_btn {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
  }
  .contact_form p.subtitle {
    margin-bottom: 20px;
  }
  .contact_form .contact_container {
    padding-top: 0;
    padding-bottom: 50px;
  }
  footer.footer {
    padding-left: 0;
    padding-right: 0;
  }
  footer.footer .row {
    margin-left: 0;
    margin-right: 0;
  }
  footer.footer .copyright {
    margin-right: 0;
  }
}

@media screen and (max-width: 480px) {
  .navbar-collapse-header .collapse-close {
    top: 15px;
  }
  .home_wrapper.service_wrapper .header_keywords {
    font-size: 50px;
  }
  #lottie_block_mobile div {
    top: 55px !important;
  }
  #lottie_block_mobile svg rect {
    x: -140;
    y: -10vh;
    width: 160%;
    height: 200vh;
  }
  .career_content_wrapper video {
    margin-left: -10vw;
    width: 103vw;
  }
  .home_wrapper .grey_section .container .col-md-6 {
    padding: 0px 10px 60px;
  }
  .home_wrapper .halfway_section.d-none.d-sm-block.d-md-none .col-md-6:last-child {
    padding: 0;
  }
  .what_matters_section .container-fluid h1 {
    font-size: 56px;
    max-width: 250px;
  }
  .mail_link {
    font-size: 14px;
  }
  .blue_bg .team_ds_img {
    margin-top: -20px;
    width: 50px;
  }
  .home_wrapper .industry_container h1.section_title {
    font-size: 42px;
  }
  .industry_box h1.section_title {
    font-size: 22px;
  }
  .home_wrapper .team_ds_container .subtitle {
    font-size: 16px;
  }
  .driving_video {
    margin-left: 0;
  }

  /*About*/
  .about_wrapper .container {
    max-width: 100%;
  }
  .about_wrapper h1.section_title {
    font-size: 36px;
  }
  .about_wrapper .breaker_section h1.section_title {
    font-size: 32px;
    line-height: 1.2;
  }
  .about_wrapper .breaker_section .subtitle {
    max-width: 250px;
  }
  .about_wrapper .my_team_section .container {
    padding: 0 20px;
  }
  .about_wrapper .my_team_section h4.subtitle {
    font-size: 20px;
  }
  .right_element {
    width: 230px;
  }
  .left_element {
    width: 400px;
    right: 83vw;
    bottom: -20px;
  }
  .about_wrapper .my_team_section .carousel_container .owl-carousel .owl-nav,
  .about_wrapper .my_team_section .carousel_container .owl-dots {
    margin-top: 0;
  }

  /*Work*/
  .working_wrapper .work_header .header_keywords {
    font-size: 5em;
  }
  .working_wrapper .work_header .header_keywords .delay07 {
    font-size: 26px;
  }
  .work_header .header_keywords {
    font-size: 39px;
  }
  .breaker_section.work_breaker h1.section_title {
    font-size: 40px;
  }
  .working_section .orange_hr {
    margin-left: 30px;
  }
  .mb-5.filter_group.col-md-6 {
    padding-left: 30px;
  }
  .working_section .breaker_section .row {
    padding-left: 15px;
  }
  .working_section .breaker_section.work_breaker_section h1.section_title {
    max-width: 80%;
  }
  .work_breaker_section.breaker_section .subtitle {
    max-width: 70%;
  }

  /*Journal*/
  .journal_section .section_title.work_section_title {
    padding-left: 30px;
  }
  .journal_first_title.section_title.work_section_title {
    font-size: 22px;
  }
  .journal_section .orange_hr {
    margin-left: 25px;
  }
  .overlay_content {
    width: 70%;
  }
  .overlay_content p {
    font-size: 18px;
  }
  .header_keywords.journal p {
    padding-right: 7vw !important;
  }

  /*Journal Details*/
  .journal_wrapper .page-header .article_title {
    font-size: 32px;
  }
  .journal_wrapper .back_btn {
    bottom: -100px;
  }
  .journal_wrapper .article_highlight {
    font-size: 56px;
  }
  .right_modal {
    max-width: 100vw;
  }
  .modal.fade .right_modal {
    margin-right: -20%;
  }
  .journal_cta.my_team_section h2.section_title {
    font-size: 37px;
  }
  .journal_cta.my_team_section .subtitle {
    font-size: 16px;
  }
  .journal_cta.my_team_section .container {
    padding: 0 15px;
  }
  .journal_cta.my_team_section .bottom_element {
    bottom: -250px;
    left: -300px;
  }

  /*Career*/
  .only_career .subtitle {
    font-size: 16px;
  }
  .only_career.career_wrapper .my_team_section h4.subtitle {
    font-size: 25px;
  }
  .only_career .my_team_section .container {
    padding: 0 45px;
  }
  .only_career.career_wrapper .career_content .career_content_wrapper h1.section_title {
    text-align: left !important;
    width: 100%;
  }
  .only_career.career_wrapper .career_content.last .career_content_wrapper h1.section_title {
    text-align: center !important;
  }
  .contact_container .contact_left.header_keywords {
    max-width: 250px;
    width: 100%;
  }
  #float-label {
    min-width: unset;
  }
  .carousel_container.career_carousel .carousel_caption {
    margin-bottom: 50px;
  }

  /*Career Details*/
  .career_details .back_btn {
    bottom: -50px;
  }
  .max_title {
    max-width: 200px;
  }
  .career_details .bottom_element {
    bottom: -250px;
    left: -300px;
  }
  .career_details h2.section_title,
  .career_details h1.section_title {
    font-size: 37px;
  }
  .career_details .work_content,
  .career_dots li,
  .career_details .subtitle {
    font-size: 16px;
  }
  footer.footer .px-5.container-fluid {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (max-width: 375.5px) {
  #lottie_block_mobile svg rect {
    width: 120%;
    height: 210vh;
  }
  /* .home_wrapper .page-header{height: 700vh} */
  .home_wrapper.service_wrapper .header_keywords {
    font-size: 42px;
  }
  .home_wrapper .service_content h1.section_title {
    font-size: 40px;
  }
  .career_content_wrapper video {
    margin-left: -10vw;
    width: 100vw;
  }
  .home_wrapper .halfway_section .sectional_title {
    font-size: 36px;
    line-height: 50px;
    max-width: 270px;
  }
  .section.team_ds_container {
    padding-bottom: 100px;
  }
  .blue_bg {
    height: 150px;
  }
  .blue_bg .team_ds_img {
    margin-top: 0px;
    width: 30px;
  }
  .home_wrapper .industry_container h1.section_title {
    font-size: 32px;
  }
  .industry_box h1.section_title {
    font-size: 22px;
  }
  .footer {
    padding-left: 0;
    padding-right: 0;
  }

  /*About*/
  .about_wrapper .page-header.about_header .header_title {
    font-size: 30px;
    margin-left: -5vw;
  }
  .about_wrapper .header_keywords {
    font-size: 48px;
  }
  .about_wrapper h1.section_title {
    font-size: 32px;
  }
  .about_wrapper .breaker_section h1.section_title {
    font-size: 32px;
  }
  .about_wrapper .breaker_section .subtitle {
    font-size: 18px;
    max-width: 350px;
  }
  .girl_img {
    max-width: 200px;
    margin-top: 20px;
  }

  /*Work*/
  .working_wrapper .work_header .header_keywords {
    font-size: 4em;
  }
  .working_wrapper .work_header .header_keywords .delay07 {
    font-size: 22px;
    padding-left: 30px;
  }
  .work_header .header_keywords {
    font-size: 39px;
  }
  .breaker_section.work_breaker h1.section_title {
    font-size: 34px;
  }
  .section_title.work_section_title {
    font-size: 34px;
  }
  .filter_title {
    margin-right: 20px;
  }
  .working_section .breaker_section.work_breaker_section h1.section_title,
  .work_breaker_section.breaker_section .subtitle {
    max-width: 100%;
  }
  .ars-mobile {
    max-width: 250px;
  }

  /*Journal*/
  .journal.header_keywords b {
    font-size: 44px;
  }
  .journal_first_title.section_title.work_section_title {
    font-size: 30px;
    padding-left: 25px;
  }
  .header_keywords.journal {
    font-size: 30px;
  }
  .header_keywords.journal p {
    margin-right: -15px !important;
  }
  .overlay_content {
    width: 100%;
  }
  .overlay_content img {
    max-width: 30px;
  }
  .overlay_content p {
    font-size: 18px;
  }

  /*Journal Details*/
  .journal_wrapper .page-header {
    max-height: 600px;
  }
  .journal_wrapper .page-header .article_title {
    font-size: 30px;
  }
  .journal_wrapper .article_highlight {
    font-size: 48px;
  }

  /*Career*/
  .career_wide_container .container {
    padding: 0px 10px;
  }
  .only_career .my_team_section .container,
  .career_content .career_text {
    padding: 0 30px;
  }
  .perks_header_img {
    width: 100vw;
  }
  .carousel_container.career_carousel .carousel_caption {
    padding: 40px 30px;
  }
  .career_details h2.section_title,
  .career_details h1.section_title {
    margin-bottom: 30px;
  }

  .contact_one .contact_left.header_keywords {
    font-size: 50px;
  }
  .career_content .career_content_wrapper h1.section_title {
    font-size: 32px;
  }

  /* smiic  */
  .smiic_hero {
    margin-top: 20px;
  }
  .car_section h2.section_title {
    font-size: 35px;
  }
  .text-23 {
    font-size: 18px;
  }
  .package-card {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 30px;
  }
  .pioneer.text-20 {
    font-size: 18px;
    line-height: 1.3;
  }
  .breaker-text .text-40 {
    font-size: 35px;
    line-height: 1.3;
  }
  .broker-img.p-5{
    padding: 1rem !important;
  }
}
@media screen and (max-width: 320px) {
  .logo_img.normal_logo {
    max-width: 120px;
  }
  .navbar {
    padding: 5px 10px;
  }
  .navbar-collapse-header .collapse-close {
    top: 10px;
  }
  #lottie_block_mobile svg rect {
    height: 240vh;
  }
  .navbar .navbar-collapse.collapse {
    padding-top: 30px;
  }
  .home_wrapper.service_wrapper .header_keywords,
  .home_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 34px;
  }
  .show_in_320 {
    display: none;
  }
  .hide_in_320 {
    display: none;
  }
  .home_header_container .col-md-12,
  .home_header_container .col-md-7 {
    padding-left: 0;
  }

  .home_header_container .col-md-12 .header_title {
    max-width: 220px;
  }
  .home_wrapper.service_wrapper .header_keywords {
    font-size: 38px;
    max-width: 300px;
  }
  .home_wrapper .service_content h1.section_title {
    font-size: 34px;
  }
  .career_content_wrapper video {
    margin-left: -15vw;
    width: 105vw;
  }
  .grey_section .left_content {
    padding-left: 10px;
  }
  .what_matters_section .container-fluid h1 {
    font-size: 50px;
  }
  .what_matters_section .container-fluid p {
    font-size: 16px;
    line-height: 1.4;
  }
  .team_ds_container {
    padding-top: 0;
  }
  .team_ds_container .subtitle {
    position: relative;
    margin-bottom: 20px;
  }
  .home_wrapper .industry_container h1.section_title {
    font-size: 29px;
  }
  .industry_box h1.section_title {
    font-size: 22px;
  }
  .home_wrapper .halfway_section .sectional_title {
    font-size: 32px;
    line-height: 1.2;
  }
  .home_header_container {
    margin-left: 20px;
    margin-right: 20px;
  }
  .home_wrapper.service_wrapper .header_title {
    max-width: unset;
    width: 280px;
  }

  /*About*/
  .about_wrapper .no_max_width {
    padding-left: 0;
    padding-right: 0;
  }
  .about_wrapper h1.section_title,
  .about_wrapper .breaker_section h1.section_title {
    font-size: 30px;
  }
  .about_wrapper .my_team_section h4.subtitle,
  .about_wrapper .subtitle {
    font-size: 18px;
  }

  .right_element {
    width: 150px;
  }

  /*Work*/
  .working_wrapper .working_section h1.work_section_title {
    padding-left: 0px;
  }
  .working_wrapper .working_section .orange_hr {
    margin-left: 0px;
  }
  .work_header .header_keywords {
    font-size: 36px;
  }
  .breaker_section.work_breaker h1.section_title {
    font-size: 30px;
  }
  .section_title.work_section_title {
    font-size: 30px;
    padding-left: 0;
  }
  .working_section .orange_hr {
    margin-left: 5px;
  }
  .mb-5.filter_group.col-md-6 {
    padding-left: 0;
  }
  .working_section .breaker_section,
  .working_section .breaker_section .row {
    padding-left: 0;
  }
  .working_section .work-info.info.info-horizontal {
    padding: 0 15px;
  }
  .ars-mobile {
    max-width: 220px;
  }

  /*Journal*/
  .journal_info {
    padding: 0px 20px 60px;
  }
  .journal_first_title.section_title.work_section_title {
    padding-left: 5px;
    font-size: 28px;
  }
  .journal_wrapper .info-head {
    font-size: 28px;
  }
  .other_journal .info-title,
  .other_journal .info-title b {
    font-size: 20px;
  }

  /*Career*/
  .only_career.career_wrapper .section_title.work_section_title,
  .only_career.career_wrapper .header_keywords .header_title {
    font-size: 32px;
  }
  .only_career.career_wrapper .my_team_section h4.subtitle {
    font-size: 27px;
    line-height: 1.3;
  }
  .only_career.career_wrapper .career_content .career_content_wrapper h1.section_title {
    font-size: 32px;
  }
  .only_career .my_team_section.positive-relative .section_title {
    font-size: 24px;
  }
  .career_carousel .dummy-title {
    font-size: 28px;
  }
  .footer .title {
    font-size: 26px;
  }
}
/**
 * ----------------------------------------
 * Animation
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes SlideLeft {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  50% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes SlideRight {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  50% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes FloatUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.menu_link a::after {
  border-bottom: 1px solid #7e7e7e;
  content: '';
  position: absolute;
  right: 100%;
  bottom: 0px;
  left: 0px;
  transition: right 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}
.menu_link a:hover::after {
  right: 0;
}

.menu_link a,
.nav-link,
.mail_link,
.apply_btn,
.social_link,
.breaker_link,
.submit_btn {
  -webkit-transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  width: fit-content;
}

.mail_link:hover .icon-phone-reverse {
  -webkit-animation: jello-horizontal 0.6s both;
  animation: jello-horizontal 0.6s both;
}

a.breaker_link,
a.breaker_link .icon-right-arrow,
a.mail_link .icon-right-arrow {
  display: inline-block;
  -webkit-transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
}

a.breaker_link:hover .icon-right-arrow {
  margin-left: 20px;
}

.mail_link:hover .icon-right-arrow {
  margin-right: -20px;
  padding-left: 20px;
}

.position-relative.contact_one.fadeOut {
  top: -100vh;
  transition: all 0.5s ease;
}

/*Nav Animation*/
.navbar .menu_link,
.navbar .social_link {
  -webkit-transform: translate3d(0, 100px, 0) scale(0.95);
  transform: translate3d(0, 100px, 0) scale(0.95);
  opacity: 0 !important;
}
.navbar .show .menu_link,
.navbar .show .social_link {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
  opacity: 1 !important;
}

.navbar .show .social_link {
  margin-right: 30px;
}

.navbar .show .social_link i {
  font-size: 25px;
}

.navbar .small_menu,
.navbar .collapse-brand .white_label,
.navbar .collapse-brand .text-uppercase,
.navbar .collapse-brand .mail_link {
  -webkit-transform: translate3d(100px, 0, 0) scale(0.95);
  transform: translate3d(100px, 0, 0) scale(0.95);
  opacity: 0 !important;
}
.navbar .show .small_menu,
.navbar .show .collapse-brand .white_label,
.navbar .show .collapse-brand .text-uppercase,
.navbar .show .collapse-brand .mail_link {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
  opacity: 1 !important;
}
.tra20 {
  -webkit-transition: transform 2000ms cubic-bezier(0.19, 1, 0.22, 1), opacity 2000ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 2000ms cubic-bezier(0.19, 1, 0.22, 1), opacity 2000ms cubic-bezier(0.19, 1, 0.22, 1);
}
.delay01 {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.delay02 {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.delay03 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.delay04 {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.delay05 {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.delay06 {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.delay07 {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}
.delay08 {
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
.delay09 {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}
.delay10 {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}
.delay11 {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}
.delay12 {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.intro {
  height: 100vh;
}

.intro video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.intro h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  color: white;
}

.trigger_content {
  height: 100vh;
  color: black;
}

.trigger_content h1 {
  padding-top: 300px;
  text-align: center;
  font-size: 80px;
}

/*Contact Form Animation*/
.contact_left {
  text-align: left;
  /* height: 100vh;
    position: absolute;
    top: 5vw;
    left: 5vw; */
  padding-right: 0;
  z-index: 89;
  transform: translateY(0px) translateZ(0px);
  color: #fff;
  position: relative;
  width: 55%;
}

.page-header.about_header .header_keywords {
  animation: 1s ease-out 0s 1 FloatUp;
}

.page-header.about_header .header_title {
  animation: 1s ease-out 0s 1 SlideLeft;
}
.about_wrapper .section_title {
  animation: FloatUp 0.8s ease-out;
}

.work_header.page-header .header_keywords span {
  display: inline-block;
  animation: SlideLeft 0.8s ease-out;
}
.work_header.page-header .header_keywords b {
  display: inline-block;
  animation: SlideLeft 1.1s ease-out;
}
.work_header.page-header .header_keywords span:last-child {
  animation: SlideLeft 1.4s ease-out;
}
.filter_label,
.working_section .work_section_title {
  animation: 1s ease-out 0s FloatUp;
}

.page-header .header_keywords.journal b {
  display: inline-block;
  animation: 1s ease-out 0s 1 FloatUp;
}
.page-header .header_keywords.journal .text-right {
  animation: SlideLeft 0.8s ease-out;
}
.journal_first_title {
  animation: 1s ease-out 0s 1 FloatUp;
}

.journal_wrapper .page-header .article_title {
  animation: 1s ease-out 0s FloatUp;
}
.journal_wrapper .page-header .back_btn {
  animation: 1s ease-out 0s SlideRight;
}
.journal_wrapper .work_content {
  animation: FloatUp 0.8s ease-out;
}
.journal_wrapper .other_journal,
.journal_wrapper .info-head,
.article_download {
  animation: SlideRight 0.8s ease-out;
}

.service_wrapper .header_keywords {
  animation: FloatUp 0.5s ease-out 0s;
}
.service_wrapper .header_keywords .header_title {
  animation: SlideRight 1s ease-out 0s;
  display: inline-block;
}
.career_wrapper .section_title.work_section_title {
  animation: 1s ease-out 0s 1 FloatUp;
}
.service_wrapper .subtitle {
  animation: 1s ease-out 0s 1 FloatUp;
}

.career_wrapper .header_keywords {
  animation: 1s ease-out 0s 1 SlideLeft;
}
.career_wrapper .section_title {
  animation: FloatUp 0.8s ease-out;
}

.ars_wrapper .header_keywords {
  animation: 1s SlideLeft 0s ease-out;
}
.ars_wrapper .header_title {
  animation: 1s ease-out 0s FloatUp;
}
.ars_section_title {
  animation: SlideRight 0.8s ease-out;
}

@-webkit-keyframes loader {
  0% {
    width: 0;
  }

  20% {
    width: 10%;
  }

  25% {
    width: 24%;
  }

  43% {
    width: 41%;
  }

  56% {
    width: 50%;
  }

  66% {
    width: 52%;
  }

  71% {
    width: 60%;
  }

  75% {
    width: 76%;
  }

  94% {
    width: 86%;
  }

  100% {
    width: 100%;
  }
}

@keyframes loader {
  0% {
    width: 0;
  }

  20% {
    width: 10%;
  }

  25% {
    width: 24%;
  }

  43% {
    width: 41%;
  }

  56% {
    width: 50%;
  }

  66% {
    width: 52%;
  }

  71% {
    width: 60%;
  }

  75% {
    width: 76%;
  }

  94% {
    width: 86%;
  }

  100% {
    width: 100%;
  }
}

.progress-bar .bar {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  background: rgba(255, 255, 255, 0.175);
  border-radius: 5px;
}

.progress-bar {
  border-radius: 60px;
  overflow: hidden;
  width: 100%;
  display: none;
  position: relative;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  width: 100%;
  max-width: 100%;
  background-color: unset;
  margin-top: 20px;
}

.progress-bar span {
  display: block;
}

.progress {
  -webkit-animation: loader 8s ease infinite;
  animation: loader 8s ease infinite;
  background: #f96436;
  color: #fff;
  padding: 5px;
  width: 0;
  margin-top: 15px;
  margin-bottom: 25px;
  box-shadow: unset;
}

@media (min-width: 1510px) and (max-width: 1590px) {
  .about_wrapper .about_header .container {
    margin-top: 80px;
  }
  .about_wrapper .container {
    max-width: 90vw;
  }
  .about_wrapper .no_max_width .info p {
    max-width: 330px;
  }
}
