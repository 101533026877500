.home_wrapper.service_wrapper .container-fluid {
  width: 100vw;
  height: 100vh;
}
.home_wrapper.service_wrapper .container-fluid .row {
  height: 100vh;
  display: flex;
  align-items: center;
}
.home_wrapper.service_wrapper .header_keywords {
  font-size: 6vw;
  max-width: 800px;
  text-align: center;
  margin: 0 auto 20px;
}
.home_header_container {
  position: fixed;
  left: 0;
  right: 0;
}
.awards_slider {
  z-index: 9;
}
.sticky_award {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 38%;
  z-index: 3;
}
.awards_slider .carousel-item {
  transition: transform 2.6s ease-in-out;
}
.awards_slider .carousel-item.active {
  transition: opacity 1s ease-in-out !important;
}

#case-study-id .sectional_title {
  max-width: 500px;
}
.home_wrapper.service_wrapper .halfway_section .sectional_title {
  font-size: 4vw;
  max-width: 600px;
  margin-bottom: 50px;
}
.driving_video {
  width: 100%;
  height: 100vh;
  margin-right: 0px;
}
.home_wrapper.service_wrapper .first_left_content {
  padding: 50px 0;
}
.home_wrapper.service_wrapper .grey_section.halfway_section .subtitle {
  max-width: 450px;
  font-size: 23px;
}
.home_wrapper.service_wrapper .halfway_section .subtitle {
  font-size: 23px;
}
.driving_ani_col {
  margin-bottom: -18vw;
  padding-right: 10vw;
}
.driving_ani_col div {
  max-width: 420px;
  margin-top: 30px;
}
.industry_container .reverse_title.desktop_only {
  display: block !important;
}
.industry_container .reverse_title.mobile_only {
  display: none !important;
}
#case-study-id {
  background: url('assets/img/home/bg.jpg') no-repeat;
  background-size: cover;
}
.demo_img {
  max-width: 420px;
}
.omni_bg {
  width: 100%;
  background: url('assets/img/home/desk.webp') no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 0;
}
.scroll_container {
  height: 100%;
  position: relative;
}
.sticky_col {
  align-self: stretch;
}
.omni_col {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.omni_lottie {
  position: relative;
  margin-bottom: 60px;
  max-width: 100%;
  width: 100%;
  margin-right: 0;
  margin-left: auto;
}
.omni_lottie img {
  margin-bottom: -30px;
}
.home_wrapper.service_wrapper .container-fluid .row.second_halfway {
  margin-right: 0;
  width: 100%;
  margin-left: auto;
}
.second_halfway .gradient_highlight {
  display: block;
  width: fit-content;
}
.home_wrapper .service_content {
  padding-top: 80px;
}
.home_wrapper .service_content .mail_link {
  margin-top: 30px;
  margin-bottom: 10px;
}
.ars_vid {
  max-width: 900px;
  margin-bottom: 0px;
  margin: auto;
}
.home_wrapper.service_wrapper footer .container-fluid,
.home_wrapper.service_wrapper footer .container-fluid .row {
  width: 100%;
  height: auto;
  align-items: flex-start;
}
.home_wrapper.service_wrapper .career_content_wrapper .container-fluid {
  height: 100%;
}
.home_wrapper.service_wrapper .career_content_wrapper .container-fluid .row {
  height: 100%;
}
.home_wrapper.service_wrapper .service_content {
  margin-bottom: -70px;
  background: url('assets/img/home/bg.jpg') no-repeat;
  background-size: cover;
  overflow: hidden;
}
.social_container {
  width: 100%;
  max-width: 130px;
  margin: 10px auto 20px;
  display: flex;
  justify-content: space-between;
}
.pre_team_ds {
  background: url('assets/img/home/bg.jpg') no-repeat;
  background-size: cover;
}
.follow_us {
  font-size: 28px;
  font-weight: bold;
}

.home-carousel .owl-carousel .owl-item img {
  max-width: 550px;
}

.home-carousel .owl-carousel .owl-item img.first_car_img {
  max-width: 750px;
}
.carousel_container.home-carousel {
  margin-top: 30px;
}
@media (min-width: 769px) {
  .home-carousel .owl-carousel .owl-item {
    width: auto !important;
    max-width: 100%;
    height: auto;
  }
}

@media (min-width: 1500px) {
  #case-study-id .container-fluid,
  #case-study-id .container-fluid .row {
    max-height: 750px;
  }
  .driving_ani_col {
    margin-bottom: -12vw;
    padding-right: 0;
  }
  .industry_container {
    min-height: 900px;
  }
  .omni_lottie {
    margin-bottom: 50px;
  }
  .home_wrapper.service_wrapper .container-fluid.side_container {
    width: 80vw;
  }
  .home_wrapper.service_wrapper .omni_bg .container-fluid.side_container,
  .home_wrapper.service_wrapper .omni_bg .container-fluid .row {
    max-height: 750px;
  }
}

@media (min-width: 1900px) {
  .logo_img.normal_logo {
    max-width: 200px;
  }
  .navbar-transparent.navbar .navbar-toggler-bar {
    width: 40px;
  }
  .navbar-transparent.navbar .navbar-toggler-bar.bar2,
  .navbar-transparent.navbar .navbar-toggler-bar + .navbar-toggler-bar {
    width: 40px;
    margin-top: 12px;
  }
  .home_wrapper.service_wrapper .halfway_section .sectional_title {
    margin-bottom: 60px;
  }
  .home_wrapper.service_wrapper .header_title {
    font-size: 28px;
  }
  .home_wrapper .corner_btn i {
    font-size: 24px;
    padding: 22px;
  }
  .home_wrapper.service_wrapper .halfway_section .side_container .subtitle {
    font-size: 24px;
  }
  .driving_ani_col {
    margin-bottom: -10vw;
    padding-right: 0;
  }
  .home-carousel .owl-carousel .owl-item img.first_car_img {
    max-width: 750px;
  }
  .home_wrapper.service_wrapper .omni_bg .container-fluid.side_container,
  .home_wrapper.service_wrapper .omni_bg .container-fluid .row {
    max-height: 900px;
  }
}

@media (max-width: 1600px) {
  .home_wrapper.service_wrapper .container-fluid {
    width: 100vw;
    height: 100vh;
  }
  .home_wrapper.service_wrapper .container-fluid.side_container {
    width: 80vw;
    height: 800px;
  }
  .omni_lottie {
    margin-bottom: 50px;
  }
  .home_wrapper.service_wrapper .omni_bg .container-fluid,
  .home_wrapper.service_wrapper .omni_bg .container-fluid .row {
    max-height: 800px;
  }
  .home_wrapper.service_wrapper .grey_section {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .home_wrapper.service_wrapper .halfway_section .sectional_title {
    max-width: 500px;
  }
  .driving_ani_col {
    margin-bottom: -12vw;
    padding-right: 0;
  }
}

@media (max-width: 1440px) {
  .home_wrapper.service_wrapper .header_keywords {
    margin: 100px auto 20px;
    font-size: 90px;
  }
  .home_wrapper.service_wrapper .omni_bg .container-fluid,
  .home_wrapper.service_wrapper .omni_bg .container-fluid .row {
    height: 100vh;
  }
  .home_wrapper.service_wrapper .container-fluid,
  .home_wrapper.service_wrapper .container-fluid .row,
  .industry_container {
    height: 100%;
  }
  .home_wrapper.service_wrapper #case-study-id .container-fluid.side_container {
    height: 700px;
  }
  .driving_ani_col {
    margin-bottom: -10vw;
  }

  .home_wrapper .career_content_wrapper {
    padding-top: 0;
  }
}

@media (max-width: 1200px) {
  .home_wrapper.service_wrapper .first_left_content {
    padding-left: 60px;
  }
  .home_wrapper.service_wrapper .container-fluid .second_halfway.justify-content-end.row {
    justify-content: center !important;
    width: 100%;
  }
  .home_wrapper.service_wrapper .omni_bg .container-fluid,
  .home_wrapper.service_wrapper .omni_bg .container-fluid .row {
    max-height: 600px;
  }
  .driving_ani_col {
    margin-bottom: -12vw;
  }
  .omni_lottie {
    margin-bottom: 40px;
  }
  .service_wrapper .follow_us.subtitle {
    font-size: 28px;
  }
}

@media (max-width: 1024px) {
  .home_wrapper.service_wrapper .omni_bg .container-fluid,
  .home_wrapper.service_wrapper .omni_bg .container-fluid .row {
    height: 80vh;
  }
}

@media (max-width: 992px) {
  .home_wrapper.service_wrapper .first_left_content {
    padding-left: 30px;
  }
  .home_wrapper.service_wrapper .header_keywords {
    font-size: 70px;
    margin-top: 0;
  }
  .home_wrapper.service_wrapper .halfway_section .sectional_title,
  .home_wrapper.service_wrapper h1.section_title {
    font-size: 5vw;
  }
  .home_wrapper.service_wrapper .omni_bg .container-fluid,
  .home_wrapper.service_wrapper .omni_bg .container-fluid .row {
    height: 60vh;
  }
  .home_wrapper .industry_container h1.section_title {
    font-size: 100px;
  }
  .driving_ani_col {
    margin-bottom: -14vw;
  }
  .omni_lottie {
    margin: auto;
    margin-bottom: 40px;
  }
  .scroll_container {
    max-width: unset;
  }
  .home_wrapper .team_ds_container .subtitle {
    max-width: 400px;
    margin: auto;
  }
  .side_img:hover {
    transform: unset;
    opacity: 1;
  }
  .side_img:focus {
    transform: scale(1.1);
    opacity: 0.8;
  }
  .industry_box:first-child {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .home_wrapper.service_wrapper .container-fluid .second_halfway.justify-content-end.row {
    justify-content: space-around !important;
  }
  .home-carousel .owl-carousel .owl-item img,
  .home-carousel .owl-carousel .owl-item img.first_car_img {
    max-width: unset;
  }
  .industry_container .industry_box .subcontent {
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .facebook_proud,
  .google_proud {
    max-width: 80px;
  }
}

@media (min-width: 767.6px) and (max-width: 768px) {
  .home_header_container {
    top: 30vh;
  }
  .home_wrapper.service_wrapper .header_keywords {
    font-size: 70px;
    margin-top: 0;
  }
  .home_wrapper.service_wrapper .header_title {
    font-size: 22px;
  }

  .home_wrapper.service_wrapper .container-fluid.side_container {
    width: 100vw;
  }
  .home_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 42px;
  }
  .home_wrapper.service_wrapper .grey_section.halfway_section .subtitle {
    max-width: 270px;
    font-size: 20px;
  }
  .home_wrapper.service_wrapper .halfway_section .subtitle {
    font-size: 20px;
  }
  .home_wrapper .mail_link {
    font-size: 16px;
  }
  .driving_ani_col {
    margin-bottom: -20vw;
  }
  .home_wrapper.service_wrapper #case-study-id .container-fluid.side_container {
    height: 600px;
  }
  .driving_ani_col div {
    margin-top: 0;
  }
  .home_wrapper.service_wrapper .omni_bg .container-fluid,
  .home_wrapper.service_wrapper .omni_bg .container-fluid .row {
    max-height: 550px;
  }
  .home_wrapper .service_content {
    padding-top: 60px;
  }
  .industry_container {
    padding-top: 100px;
    padding-bottom: 80px;
  }
  .pre_team_ds.career_text {
    padding-top: 70px;
    padding-bottom: 60px;
  }
  .carousel_container.home-carousel {
    margin-top: 20px;
  }
  .footer .title {
    font-size: 20px;
  }
  footer.footer a.mail_link {
    font-size: 13px;
  }
  .footer .nav-item {
    width: 100%;
  }
  footer .col-sm-6.col-md-3 {
    max-width: 20%;
  }
  footer .social_col {
    max-width: 21%;
    flex: 0 0 21%;
  }
}

@media (max-width: 767.5px) {
  .home_wrapper.service_wrapper .page-header .container {
    margin: auto;
  }
  .home_wrapper.service_wrapper .page-header .container.home_header_container {
    margin: 150px auto;
  }
  .home_wrapper.service_wrapper .header_title {
    margin-left: 0;
  }
  .home_wrapper.service_wrapper .omni_bg .container-fluid,
  .home_wrapper.service_wrapper .omni_bg .container-fluid .row {
    max-height: unset;
  }
  .home_wrapper.service_wrapper .header_keywords {
    font-size: 80px;
  }
  .home_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 50px;
  }
  .home_wrapper.service_wrapper h1.section_title {
    font-size: 42px;
  }
  .home_wrapper.service_wrapper h1.section_title.reverse_title {
    font-size: 56px;
  }
  .industry_container .reverse_title b.gradient_wording {
    background: -webkit-linear-gradient(270deg, rgba(59, 97, 104, 1) 0%, rgba(24, 38, 55, 1) 44%, rgba(255, 127, 50, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  #case-study-id .justify-content-end .justify-content-end {
    justify-content: center !important;
  }
  #case-study-id .justify-content-end video {
    margin-left: 50px;
  }
  #case-study-id .justify-content-end.col-sm-12 {
    justify-content: flex-start !important;
  }
  #case-study-id .justify-content-end .left_content {
    padding-left: 30px;
  }
  .home_wrapper.service_wrapper .omni_bg .container-fluid,
  .home_wrapper.service_wrapper .omni_bg .container-fluid .row {
    height: 100vh;
  }
  .driving_ani_col {
    margin-bottom: -130px;
  }
  .omni_lottie {
    max-width: 600px;
    margin-bottom: 50px;
  }
  .d-md-none .gradient_highlight {
    display: block;
    width: fit-content;
  }
  .d-md-none .left_content {
    padding-left: 30px;
  }
  .omni_bg .left_content {
    padding: 0px 0 50px 50px;
  }
  .industry_container .container {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 15px;
  }
  .industry_box.col-md-4 {
    max-width: 80vw;
    margin: auto;
  }
  .industry_container .align-items-center {
    align-items: flex-start !important;
  }
  .sticky_col {
    position: sticky;
    top: 100px;
    padding-right: 0;
    align-self: unset;
  }
  .industry_container .reverse_title.desktop_only {
    display: none !important;
  }
  .industry_container .reverse_title.mobile_only {
    display: block !important;
  }
  .pre_team_ds.career_text {
    padding-top: 70px;
    padding-bottom: 60px;
  }
  .facebook_proud,
  .google_proud {
    max-width: 100px;
  }
}

@media (max-width: 575px) {
  .navbar-collapse.show {
    padding: 2.5rem 2.5rem 2.5rem 3rem;
  }
  .menu_link a {
    font-size: 45px;
  }
  .sticky_award {
    width: 100%;
  }
  .home_wrapper.service_wrapper .show_bg {
    align-items: flex-start;
  }
  .home_wrapper.service_wrapper .show_bg .header_keywords {
    text-align: left;
    font-size: 55px;
  }
  .home_wrapper.service_wrapper .header_title {
    text-align: left;
    font-size: 20px;
    max-width: 350px;
  }
  .home_wrapper.service_wrapper #case-study-id .container-fluid.side_container {
    height: 100%;
    width: 100%;
  }
  #case-study-id .justify-content-end .left_content {
    padding-top: 50px;
  }
  .home_wrapper.service_wrapper .container-fluid,
  .home_wrapper.service_wrapper .container-fluid .row,
  .industry_container {
    width: 100%;
  }
  .industry_container {
    padding: 80px 20px 40px;
  }
  .home_wrapper.service_wrapper .header_keywords,
  .home_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 50px;
  }
  .home_wrapper.service_wrapper .halfway_section .sectional_title {
    line-height: 1.2;
    margin-bottom: 30px;
  }
  .home_wrapper .halfway_section .subtitle {
    margin-bottom: 30px;
  }
  .home_wrapper.service_wrapper h1.section_title {
    font-size: 42px;
  }
  .home_wrapper.service_wrapper .halfway_section .subtitle {
    font-size: 18px;
  }
  #case-study-id .justify-content-end .left_content,
  .d-md-none .left_content {
    padding-left: 0;
  }
  .pre_team_ds.career_text {
    padding-top: 40px;
  }
  .home_wrapper .team_ds_container .subtitle {
    max-width: 100%;
  }
  .home_wrapper.service_wrapper .service_content {
    margin-bottom: -35px;
  }

  .home_wrapper.service_wrapper h1.section_title.reverse_title {
    font-size: 42px;
  }

  .industry_box a {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .industry_cont {
    margin-left: 0;
    margin-top: 20px;
  }
  .omni_lottie {
    margin-bottom: 0px;
  }
  .driving_ani_col {
    margin-bottom: -23vw;
    margin-left: 40px;
  }
  .view_all {
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  .home_wrapper.service_wrapper .service_content {
    margin-bottom: -30px;
  }
  .home_wrapper.service_wrapper .page-header .container.home_header_container {
    margin: 0;
    padding: 0 20px;
    margin-top: 20vh;
    top: 0;
  }
  .home_wrapper.service_wrapper .container-fluid,
  .home_wrapper.service_wrapper .container-fluid .row,
  .industry_container {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .home_wrapper.service_wrapper .omni_bg .container-fluid,
  .home_wrapper.service_wrapper .omni_bg .container-fluid .row {
    height: 100%;
  }
  .omni_lottie {
    margin-bottom: 50px;
  }
  .home_wrapper.service_wrapper h1.section_title {
    font-size: 38px;
  }
  .home_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 44px;
  }
  .home_wrapper.service_wrapper .grey_section.halfway_section .subtitle {
    font-size: 18px;
  }
  .home_wrapper.service_wrapper .service_content {
    margin-bottom: -30px;
    padding: 50px 0 0;
  }
  .ars_vid {
    padding-left: 0;
    padding-right: 0;
  }
  .home_wrapper .service_content .mail_link {
    margin-top: 0px;
  }
  .service_wrapper .follow_us.subtitle {
    font-size: 24px;
  }
}

@media (max-width: 375.5px) {
  .home_wrapper.service_wrapper .page-header .container.home_header_container {
    margin-top: 150px;
  }
  .home_wrapper.service_wrapper .show_bg .header_keywords {
    font-size: 50px;
  }
  .home_wrapper.service_wrapper .header_title {
    font-size: 18px;
  }
  .industry_box.col-md-4 {
    max-width: 90%;
  }
  .home_wrapper.service_wrapper .industry_box h1.section_title {
    padding-top: 50px;
  }
  .industry_container .industry_box .subtitle {
    font-size: 20px;
  }
  .home_wrapper.service_wrapper .service_content {
    margin-bottom: -20px;
  }
  .home_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 40px;
  }
  .driving_ani_col {
    margin-bottom: -90px;
    margin-left: 20px;
  }
  .omni_lottie {
    margin-bottom: 50px;
  }
}

@media (max-width: 320px) {
  .home_wrapper.service_wrapper .show_bg .header_keywords {
    font-size: 42px;
    padding-left: 10px;
  }
  .home_wrapper.service_wrapper .header_title {
    font-size: 16px;
    margin-left: 10px;
  }
  .home_wrapper.service_wrapper .header_keywords {
    font-size: 38px;
  }
  .home_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .home_wrapper.service_wrapper h1.section_title {
    font-size: 34px;
  }
  .home_wrapper.service_wrapper .grey_section.halfway_section .subtitle,
  .home_wrapper .halfway_section.omni_bg .subtitle {
    font-size: 18px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1510px) and (max-width: 1590px) {
  .home_wrapper.service_wrapper .header_keywords {
    margin: 60px auto 20px;
  }
  .home_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 4.2vw;
  }
  .home_wrapper.service_wrapper .halfway_section .side_container .left_content {
    padding-left: 20px;
  }
  .omni_lottie {
    margin-bottom: 20px;
  }
}

@media (min-height: 736px) and (max-height: 746px) {
  .omni_lottie {
    margin-bottom: 45px;
  }
}

@media (min-width: 1210px) and (max-width: 1280px) {
  .home_wrapper.service_wrapper .halfway_section .first_left_content .sectional_title {
    font-size: 5vw;
  }
}

@media (min-height: 800px) and (max-width: 420px) {
  .home_wrapper.service_wrapper .page-header .container.home_header_container {
    margin-top: 200px;
    top: 0;
  }
}
