.footer{
    padding: 50px 0 20px;
    /*background: radial-gradient(ellipse at bottom, #292D61 30%, $background-black 80%);*/
    background: #000;
    [class*="container-"] {
      padding: 0;
    }

    .nav{
        display: block;
        float: left;
        margin-bottom: 0;
        list-style: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .nav-item {
      display: block;
      width: 50%;
    }

    .nav-link {
      color: #7A7A7A;
      padding: 5px 0;
      font-size: 18px;
      text-decoration: none;

      &:hover{
          text-decoration: none;
          color:$white;
      }
    }

    .title {
      text-align: left !important;
      font-size: 37px;
      color: $white;
      margin-top:0 !important;
      line-height: 1.5;
    }

    .copyright{
        font-size: $font-size-sm;
        line-height: 1.8;
        color: #8E8E8E;
        margin-top:30px;
    }

    &:after{
        display: table;
        clear: both;
        content: " ";
    }
    .social_col .nav-item{
      width:100%;
      i{
      margin-right:10px;
      }
      &:hover{
        color: $white;
      }
    }
    .mail_link{margin-bottom:10px;}
}


@media screen and (max-width: 991px){
  .footer {
    .title {
      font-size:26px;
    }
    .mail_link{
      font-size: 14px;
    }
    .footer_label{
      font-size:14px;
    }
    .nav-link{
      font-size:12px;
    }
  }
}

@media screen and (max-width: 767.5px){
  .footer {
    padding-left: 0px;

    .title{
    text-align:center !important;
    font-size:34px;
    }

    .mail_link{
    justify-content: center;
    }

    .nav-link{
    font-size:16px;
    }

    .nav{
    float:none;
    }
    .footer_label{
    margin-top:20px;
    }

    .copyright {
      margin-right: 15px;
    }
  }
}

@media screen and (max-width: 576px){
  .footer {
    text-align: center;
    .px-5{
    padding-left:20px !important;
    padding-right:20px !important;
    }
    .footer_label{
    font-size: 16px;
    }
    .copyright {
      text-align: center;
    }

    .nav{
      float: none;
      padding-left: 0;
    }
  }
}
