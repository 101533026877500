.ars_wrapper .header_keywords {
  max-width: 600px;
  width: 100%;
}
.ars_wrapper .page-header {
  background-color: #0a0e1a;
  background-size: cover;
  background-position: right;
}
.ars_section {
  padding-bottom: 0px;
}
.ars_section .ars_section_title {
  font-size: 35px;
  font-weight: normal;
  font-family: 'DM Serif Display';
  max-width: 900px;
  margin-bottom: 0;
  animation: SlideRight 1s ease-out 0s;
}

.ars_wrapper .dark_blue_bg {
  background: linear-gradient(90deg, black 0%, #05314e 100%);
  position: relative;
  width: 100%;
  left: unset;
}
.ars_breaker {
  background: url('assets/img/ars/breaker_desktop.png') no-repeat,
    linear-gradient(90deg, black 0%, #05314e 100%);
  overflow: hidden;
  position: relative;
  z-index: 2;
  background-size: contain;
  padding: 100px 0;
}
.ars_breaker .right_element {
  position: absolute;
  right: 0;
  bottom: 0;
}
.ars_breaker .subtitle {
  font-size: 49px;
  color: #fff;
  font-family: 'DM Serif Display';
  float: right;
  margin-bottom: 0;
  max-width: 400px;
}
.mid_content {
  padding-left: 50px;
}
.ars_wrapper .halfway_section .row {
  align-items: center;
  justify-content: space-between;
}
.ars_wrapper .halfway_section .sectional_title {
  max-width: 450px;
  font-size: 49px;
}
.ars_wrapper .grey_section {
  padding-top: 80px;
  padding-bottom: 80px;
  margin: 0;
}
.ars_wrapper section.dark_blue_bg.halfway_section .sectional_title {
  max-width: 400px;
}
.ars_wrapper .halfway_img {
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.shift_up_img {
  margin-top: -200px;
}
.ars_wrapper hr.orange_hr {
  max-width: 68px !important;
}
.ars_wrapper .career_content_wrapper {
  padding-top: 0;
}
.ars_wrapper .career_content_wrapper h1.section_title {
  font-size: 47px;
  color: #fff;
  max-width: 800px;
  margin: 0 auto 40px;
}

.ars_wrapper .career_content_wrapper p.subtitle {
  font-size: 22px;
  color: #fff;
  max-width: 600px;
  margin: 0 auto 30px;
}

.ars_wrapper .service_content.dark_blue_bg .mail_link {
  border-radius: 45px;
  background-color: #fff;
  padding: 10px 30px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 300;
}

.ars_wrapper .service_content img {
  left: -200px;
  bottom: -135px;
  z-index: 0;
}

.ars_wrapper .grey_section.last_mid_content {
  overflow: hidden;
  padding-top: 0;
}

.footer {
  position: relative;
}

@media (min-width: 1900px) {
  .ars_wrapper .container {
    max-width: 90vw;
  }
  .ars_wrapper .header_keywords {
    font-size: 5vw;
    max-width: 650px;
  }
  .ars_wrapper .header_title {
    max-width: 700px;
    font-size: 32px;
    margin-top: 30px;
  }
  .ars_wrapper .halfway_img {
    width: 90%;
    max-width: 100%;
  }
  .ars_section .ars_section_title {
    max-width: 75vw;
    font-size: 2.6vw;
    line-height: 1.3;
  }
  .ars_section {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .ars_wrapper .halfway_section .sectional_title {
    font-size: 3.7vw;
    max-width: 550px;
  }
  .ars_wrapper .halfway_section .subtitle {
    font-size: 24px;
    max-width: 620px;
    line-height: 1.4;
  }
  .ars_wrapper section.dark_blue_bg.halfway_section {
    margin-top: 100px;
    padding: 120px 0;
  }
  .ars_wrapper section.dark_blue_bg.halfway_section .sectional_title {
    max-width: 500px;
  }
  .ars_wrapper .career_content_wrapper h1.section_title {
    font-size: 3vw;
    max-width: 900px;
  }
}

@media screen and (max-width: 1200px) {
  .ars_wrapper .page-header {
    background-size: cover;
  }
  .ars_wrapper .halfway_img {
    max-width: 400px;
  }
  .shift_up_img {
    margin-top: -300px;
  }
  .ars_wrapper .halfway_section .sectional_title {
    font-size: 38px;
    line-height: 1.2;
  }
  .ars_wrapper .halfway_section.last_mid_content .subtitle {
    margin-bottom: 0;
  }
  .ars_wrapper .grey_section.halfway_section .container {
    max-width: 720px;
  }
}

@media screen and (max-width: 1024px) {
  .ars_wrapper.case-study .header_keywords {
    font-size: 88px;
  }
  .ars_wrapper.case-study .page-header {
    background-position: center;
  }
  .ars_section .ars_section_title {
    font-size: 34px;
  }
  .ars_wrapper .halfway_section .sectional_title {
    font-size: 40px;
  }
  .ars_wrapper .halfway_section h6 {
    margin-bottom: 40px;
  }
  .ars_breaker .subtitle {
    font-size: 40px;
  }
  .ars_wrapper .dark_blue_bg {
    min-width: unset;
  }
  .ars_wrapper .career_content_wrapper h1.section_title {
    font-size: 42px;
  }
}

@media screen and (max-width: 991px) {
  .ars_wrapper.case-study .header_keywords {
    max-width: 500px;
    font-size: 72px;
  }
  .dark_blue_bg .mid_content {
    padding-left: 15px;
  }
  .ars_wrapper .halfway_section h6 {
    margin-bottom: 30px;
  }
  .ars_wrapper.case-study .halfway_section .subtitle {
    margin-bottom: 0;
  }
  .ars_wrapper .shift_up_img {
    margin-top: 0;
  }
  .ars_wrapper .perks_container {
    margin-top: 30px;
    min-width: 160px;
  }
  .ars_wrapper .perks_container .subtitle {
    font-size: 16px;
  }
  .ars_wrapper .halfway_section.last_mid_content .mid_content {
    padding-left: 15px;
  }
  .ars_wrapper .halfway_section .sectional_title {
    font-size: 32px;
  }
}

@media screen and (max-width: 768px) {
  .ars_wrapper.case-study .header_keywords {
    max-width: 400px;
    font-size: 64px;
  }
  .ars_wrapper.case-study .header_title {
    font-size: 18px;
  }
  .ars_section .ars_section_title {
    font-size: 26px;
    line-height: 1.3;
  }
  .ars_wrapper .halfway_section .sectional_title {
    font-size: 32px;
  }
  .ars_wrapper .halfway_section .subtitle {
    font-size: 16px;
  }
  .ars_wrapper .career_content_wrapper h1.section_title {
    font-size: 32px;
    max-width: 500px;
    margin: 0 auto 50px;
  }
  .ars_wrapper .career_content_wrapper p.subtitle {
    font-size: 18px;
    max-width: 500px;
  }
  .ars_breaker {
    background: url('assets/img/ars/breaker_mobile.png') no-repeat,
      linear-gradient(90deg, black 0%, #05314e 100%);
    padding: 450px 0 100px;
  }
  .ars_breaker .subtitle {
    float: left;
    max-width: 400px;
  }
  .ars_wrapper .ars_breaker .right_element {
    width: 450px;
    left: 60vw;
    opacity: 1;
  }
}

@media screen and (max-width: 767.5px) {
  .ars_wrapper.case-study .page-header {
    background-image: linear-gradient(90deg, black 0%, #05314e 100%) !important;
    min-height: 70vh;
  }
  .ars_wrapper.case-study .page-header .container {
    justify-content: center;
    align-items: flex-start !important;
    padding-top: 20px;
    background-image: url('assets/img/ars/ars-mock.png');
    background-size: 250px;
    background-repeat: no-repeat;
    background-position: center bottom;
  }
  .ars_wrapper .grey_section.halfway_section .container {
    max-width: 540px;
  }
  .ars_wrapper.case-study .header_keywords {
    font-size: 45px;
    max-width: 300px;
  }
  .ars_wrapper.case-study h1.ars_section_title {
    font-size: 25px;
  }
  .ars_wrapper .grey_section,
  .ars_wrapper .grey_section.last_mid_content {
    padding-top: 60px;
  }
  .ars_wrapper .halfway_section .col-md-6:last-child {
    margin-top: 30px;
  }
  .ars_wrapper .halfway_img {
    max-width: unset;
  }
  .ars_wrapper .halfway_section .sectional_title {
    font-size: 35px;
    max-width: 100%;
  }
  .ars_wrapper .halfway_section .subtitle {
    max-width: 100%;
    text-align: justify;
  }
  .ars_wrapper .halfway_section .mid_content {
    margin-top: 30px;
  }
  .ars_wrapper .shift_up_img {
    margin-top: -250px;
  }
  .ars_wrapper .halfway_section:nth-child(5) {
    margin-top: -200px;
    padding-bottom: 0;
  }
  .ars_wrapper .halfway_section.grey_section.last_mid_content .row,
  .ars_wrapper .halfway_section.dark_blue_bg .row {
    flex-flow: wrap-reverse;
  }
  .ars_wrapper .halfway_section.flex_flow {
    padding-bottom: 200px;
  }
  .ars_wrapper .service_content.dark_blue_bg {
    overflow: hidden;
  }
}

@media screen and (max-width: 575px) {
  .ars_wrapper.case-study .page-header .row {
    margin-top: 10vh;
  }
  .ars_wrapper.case-study .halfway_section.grey_section .col-md-6:last-child {
    padding-left: 15px;
  }
  .ars_wrapper.case-study .page-header .container {
    background-size: 80%;
    margin-top: 0;
  }
  .ars_wrapper .halfway_section .sectional_title {
    line-height: 1.3;
  }
  .ars_wrapper.case-study .ars_section h1.section_title {
    margin-bottom: 0;
  }
  .ars_wrapper .halfway_section.flex_flow {
    padding-bottom: 250px;
  }
  .ars_wrapper .halfway_section.dark_blue_bg.flex_flow {
    padding-bottom: 220px;
  }
  .ars_breaker {
    padding: 300px 0 120px;
    background-size: cover;
  }
  .ars_breaker .subtitle {
    font-size: 35px;
  }
  .ars_wrapper .career_content_wrapper h1.section_title {
    line-height: 1.3;
  }
  .ars_breaker .right_element {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .ars_wrapper .halfway_section .sectional_title {
    font-size: 34px;
  }
  .ars_wrapper .career_content_wrapper h1.section_title {
    font-size: 22px;
    margin: 0 auto 20px;
  }
  .ars_wrapper .career_content_wrapper p.subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 375px) {
  .ars_wrapper .halfway_section .sectional_title {
    font-size: 28px;
  }
  .ars_wrapper .halfway_section.last_mid_content {
    padding-bottom: 60px;
  }
  .ars_wrapper .halfway_section.last_mid_content .d-flex {
    flex-wrap: wrap;
  }
  .ars_wrapper .halfway_section .perks_container {
    width: 100%;
    margin-top: 0;
  }
  .ars_wrapper .halfway_section .perks_container:first-child {
    margin-top: 30px;
  }
  .ars_wrapper .halfway_section .subtitle {
    font-size: 18px;
  }
  .ars_breaker {
    padding: 300px 0 120px;
  }
  .ars_breaker .subtitle {
    font-size: 35px;
  }
}

@media screen and (max-width: 320px) {
  .ars_wrapper.case-study .header_keywords {
    font-size: 42px;
  }
  .ars_wrapper.case-study .header_title {
    font-size: 15px;
  }
  .ars_wrapper.case-study h1.ars_section_title {
    font-size: 20px;
  }
  .ars_wrapper .halfway_section .sectional_title {
    font-size: 24px;
  }
  .ars_wrapper .career_content_wrapper h1.section_title {
    font-size: 28px;
  }
  .ars_wrapper .halfway_section.dark_blue_bg.flex_flow {
    padding-bottom: 200px;
  }
  .ars_wrapper .halfway_section.dark_blue_bg .mid_content {
    margin-top: 10px;
  }
  .ars_breaker {
    padding: 200px 0 60px;
  }
  .ars_breaker .subtitle {
    max-width: 250px;
    font-size: 28px;
  }
}
