.brand_img_box:nth-child(3) .brand-img,
.brand_img_box:nth-child(7) .brand-img,
.brand_img_box:nth-child(19) .brand-img {
  max-width: 100px;
}

.brand_img_box:nth-child(13) .brand-img,
.brand_img_box:nth-child(14) .brand-img {
  max-width: 80px;
}

.brand_img_box:nth-child(18) .brand-img {
  max-width: 180px;
}
.case_desktop_header {
  display: flex !important;
}
.case_mobile_header {
  display: none !important;
}

.work_mid_container {
  max-width: 85vw;
  margin: auto;
}

.working_wrapper
  .working_section
  .breaker_section.work_breaker_section
  h1.section_title
  span {
  font-family: 'DM Serif Display';
}

.working_wrapper .working_section .breaker_section.work_breaker_section {
  width: 115vw;
  margin-left: -7.5vw;
  margin-right: -7.5vw;
  min-height: 700px;
  background-position: bottom;
  padding: 160px 0 60px;
}

.working_section .breaker_section.work_breaker_section .col-md-5 {
  max-width: 550px;
}
.case-study.case_study_wrapper .case_summary .container,
.case-study.case_study_wrapper .objective_section .container,
.case-study.case_study_wrapper .breaker_section .container {
  max-width: 960px;
}
.case-study .sample_work_section .container {
  max-width: 100%;
}

.breaker_section .list-group {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}
.breaker_section .list-group .info-head {
  margin-bottom: 0;
  font-size: 32px;
}
.breaker_section .list-group .list-group-item {
  width: 33%;
  float: left;
  min-height: 80px;
}
.breaker_section .list-group .list-group-item:first-child {
  width: 100%;
  padding-left: 0;
}

.breaker_section .list-group .list-group-item .info-title {
  font-weight: normal;
  font-size: 20px;
  line-height: 1.2;
}
.case-study .case_desktop_header .header_title {
  max-width: 550px;
}
.obj_subheader {
  font-family: 'FSLucasProRegular';
  font-weight: bold;
  font-size: 26px;
  color: #000;
}
.obj_subtitle {
  font-size: 20px;
}
@media (min-width: 1900px) {
  .working_wrapper .container,
  .case-study.case_study_wrapper .case_desktop_header .container,
  .other_stories_container.container {
    max-width: 90vw;
  }
  .case-study .services_list.justify-content-end {
    justify-content: center !important;
  }

  .working_wrapper .working_section .breaker_section.work_breaker_section {
    min-height: 700px;
    background-position: bottom;
    padding-top: 200px;
    width: 115vw;
    margin-left: -7.5vw;
    margin-right: -7.5vw;
  }
  .profile-page .info {
    padding: 50px 50px 0;
  }

  .kpi_container {
    padding: 120px 0;
  }
  .kpi_container h1 {
    font-size: 90px;
  }
  .kpi_container p {
    font-size: 30px;
  }
  .other_stories_carousel .info-title.work-title {
    font-size: 50px;
  }
  .other_stories_carousel .work-info p {
    font-size: 22px;
    line-height: 1.3;
  }
}

@media (max-width: 1600px) {
  .case_study_wrapper.case-study .header_keywords {
    font-size: 5.5em;
    width: 40vw;
  }
}

@media (max-width: 1440px) {
  .working_wrapper .working_section .breaker_section.work_breaker_section {
    padding: 120px 0;
    min-height: 600px;
  }
}

@media (max-width: 1200px) {
  .service_wrapper .header_keywords {
    font-size: 100px;
  }
  .service_wrapper .header_title {
    font-size: 50px;
  }
  .career_wrapper .section_title.work_section_title {
    font-size: 50px;
  }
  .service_wrapper .subtitle {
    font-size: 16px;
  }

  .halfway_section .sectional_title {
    font-size: 50px;
  }
  .halfway_section h6 {
    font-size: 18px;
  }
  .halfway_section .col-md-6:last-child {
    padding-left: 40px;
    padding-right: 20px;
  }
  .halfway_section.grey_section .col-md-6:last-child {
    padding-left: 20px;
  }

  .grey_section {
    margin-top: -105px;
    padding-top: 200px;
  }
  .breaker_section .list-group .list-group-item .info-title {
    font-size: 20px;
  }
  /*Case Study*/
  .case-study.case_study_wrapper .page-header {
    min-height: 600px;
    max-height: 800px;
  }
  .case_study_wrapper.case-study .header_keywords {
    font-size: 4vw;
    width: 35vw;
    line-height: 1.2;
  }
  .case-study .case_desktop_header .header_title {
    max-width: 450px;
  }
}

@media (max-width: 1024px) {
  /*Case Study*/
  .case-study.case_study_wrapper .case_summary .container,
  .case-study.case_study_wrapper .objective_section .container,
  .case-study.case_study_wrapper .breaker_section .container,
  .case_study_wrapper.case-study .page-header.case_desktop_header .container,
  .case_study_wrapper.case-study .other_stories_container.container {
    max-width: 900px;
  }
  .case-study .header_keywords {
    font-size: 98px;
  }
  .case-study .section_title {
    font-size: 52px;
  }
  .other_stories_container .section_subtitle {
    font-size: 31px;
  }
}

@media (max-width: 992px) {
  .longer_subtitle {
    min-width: 350px;
  }
  .halfway_section .perks_container {
    min-width: 180px;
  }
  .halfway_section .col-md-6:last-child {
    padding-left: 20px;
  }

  /*Case Study*/
  .case-study.case_study_wrapper .case_summary .container,
  .case-study.case_study_wrapper .objective_section .container,
  .case-study.case_study_wrapper .breaker_section .container,
  .case_study_wrapper.case-study .page-header.case_desktop_header .container,
  .case_study_wrapper.case-study .other_stories_container.container {
    max-width: 95vw;
  }
  .case_study_wrapper.case-study .header_keywords {
    font-size: 48px;
    width: 45vw;
  }
  .case-study .case_desktop_header .header_title {
    max-width: 400px;
  }
  .kpi_container {
    text-align: center;
    padding: 50px 0;
  }
  .kpi_container h1 span {
    font-size: 55px;
  }
  .kpi_container h1 {
    font-size: 52px;
  }
  .kpi_container p {
    font-size: 22px;
  }
  .breaker_section .list-group .list-group-item {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .working_wrapper .section_title.work_section_title {
    font-size: 32px;
  }
  .working_wrapper .working_section .breaker_section.work_breaker_section {
    padding: 70px 0;
    min-height: 400px;
  }
  .working_section .breaker_section.work_breaker_section h1.section_title {
    font-size: 40px;
  }
  .working_wrapper .working_section .info-title.work-title {
    font-size: 20px;
  }
  .working_wrapper .working_section .work-info.info.info-horizontal {
    padding: 0 2vw;
  }
  .working_wrapper .breaker_section.work_breaker h1.section_title {
    font-size: 42px;
  }
  .working_wrapper .breaker_section .breaker_link {
    margin-top: 20px;
  }
  /*Case Study*/
  .case-study.case_study_wrapper .page-header {
    height: auto;
  }
  .case-study .page-header .container {
    justify-content: flex-start;
  }
  .case-study .header_keywords {
    font-size: 70px;
  }
  .short_title {
    font-size: 24px;
  }
  .case-study .work_content {
    font-size: 20px;
  }
  .case-study.case_study_wrapper .objective_section .work_content {
    margin-bottom: 20px !important;
  }
  .case-study.case_study_wrapper .case_summary,
  .case-study.case_study_wrapper .objective_section {
    padding: 60px 0;
  }
  .case-study.case_study_wrapper .objective_section {
    padding-bottom: 40px;
  }
  .strategyImg {
    max-width: 75px;
  }
  .obj_subtitle {
    font-size: 18px;
  }
  .case-study .breaker_section {
    padding: 60px 0;
  }
  .case-study.case_study_wrapper .other_stories {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 767.5px) {
  .working_wrapper .working_section h1.work_section_title {
    padding-left: 20px;
  }
  .working_wrapper .working_section .orange_hr {
    margin-left: 20px;
  }
  .service_wrapper .header_keywords {
    font-size: 70px;
  }
  .service_wrapper .header_title {
    font-size: 42px;
  }
  .work_mid_container {
    max-width: 100%;
  }
  .career_wrapper.service_wrapper .header_keywords {
    font-size: 100px;
  }
  .service_wrapper.career_wrapper .header_keywords span {
    padding-left: 100px;
    font-size: 44px;
  }
  .career_wrapper.service_wrapper .section.halfway_section {
    padding-top: 0;
    padding-bottom: 100px;
    margin-bottom: 0;
  }
  .career_wrapper.service_wrapper section.halfway_section.grey_section {
    margin-top: 0px;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-bottom: 50px;
  }
  .grey_bg {
    width: 100%;
  }
  .dark_blue_bg {
    width: 100%;
    background: url('assets/img/about/element2.png') no-repeat 450px 190%,
      linear-gradient(90deg, black 0%, #05314e 100%);
    left: 0;
    top: unset;
    bottom: 0;
    max-height: 65%;
  }
  .halfway_section .perks_container {
    min-width: 250px;
  }
  .halfway_section .col-md-6:last-child {
    padding: 0 15px;
    margin-top: 80px;
  }
  .career_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 42px;
    line-height: 1.3;
  }
  .career_wrapper.service_wrapper
    section.halfway_section.grey_section
    .justify-content-center.row {
    flex-flow: row wrap-reverse;
  }
  .career_wrapper.service_wrapper
    section.halfway_section.grey_section
    .col-md-6:last-child {
    padding: 0 15px;
    margin-top: 0;
    margin-bottom: 80px;
  }
  .career_wrapper.service_wrapper
    section.halfway_section.grey_section
    .service_container_align_img
    img.halfway_img {
    width: 100%;
  }
  .career_content_wrapper h1.section_title {
    font-size: 42px;
  }
  .service_content {
    overflow-x: hidden;
  }
  .service_content img {
    right: -120px;
    opacity: 0.5;
  }

  /*Case Study*/
  .case-study .header_keywords {
    font-size: 53px;
  }
  .case-study .header_title {
    font-size: 16px;
  }
  .case_summary {
    padding: 50px 0 20px;
  }
  .case_summary .col-sm-6 {
    margin-bottom: 30px;
  }
  /* .objective_section .row {
    flex-flow: row wrap-reverse;
  } */
  .objective_section .services_list.justify-content-end {
    justify-content: flex-start !important;
    margin-bottom: 30px;
  }
  .objective_section h1.section_title {
    margin-bottom: 30px;
  }
  .objective_section h1.section_title b {
    font-size: 50px;
  }
  .objective_section .services_list .list-group-item {
    padding-left: 0;
  }
  .objective_section .services_list .info-head {
    margin-bottom: 0px;
  }

  .case-study h1.section_title {
    font-size: 50px;
    margin-bottom: 30px;
  }
  .case-study .breaker_section .subtitle {
    margin-bottom: 30px;
    font-size: 16px;
  }
  .kpi_container {
    padding: 0;
    text-align: center;
  }
  .kpi_container:last-child {
    margin-top: 30px;
  }
  .kpi_container.mid_container {
    padding: 120px 0;
    margin-top: 30px;
  }
  .case-study .vertical_hr {
    display: none;
  }
  .sample_work_section {
    padding: 50px 0 70px;
  }
  .other_stories h1.section_title {
    text-align: center !important;
    font-size: 42px;
  }
  .other_stories_carousel .work-info {
    flex-wrap: wrap;
  }
  .other_stories_carousel .description hr.orange_hr {
    margin-top: 40px;
  }
  .other_stories_carousel .owl-item img.work_image {
    width: 100%;
    margin-right: 0;
  }
  .journal_info .work-info.info.info-horizontal:hover,
  .other_stories_carousel .owl-carousel .owl-item:hover {
    transform: unset;
  }
  #work_image_6 {
    margin-top: 0;
  }
  .other_stories_carousel .owl-item .info {
    padding-top: 20px;
  }
  .other_stories_carousel .owl-nav.disabled + .owl-dots {
    margin-top: 50px;
  }
  .other_stories_carousel .owl-dots .owl-dot.active span {
    background: #000;
  }
  .other_stories_carousel .owl-dots .owl-dot.active span:after {
    color: #000;
  }

  .working_wrapper .working_section .breaker_section.work_breaker_section {
    padding: 100px 0 0;
    min-height: unset;
  }
}

@media (max-width: 575px) {
  .working_wrapper .working_section .breaker_section.work_breaker_section {
    width: 100vw;
    margin-left: 0;
    margin-right: 0;
    padding-top: 50px;
  }
  .working_wrapper .working_section .breaker_section .row {
    max-width: unset;
  }
  .working_wrapper .working_section .work-info.info.info-horizontal {
    padding: 0;
    max-width: 85vw;
  }
  .service_wrapper .header_keywords {
    font-size: 53px;
  }
  .service_wrapper .header_title {
    font-size: 34px;
  }
  .career_wrapper.service_wrapper .header_keywords {
    font-size: 82px;
  }
  .service_wrapper.career_wrapper .header_keywords span {
    font-size: 36px;
  }
  .career_wrapper .section_title.work_section_title {
    font-size: 37px;
  }
  .service_content img {
    display: none;
  }
  .career_wrapper.service_wrapper .section.halfway_section .col-md-6,
  .career_wrapper.service_wrapper .section.halfway_section .col-md-6:last-child,
  .career_wrapper.service_wrapper
    section.halfway_section.grey_section
    .col-md-6:last-child {
    padding: 0 10vw;
  }
  .career_wrapper.service_wrapper .section.halfway_section .halfway_img {
    margin: auto;
  }
  .halfway_section .perks_container {
    min-width: unset;
    max-width: 100%;
    width: 100%;
    padding: 0;
  }
  .mobile_perks {
    flex-wrap: wrap;
  }
  .dark_blue_bg {
    background: url('assets/img/about/element2.png') no-repeat 45vw 80vh,
      linear-gradient(90deg, black 0%, #05314e 100%);
    max-height: 75%;
  }
  .career_wrapper.service_wrapper .halfway_section .subtitle {
    margin-bottom: 40px;
  }
  .career_wrapper.service_wrapper .service_content .mail_link {
    margin: 20px auto 0;
  }

  /*Case Study*/
  .case-study.case_study_wrapper .page-header {
    height: 100vh;
    max-height: 800px;
    background-position: bottom;
    min-height: 650px;
  }
  .case_study_wrapper.case-study .header_keywords {
    width: 100%;
    line-height: 1.1;
  }
  .case_desktop_header {
    display: none !important;
  }
  .case_mobile_header {
    display: flex !important;
  }
  .case-study .page-header .container {
    align-items: flex-start !important;
    margin-top: 100px;
  }
  .case-study .page-header .col-md-12 {
    padding: 50px 25px;
  }
  .case-study.case_study_wrapper .case_summary {
    padding: 60px 0 20px;
  }
  .case-study.case_study_wrapper .objective_section {
  }
  .case_summary .container {
    padding-left: 50px;
    padding-right: 30px;
  }
  .other_stories h1.section_title {
    font-size: 36px;
  }
  .case-study .work_content,
  .kpi_container p,
  .case-study .breaker_section .subtitle {
    font-size: 20px;
  }
  .case-study .breaker_section {
    padding: 60px 20px;
    overflow: hidden;
  }
  .breaker_section .list-group .list-group-item {
    width: 100%;
  }
  .other_stories_container {
    padding-left: 0;
  }
}

@media (max-width: 480px) {
  .working_wrapper .section_title.work_section_title {
    font-size: 28px;
  }
  .working_wrapper .breaker_section.work_breaker h1.section_title {
    font-size: 34px;
  }
  .dark_blue_bg {
    background: url('assets/img/about/element2.png') no-repeat 40vw 68vh,
      linear-gradient(90deg, black 0%, #05314e 100%);
  }
  .longer_subtitle {
    min-width: unset;
  }
  .career_wrapper.service_wrapper section.halfway_section.grey_section {
    padding-top: 60px;
    padding-bottom: 50px;
    margin-bottom: 60px;
  }
  .career_wrapper.service_wrapper .section.halfway_section {
    padding-bottom: 40px;
  }
  .career_wrapper.service_wrapper
    section.halfway_section.grey_section
    .col-md-6:last-child {
    margin-bottom: 50px;
  }
  .halfway_section .col-md-6:last-child {
    margin-top: 50px;
  }

  /*Case Study*/
  .case-study .page-header {
    background-position: bottom;
    min-height: 600px;
    max-height: 800px;
  }
  .case-study .case_summary .work_content {
    line-height: 1.2;
  }
  .case-study.case_study_wrapper .case_summary {
    padding: 40px 0 0;
  }
  .case-study.case_study_wrapper .case_summary .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .case-study.case_study_wrapper .case_summary .work_content {
    font-size: 18px;
  }
  .case_study_wrapper.case-study .header_keywords {
    font-size: 3em;
  }
  .case-study h1.section_title,
  .objective_section h1.section_title b {
    font-size: 40px;
  }
  .other_stories_carousel .info-title.work-title {
    font-size: 26px;
  }
  .other_stories_carousel .description p {
    font-size: 16px;
  }

  /*Services*/
  .career_wrapper.service_wrapper .header_keywords {
    font-size: 70px;
  }
  .service_wrapper.career_wrapper .header_keywords span {
    font-size: 34px;
    padding-left: 40px;
  }
}

@media (max-width: 375px) {
  .service_wrapper .header_keywords {
    font-size: 48px;
  }
  .service_wrapper .header_title {
    font-size: 30px;
  }
  .career_wrapper.service_wrapper .header_keywords {
    font-size: 64px;
    line-height: 1.2;
  }
  .service_wrapper.career_wrapper .header_keywords span {
    font-size: 26px;
    padding-left: 30px;
  }
  .dark_blue_bg {
    background: url('assets/img/about/element2.png') no-repeat 30vw 68vh,
      linear-gradient(90deg, black 0%, #05314e 100%);
    max-height: 80%;
  }
  .career_wrapper.service_wrapper .section.halfway_section .col-md-6,
  .career_wrapper.service_wrapper .section.halfway_section .col-md-6:last-child,
  .career_wrapper.service_wrapper
    section.halfway_section.grey_section
    .col-md-6:last-child {
    padding: 0 5vw;
  }
  .career_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 36px;
    line-height: 1.2;
  }
  .case-study.case_study_wrapper .case_summary {
    padding: 20px 0 0;
  }
  .career_content_wrapper h1.section_title {
    font-size: 35px;
  }
  .career_wrapper.service_wrapper .service_content h1.section_title {
    font-size: 28px;
  }
  .case-study .page-header {
    min-height: 110vh;
  }
  .case-study .page-header .col-md-12 {
    padding: 50px 30px;
  }
  .case-study .header_keywords {
    font-size: 38px;
  }
  .case_summary .container {
    padding: 20px;
  }
  .case_summary .col-sm-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .case-study .work_content,
  .kpi_container p,
  .case-study .breaker_section .subtitle {
    font-size: 18px;
  }
  .case-study .section {
    padding: 40px 10px;
  }
  .case-study .sample_work_section {
    padding: 50px 0px;
  }
  .obj_subheader {
    font-size: 20px;
  }
  .strategyImg {
    max-width: 70px;
  }
}

@media (max-width: 320px) {
  .working_wrapper .breaker_section.work_breaker h1.section_title {
    font-size: 28px;
  }
  .working_wrapper .breaker_section.work_breaker .subtitle {
    font-size: 18px;
  }
  .case_study_wrapper.case-study .header_keywords {
    font-size: 2.5em;
  }
}
