.home_wrapper.service_wrapper.registration_wrapper .page-header .container {
  max-width: 80vw;
}
.home_wrapper.service_wrapper.registration_wrapper .header_keywords {
  font-size: 80px;
  text-align: left;
  line-height: 1;
  margin-bottom: 0;
}

.home_wrapper.service_wrapper.registration_wrapper .header_subkeywords {
  font-size: 56px;
  font-family: 'DM Serif Display';
  color: #fff;
  text-transform: capitalize;
  line-height: 1;
}

.home_wrapper.service_wrapper.registration_wrapper .header_year {
  font-size: 172px;
  font-family: 'DM Serif Display';
  color: #fff;
  line-height: 1;
  margin-bottom: 0;
}

.home_wrapper.service_wrapper.registration_wrapper .header_title {
  max-width: 100%;
  margin-top: 0;
}

.home_wrapper.service_wrapper.registration_wrapper .corner_btn {
  left: 0;
  right: 0;
  position: fixed;
  bottom: 5vh;
}

.consultant_btn {
  border: 1px solid #ffffff;
  border-radius: 45.5px;
  color: #000;
  background-color: #fff;
  letter-spacing: 0;
  font-size: 25px;
  padding: 15px 40px;
  min-width: 150px;
  text-align: center;
  z-index: 999;
  position: absolute;
  right: 3vh;
  top: 3vh;
  transition: all 0.5s ease;
}
.consultant_btn:hover,
.consultant_btn:focus {
  color: #fff;
  background-color: #000;
  cursor: pointer;
}

.bg-info .consultant_btn {
  top: 3px;
  font-size: 16px;
  padding: 10px 20px;
  background: transparent;
  color: #fff;
  transition: all 0.5s ease;
}
.bg-info .consultant_btn:hover,
.bg-info .consultant_btn:focus {
  background: #fff;
  color: #000;
}

.home_wrapper.service_wrapper.registration_wrapper .service_content {
  margin: 0;
  padding-bottom: 80px;
}

.service_content img.award_card {
  position: relative;
  margin: auto;
  left: unset;
  right: unset;
}

.service_content .gold_word {
  color: #eab726;
  text-shadow: 2px 2px 2px #00000029;
  font-family: 'Coupe';
  margin-bottom: 15px;
  font-size: 66px;
  line-height: 1;
  margin-top: 15px;
}
.service_content .silver_word {
  color: #2f4148;
  text-shadow: 2px 2px 2px #00000029;
  font-family: 'Coupe';
  margin-bottom: 15px;
  font-size: 66px;
  line-height: 1;
  margin-top: 15px;
}

.service_content .award_p {
  font-size: 30px;
  font-family: 'FSLucasProRegular';
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.66px;
  line-height: 1.1;
}

.award_content {
  max-width: 100%;
}

.award_content .container {
  max-width: 80vw;
}
.registration_services {
  overflow: hidden;
  position: relative;
  background: linear-gradient(145deg, #051745 0%, #020a17 100%, #839db000 100%) 0% 0% no-repeat padding-box;
}
.registration_services .container {
  max-width: 94vw;
  padding-left: 3vw;
  padding-right: 3vw;
  overflow: hidden;
  height: auto !important;
}
.registration_services .row {
  margin-bottom: 20px;
}
.registration_services h1 {
  font-family: 'DM Serif Display';
}
.service_card {
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
}
.service_card h2 {
  color: #000000;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 38px;
  font-family: 'DM Serif Display';
}
.service_card p {
  line-height: 1.2;
  font-size: 20px;
}
.service_card img {
  max-height: 120px;

  padding: 15px 15px 10px;
}

.registration_wrapper h1.section_title {
  line-height: 1.2;
}
.form_cont {
  width: 100%;
  max-width: 800px;
  margin: 30px auto 0;
}
#register .subtitle {
  margin-top: 20px;
}
#reg_form {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 350px;
}
#reg_form input {
  width: 100%;
  padding: 14px 16px 0 10px;
  outline: 0;
  border: none;
  border-bottom: 1px solid #979797;
  background: transparent;
  font-size: 16px;
  margin-top: 20px;
  font-family: FSLucasProRegular;
  color: #000;
  border-radius: 0;
}
#reg_form label {
  font-size: 18px;
  padding: 0 12px;
  color: #000;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 26px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  text-transform: uppercase;
}

#reg_form .form-group:focus-within label {
  transform: translate(0, 12px) scale(0.75);
}
#reg_form .Active {
  transform: translate(0, 12px) scale(0.75);
}
#reg_form .submit_btn {
  border: 1px solid #000000;
  border-radius: 45px;
  min-width: 180px;
  margin: auto;
  text-align: center;
  display: block;
  color: #000;
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: transparent;
  opacity: 1;
  transition: opacity 0.5 linear;
  font-size: 17px;
  margin-top: 20px;
}
#reg_form .submit_btn:hover,
#reg_form .submit_btn:focus,
#reg_form .submit_btn:active {
  background-color: #000;
  border-color: #000;
  color: #fff;
}
#reg_form .submit_btn i {
  display: none;
}
#reg_form .submit_btn:disabled {
  opacity: 0.6;
}
#reg_form .submit_btn:disabled i {
  opacity: 0;
  transition: opacity 0.5 linear;
}
#reg_form .submit_btn i {
  opacity: 1;
  transition: opacity 0.5 linear;
}
#reg_form .submit_btn:disabled:hover,
#reg_form .submit_btn:disabled:focus,
#reg_form .submit_btn:disabled:active {
  background-color: transparent;
  color: #000;
}
.our_client_content {
  padding: 80px 0;
}
.our_client_content .container {
  max-width: 90vw;
}
.our_client_content h1 {
  font-family: 'DM Serif Display';
  width: fit-content;
  margin: 0 auto 50px;
}
.our_client_logo {
  width: auto;
  padding: 20px;
}

.our_client_logo:nth-child(4) {
  max-width: 90%;
}
.hide_992 {
  display: flex;
}
.show_992 {
  display: none;
}

.show_767 {
  display: none;
}
.hide_767 {
  display: flex;
}

.thankyou_wrapper h1.header_year {
  line-height: 1.3;
  font-size: 6vw;
  color: #fff;
  opacity: 1;
  font-family: 'DM Serif Display';
  margin-bottom: 0;
}
.home_wrapper.thankyou_wrapper .header_title {
  margin-top: 0;
}

@-webkit-keyframes loader {
  0% {
    width: 0;
  }

  20% {
    width: 10%;
  }

  25% {
    width: 24%;
  }

  43% {
    width: 41%;
  }

  56% {
    width: 50%;
  }

  66% {
    width: 52%;
  }

  71% {
    width: 60%;
  }

  75% {
    width: 76%;
  }

  94% {
    width: 86%;
  }

  100% {
    width: 100%;
  }
}

@keyframes loader {
  0% {
    width: 0;
  }

  20% {
    width: 10%;
  }

  25% {
    width: 24%;
  }

  43% {
    width: 41%;
  }

  56% {
    width: 50%;
  }

  66% {
    width: 52%;
  }

  71% {
    width: 60%;
  }

  75% {
    width: 76%;
  }

  94% {
    width: 86%;
  }

  100% {
    width: 100%;
  }
}

.progress-bar .bar {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  background: rgba(255, 255, 255, 0.175);
  border-radius: 5px;
}

.progress-bar {
  border-radius: 60px;
  overflow: hidden;
  width: 100%;
  display: none;
  position: relative;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  width: 100%;
  max-width: 100%;
  background-color: unset;
  margin-top: 20px;
}

.progress-bar span {
  display: block;
}

.progress {
  -webkit-animation: loader 8s ease infinite;
  animation: loader 8s ease infinite;
  background: #f96436;
  color: #fff;
  padding: 5px;
  width: 0;
  margin-top: 15px;
  margin-bottom: 25px;
  box-shadow: unset;
}

@media (max-width: 1800px) {
  .registration_wrapper .home_header_container img {
    max-width: 350px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_keywords {
    font-size: 70px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_subkeywords {
    font-size: 50px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_year {
    font-size: 140px;
  }
  .consultant_btn {
    top: 1vh;
    padding: 10px 30px;
  }

  .registration_services .container {
    max-width: 95vw;
  }
  .service_card {
    justify-content: flex-start;
    height: 100%;
  }
  .service_card h2 {
    font-size: 32px;
    line-height: 1;
  }
  .service_card p {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .home_wrapper.service_wrapper.registration_wrapper .page-header .container {
    max-width: 90vw;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_keywords {
    margin: 0 !important;
  }
  .registration_wrapper .service_content h1.section_title {
    margin-bottom: 0px;
  }
  .registration_wrapper h1.section_title {
    font-size: 4vw;
  }
  .service_content img {
    max-width: 250px;
  }
  .service_content .gold_word,
  .service_content .silver_word {
    font-size: 58px;
  }
  .service_content .award_p {
    font-size: 24px;
  }
  .service_card img {
    max-height: 100px;
  }
}

@media (max-width: 1440px) {
  .registration_wrapper .home_header_container img {
    max-width: 300px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_keywords {
    font-size: 60px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_subkeywords {
    font-size: 42px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_year {
    font-size: 115px;
  }
  .service_card h2 {
    font-size: 28px;
  }
  .our_client_logo {
    padding: 10px 0;
  }
}

@media (max-width: 1200px) {
  .consultant_btn {
    padding: 6px 20px;
    font-size: 20px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .page-header .container {
    max-width: 90vw;
    margin-left: auto;
  }
  .registration_wrapper .home_header_container img {
    max-width: 260px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_title {
    text-align: left;
    font-size: 18px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_keywords {
    font-size: 50px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_subkeywords {
    font-size: 35px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_year {
    font-size: 100px;
  }

  .award_content .container {
    max-width: 90vw;
  }

  .service_content img {
    max-width: 200px;
  }
  .service_content .gold_word,
  .service_content .silver_word {
    font-size: 46px;
  }
  .service_content .award_p {
    font-size: 20px;
  }

  .registration_services .row {
    margin-bottom: 20px;
  }
  .registration_services .col-xl-3 {
    margin-bottom: 30px;
  }
  .our_client_content h1 {
    margin-bottom: 0;
  }
  .our_client_logo {
    padding: 50px 0 0;
  }
}

@media (max-width: 992px) {
  .registration_wrapper .home_header_container {
    top: unset;
  }
  .hide_992 {
    display: none;
  }
  .show_992 {
    display: flex;
  }
  .lead_gen_carousel {
    margin-top: 20px;
  }
  .lead_gen_carousel .owl-carousel .owl-stage {
    display: flex;
    align-items: center;
  }
  .lead_gen_carousel .owl-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .lead_gen_client_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .lead_gen_client_box .our_client_logo {
    padding: 0;
  }
  .award_carousel .owl-dots .owl-dot.active span:after,
  .brand_carousel .owl-dots .owl-dot.active span:after {
    color: #000;
  }
  .award_carousel .owl-theme .owl-dots .owl-dot span,
  .brand_carousel .owl-theme .owl-dots .owl-dot span {
    background-color: #000;
  }
  .thankyou_wrapper .home_header_container {
    position: relative;
    top: unset;
  }
  .thankyou_wrapper h1.header_year {
    font-size: 55px;
  }
}

@media (max-width: 991.5px) {
  .registration_wrapper .home_header_container .col-lg-8 .row {
    justify-content: center;
    margin-top: 20px;
  }
  .lead_gen_carousel .team_box {
    gap: 0 20px;
  }
}

@media (max-width: 768px) {
  .home_wrapper.service_wrapper.registration_wrapper .service_content {
    padding-bottom: 60px;
  }
  .registration_wrapper .service_content h1.section_title {
    margin-bottom: 20px;
  }
  .service_content img {
    max-width: 150px;
  }
  .service_content .gold_word,
  .service_content .silver_word {
    font-size: 42px;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .service_content .award_p {
    font-size: 18px;
  }
  .our_client_content,
  .registration_services {
    padding: 60px 0;
  }
}

@media (max-width: 767.5px) {
  .show_767 {
    display: flex;
  }
  .hide_767 {
    display: none;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_keywords,
  .home_wrapper.service_wrapper.registration_wrapper .header_subkeywords,
  .home_wrapper.service_wrapper.registration_wrapper .header_year,
  .home_wrapper.service_wrapper.registration_wrapper .header_title {
    text-align: center;
  }
  .registration_wrapper .home_header_container img {
    max-width: 200px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_year {
    font-size: 135px;
  }
  .registration_wrapper .section {
    padding: 60px 0;
  }
  .registration_services .row {
    margin-bottom: 20px;
  }
  .registration_services .col-xl-3 {
    margin-bottom: 20px;
    padding: 0 10px;
  }
  .service_card {
    padding: 20px 10px;
  }
  .service_card img {
    max-height: 60px;
  }
  .service_card h2 {
    font-size: 18px;
  }
  .service_card p {
    font-size: 13px;
  }
  .award_carousel .team_box {
    flex-direction: column;
  }
  .award_carousel img {
    opacity: 1;
  }
  .award_carousel {
    margin-top: 20px;
  }

  .home_wrapper.thankyou_wrapper .header_title {
    text-align: left;
  }
}
@media (max-width: 575px) {
  .lead_gen_carousel .team_box {
    gap: 40px;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .brand_carousel .owl-carousel .owl-item img {
    max-width: 150px;
  }
  .brand_carousel #bmw-logo,
  .brand_carousel #sime-logo {
    max-width: 100px;
  }
  .registration_wrapper .service_content h1.section_title {
    max-width: 100%;
    margin: auto;
  }

  .home_wrapper.service_wrapper.thankyou_wrapper .page-header .container.home_header_container {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 30px;
  }

  .thankyou_wrapper .lottie_bg {
    object-fit: left;
  }
  .thankyou_wrapper h1.header_year,
  .home_wrapper.thankyou_wrapper .header_title {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .registration_wrapper .lottie_bg {
    object-position: center;
    width: 102%;
    height: 101vh;
    max-width: 102%;
  }
  .consultant_btn {
    font-size: 14px;
    top: 15px;
    right: 10px;
  }
  .registration_wrapper .home_header_container img {
    max-width: 160px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .page-header .container.home_header_container {
    margin: 15vh auto 0;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_keywords {
    font-size: 40px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_subkeywords {
    font-size: 25px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_year {
    font-size: 105px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_title {
    width: 300px;
    margin: 0px auto;
  }
  .lead_gen_carousel .team_box {
    flex-direction: column;
  }
  .home_wrapper.service_wrapper.registration_wrapper .service_content {
    padding-bottom: 50px;
  }
  /* .registration_wrapper .service_content h1.section_title {
    padding: 0 10px;
  } */

  .registration_wrapper #register h1.section_title {
    padding: 0;
    font-size: 36px;
  }
  .home_wrapper.service_wrapper.thankyou_wrapper .page-header .container.home_header_container {
    padding-left: 15px;
  }
  .thankyou_wrapper h1.header_year {
    font-size: 46px;
  }
}
@media (max-width: 375.5px) {
  .consultant_btn {
    padding: 5px 10px;
    min-width: unset;
    right: 10px;
  }
  .bg-info .consultant_btn {
    padding: 5px 10px;
    top: 10px;
  }
  .home_wrapper.registration_wrapper h1.section_title {
    font-size: 32px;
  }
  .award_content .container {
    max-width: 100vw;
  }
  .registration_services .row {
    margin-bottom: 10px;
  }
  .registration_services .col-6 {
    margin-bottom: 20px;
    padding: 0 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .registration_wrapper .service_content h1.section_title {
    max-width: 100%;
  }
  .service_card {
    padding: 20px;
  }
}

@media (max-width: 320px) {
  .registration_wrapper .home_header_container .col-md-12,
  .registration_wrapper .home_header_container .col-md-7 {
    padding-right: 0;
  }
  .registration_wrapper .home_header_container img {
    max-width: 150px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_keywords {
    font-size: 34px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_subkeywords {
    font-size: 24px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_year {
    font-size: 90px;
  }
  .home_wrapper.service_wrapper.registration_wrapper .header_title {
    font-size: 14px;
    width: 200px;
  }
  /* .registration_wrapper .service_content h1.section_title {
    padding: 0 10px;
  } */

  .thankyou_wrapper .home_header_container .col-md-12,
  .thankyou_wrapper .home_header_container .col-md-7 {
    padding-left: 15px;
  }
}
.test_button {
  /* Add some basic button styles */
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #2a2f33;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  /* Add a transition effect for the transform property */
  transition: transform 0.3s;

  /* Add an animation for the hover state */
}
.test_button:hover {
  background-color: #83bbe7;
  transform: translateY(-3px);
}
