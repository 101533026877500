.dark_blue_bg {
  width: calc((100% - (100% - 750px) / 2));
  min-width: 1000px;
  background: url('assets/img/about/element2.png') no-repeat -300px 190%,
    linear-gradient(90deg, black 0%, #05314e 100%);
  position: absolute;
  top: 0;
  left: calc((100% - 750px) / 2);
}
.career_wrapper.service_wrapper .dark_blue_bg {
  width: calc((100% - (100% - 950px) / 2));
  min-width: 1000px;
  background: url('assets/img/about/element2.png') no-repeat -300px 190%,
    linear-gradient(90deg, black 0%, #05314e 100%);
  position: absolute;
  top: 0;
  left: calc((100% - 950px) / 2);
}
.grey_bg {
  background: url('assets/img/home/bg.jpg') no-repeat;
  width: 70vw;
  position: absolute;
  left: 0;
  top: 0;
}
.with_dark_blue .halfway_img {
  width: 100%;
}
#special_perks_container {
  width: 55%;
}
.career_wrapper.service_wrapper .perks_container {
  margin-top: 0;
  width: 45%;
}
.career_wrapper.service_wrapper .perks_img {
  width: 40px;
}
.career_wrapper.service_wrapper .service_content {
  background: url('../img/services/bg.jpg') no-repeat;
  background-size: cover;
}
.service_content img.service_img_left {
  left: 10vw;
  max-width: 400px;
  width: 100%;
  bottom: 0;
}
.service_content img.service_img_right {
  right: 0;
  bottom: 50px;
}
.career_wrapper.service_wrapper .section_title.work_section_title,
.career_wrapper.service_wrapper .halfway_section .sectional_title {
  font-size: 60px;
}
.career_wrapper.service_wrapper .career_text {
  max-width: 700px;
  text-align: center;
  margin: auto;
}
.career_wrapper.service_wrapper .career_text p {
  text-align: center !important;
  font-size: 22px;
  max-width: 500px;
  margin: 30px auto 50px;
}
.career_wrapper.service_wrapper .career_content_wrapper .mail_link {
  display: inline-block;
}
.perks_slider {
  margin-bottom: 40px;
}
.two_links_container {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  margin: auto;
}
.service_content img.coffee_arrow {
  margin-left: 0;
  position: relative;
  right: 0;
  display: inline-block;
}

.service_container_align_img img {
  height: auto;
  object-fit: cover;
}
.career_wrapper.service_wrapper .perks_img.go_lang_perk_img {
  padding: 20px 0;
}
.career_wrapper.service_wrapper .perks_img.pwa_perks {
  width: 30px;
  margin-left: 5px;
  margin-right: 25px;
}

.career_wrapper.service_wrapper .perks_img.andriod_perks {
  width: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.career_wrapper.service_wrapper .perks_img.apple_perks {
  width: 35px;
  margin-right: 25px;
}

.career_wrapper.service_wrapper .perks_img.phone_gap_perks {
  width: 35px;
}

.with_dark_blue .service_container_align_img {
  padding-right: 50px;
}

@media (min-width: 1900px) {
  .career_wrapper.service_wrapper .container {
    max-width: 70vw;
  }
  .dark_blue_bg {
    width: 87vw;
    left: unset;
    right: 0;
  }
  .career_wrapper.service_wrapper .halfway_section .row {
    align-items: center;
  }
  .service_container_align_img img {
    width: 100%;
    height: auto;
  }
  .career_wrapper.service_wrapper .halfway_section .subtitle {
    font-size: 20px;
    max-width: 450px;
    line-height: 1.5;
  }

  .career_wrapper.service_wrapper .dark_blue_bg {
    width: calc((100% - (100% - 1000px) / 2));
    left: calc((100% - 1000px) / 2);
  }
}

@media (max-width: 1440px) {
  .career_wrapper.service_wrapper .service_content {
    padding-top: 0;
  }
  .service_content img.service_img_left {
    left: 5vw;
  }
}

@media (max-width: 1200px) {
  .service_wrapper .header_keywords {
    font-size: 100px;
  }
  .service_wrapper .header_title {
    font-size: 50px;
  }
  .career_wrapper .section_title.work_section_title {
    font-size: 50px;
  }
  .service_wrapper .subtitle {
    font-size: 16px;
  }

  .halfway_section .sectional_title {
    font-size: 50px;
  }
  .career_wrapper.service_wrapper .grey_section.halfway_section .container {
    max-width: 960px;
  }
  .grey_section.halfway_section .container {
    max-width: 1150px;
  }
  .career_wrapper.service_wrapper .halfway_section {
    padding-bottom: 105px;
  }
  .career_wrapper.service_wrapper .halfway_section.grey_section {
    padding-bottom: 160px;
  }
  .career_wrapper.service_wrapper .halfway_img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  .halfway_section h6 {
    font-size: 18px;
  }
  .halfway_section .col-md-6:last-child {
    padding-left: 40px;
    padding-right: 20px;
  }
  .halfway_section.grey_section .col-md-6:last-child {
    padding-left: 20px;
  }

  .grey_section {
    margin-top: -105px;
    padding-top: 200px;
  }
  .career_wrapper.service_wrapper .career_content_wrapper {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
  .career_wrapper.service_wrapper .career_text {
    max-width: 100%;
  }
  .career_wrapper.service_wrapper .career_text p {
    max-width: 500px;
  }
  .career_wrapper.service_wrapper .career_text h1 {
    font-size: 50px;
    max-width: 600px;
    margin: auto;
  }
  .two_links_container {
    max-width: 400px;
  }
  .service_content img.service_img_right {
    max-width: 250px;
  }

  .career_wrapper.service_wrapper .dark_blue_bg {
    width: calc((100% - (100% - 750px) / 2));
    left: calc((100% - 750px) / 2);
  }
  .career_wrapper.service_wrapper .section_title.work_section_title,
  .career_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 46px;
  }
  .career_wrapper.service_wrapper .grey_bg {
    width: 87vw;
  }
  #special_perks_container {
    width: 70%;
  }
  #special_perks_container2 {
    width: 30%;
    min-width: 150px;
  }
}

@media (max-width: 992px) {
  .service_content img.service_img_right {
    display: none;
  }
  .service_content img.service_img_left {
    max-width: 300px;
    position: relative;
    margin: auto;
    margin-top: 30px;
    display: block;
  }
  .career_wrapper.service_wrapper .perks_container {
    min-width: 220px;
  }
  .career_wrapper.service_wrapper .service_content {
    padding-bottom: 0;
  }
  .dark_blue_bg {
    left: calc((100% - 750px) / 2);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .career_wrapper.service_wrapper .halfway_section .container {
    max-width: unset;
  }
  .dark_blue_bg {
    left: 0;
  }
  .grey_bg,
  .dark_blue_bg {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .career_wrapper.service_wrapper .dark_blue_bg {
    width: 100%;
    left: 0;
    bottom: 0;
    top: unset;
  }
  .career_wrapper.service_wrapper .section_title.work_section_title,
  .career_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 46px;
  }
  .career_wrapper.service_wrapper .grey_bg {
    width: 100%;
  }
  .career_wrapper.service_wrapper .perks_container {
    min-width: unset;
  }
  #special_perks_container .d-flex,
  #special_perks_container2 .d-flex {
    min-height: 32px;
  }
  #special_perks_container .d-flex .subtitle,
  #special_perks_container2 .d-flex .subtitle {
    width: 100%;
  }
}

@media (max-width: 767.5px) {
  .career_wrapper.service_wrapper .grey_section.halfway_section .container,
  .grey_section.halfway_section .container {
    max-width: 540px;
  }
  .service_content img.coffee_arrow {
    opacity: 1;
  }
  .with_dark_blue .service_container_align_img {
    padding-right: 15px;
  }
  .career_wrapper.service_wrapper .service_content h1.section_title {
    font-size: 50px;
  }
}
@media (max-width: 575px) {
  .career_wrapper.service_wrapper .section_title.work_section_title,
  .career_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 42px;
  }
  .two_links_container {
    max-width: unset;
  }
  .two_links_container {
    flex-wrap: wrap;
  }
  .career_wrapper.service_wrapper .service_content .mail_link {
    width: 100%;
    text-align: center;
    z-index: 99;
  }
  .service_content img.service_img_left {
    margin-left: 0;
    margin-top: -30px;
  }
  .career_wrapper.service_wrapper .service_content h1.section_title {
    font-size: 46px;
  }
  .career_wrapper.service_wrapper .career_text p {
    font-size: 20px;
    max-width: 400px;
    margin: 30px auto 20px;
  }
  .career_wrapper.service_wrapper .career_content_wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .career_wrapper.service_wrapper .perks_container {
    margin-left: 0;
    width: 100%;
  }
  .career_wrapper.service_wrapper .halfway_section .sectional_title {
    font-size: 38px;
  }
  .perks_slider {
    margin-bottom: 0px;
  }
  .career_wrapper.service_wrapper .perks_img.go_lang_perk_img {
    padding: 10px 0;
  }
  .career_wrapper.service_wrapper .perks_img.andriod_perks {
    margin-right: 25px;
  }

  .career_wrapper.service_wrapper .perks_img.phone_gap_perks {
    margin-right: 25px;
  }
}

@media (max-width: 480px) {
  .career_wrapper.service_wrapper .section_title.work_section_title {
    font-size: 36px;
  }
  .career_wrapper.service_wrapper .service_content h1.section_title {
    font-size: 38px;
  }
  .service_content img.service_img_left {
    margin-left: -30px;
  }
  .career_wrapper.service_wrapper .perks_img {
    width: 30px;
  }
}

@media (max-width: 375px) {
  .career_wrapper.service_wrapper .career_text p {
    font-size: 16px;
  }
}
